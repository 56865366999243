import { Box, Flex, Image, Text } from "@chakra-ui/react";
import brand from "@/assets/images/brand.png";
import usePolyfills from "@/libs/hooks/polyfills";

const Loading = () => {
  const { getMaxHeightRepresentation } = usePolyfills();
  const fullscreen = getMaxHeightRepresentation();
  return (
    <Flex height={fullscreen} bg="#0f0f0f" width="100%">
      <Box m="auto" color="white" fontSize="sm">
        <Image src={brand} width={{ base: 60, md: 96 }} mx="auto" />
        <Text align="center" mt={2}>
          When night falls, call your passion rise up!
        </Text>
        <Text align="center">夜幕中喚醒你的激情——NightCo！</Text>
      </Box>
    </Flex>
  );
};
export default Loading;
