import { Flex } from "@chakra-ui/react";
import FAIcon from "@/components/FAIcon";
import { useCallback, useEffect, useState } from "react";

const VideoOverlay = ({ active, paused, onClick, onDoubleClick }) => {
  const [videoClicked, setVideoClicked] = useState(0);

  const handleVideoClicked = useCallback(
    () => setVideoClicked((v) => v + 1),
    [],
  );

  // handle video clicked
  useEffect(() => {
    if (videoClicked === 1) {
      // click once, toggle video pause
      const t = setTimeout(() => {
        onClick();
        setVideoClicked(0);
      }, 200);
      return () => clearTimeout(t);
    } else if (videoClicked >= 2) {
      // click twice, toggle video like
      onDoubleClick();
      setVideoClicked(0);
    }
  }, [videoClicked, paused, onClick, onDoubleClick]);

  return (
    active && (
      <Flex
        position="absolute"
        left={0}
        top={0}
        width="100%"
        height="100%"
        opacity={paused ? 1 : 0}
        transition=".2s all"
        onClick={handleVideoClicked}
      >
        <FAIcon type={paused ? "pause" : "play"} fontSize="5xl" m="auto" />
      </Flex>
    )
  );
};

export default VideoOverlay;
