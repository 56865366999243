import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useFirebase } from "@/libs/hooks/firebase";
import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import AuthContext from "./auth";
import useAPI from "@/libs/hooks/api";
import { transformChat } from "@/libs/chats";
import PersistentStorage from "@/libs/persistent-storage";
import OnlineStatusContext from "./online-status";
import { onMessage } from "firebase/messaging";

const ChatContext = createContext({ chats: [], resolveChatUrl: () => null });

const CHAT_HISTORIES_STORAGE_KEY = "CHAT_HISTORIES";

export const withChatContext = (Component) => (props) => {
  const { firestore, messaging } = useFirebase();
  const { user, authLoaded } = useContext(AuthContext);
  const { updateListenList } = useContext(OnlineStatusContext);
  const api = useAPI();
  const [chats, setChats] = useState({});
  const [pinned, setPinned] = useState([]);
  const [muted, setMuted] = useState([]);
  const [incoming, setIncoming] = useState([]);
  const [histories, setHistories] = useState({});
  const [gifts, setGifts] = useState({});
  const [initialized, setInitialized] = useState(false);

  const authenticated = authLoaded && !!user;

  const resolveChatUrl = useCallback(
    (creatorId) => {
      const existingChat = Object.values(chats).find(
        (chat) => chat.creator.id === creatorId,
      );
      return existingChat
        ? `/chat/${existingChat.id}`
        : `/chat/${creatorId}/new`;
    },
    [chats],
  );

  const updateChatrooms = useCallback(async () => {
    const { data } = await api.getChats();
    updateListenList(data.map((chat) => chat.creator.id));
    setChats(Object.fromEntries(data.map((d) => [d.id, d])));
  }, [api, updateListenList]);

  const updateMuted = useCallback(
    (id) => async (mute) => {
      const result = await api.updateChatsSettings({ id, mute });
      setMuted(result.muted);
    },
    [api],
  );

  const updatePinned = useCallback(
    (id) => async (pin) => {
      const result = await api.updateChatsSettings({ id, pin });
      setPinned(result.pinned);
    },
    [api],
  );

  const updateHistory = useCallback(
    (id) => (updates) => {
      const updater = (state) => {
        const target = state[id] ? { ...state[id] } : {};

        const updatedTarget = updates.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, target);

        return { ...state, [id]: updatedTarget };
      };
      setHistories(updater);
    },
    [],
  );

  useEffect(() => {
    // @todo: update this function when schema updated
    api.getGifts().then((data) => {
      if (!data) return;
      const normalized = data.reduce((acc, cur) => {
        acc[cur.group] = acc[cur.group] ? acc[cur.group].concat(cur) : [cur];
        return acc;
      }, {});
      setGifts(normalized);
    });
  }, [api]);

  // read stored from storage
  useEffect(() => {
    if (authenticated) {
      const rawStoredHistories =
        PersistentStorage.getItem(CHAT_HISTORIES_STORAGE_KEY) || "{}";
      const storedHistories = JSON.parse(rawStoredHistories);
      setHistories(storedHistories);
    }
  }, [authenticated]);

  // persist state hook
  useEffect(() => {
    if (authenticated) {
      PersistentStorage.setItem(
        CHAT_HISTORIES_STORAGE_KEY,
        JSON.stringify(histories),
      );
    }
  }, [authenticated, chats, histories]);

  // listen chatroom
  useEffect(() => {
    if (!user?.uid) return;
    const q = query(
      collection(firestore, "chatrooms"),
      where("userId", "==", user.uid),
    );
    if (!initialized) {
      // initial fetch
      updateChatrooms().then(() => setInitialized(true));
    }
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      let updates = [];
      let refetch = false;
      querySnapshot.docChanges().forEach((record) => {
        // has new record, refetch all
        if (record.type === "added") refetch = true;
        updates.push(transformChat(record.doc));
      });
      if (refetch) {
        // refetch the chat list from API if needed,
        updateChatrooms();
      } else {
        // otherwise, update exist ids only
        setChats((state) => {
          // copy of state
          const updated = { ...state };
          updates.forEach((record) => {
            updated[record.id] = {
              ...updated[record.id],
              ...record,
            };
          });
          return updated;
        });
      }
    });
    return () => unsubscribe();
  }, [api, firestore, initialized, updateChatrooms, user]);

  // listen push notification
  useEffect(() => {
    if (!messaging) return;
    const unsubscribe = onMessage(messaging, (payload) => {
      const { data } = payload;
      const { action, body, image } = data;
      const isNewChatMessage = action?.startsWith("NAVIGATE_TO_CHAT");
      if (isNewChatMessage) {
        const splited = action.split("::");
        const sender = splited[2];
        const message = body
          ? `${sender}: ${body}`
          : `${sender} 傳送了一則訊息`;
        setIncoming((state) => state.concat({ message, image }));
      }
    });
    return () => unsubscribe();
  }, [messaging]);

  // load pinned & muted status
  useEffect(() => {
    api.getChatsSettings().then((data) => {
      setPinned(data?.pinned ?? []);
      setMuted(data?.muted ?? []);
    });
  }, [api]);

  return (
    <ChatContext.Provider
      value={{
        incoming,
        setIncoming,
        chats,
        pinned,
        muted,
        gifts,
        histories,
        updateHistory,
        updateMuted,
        updatePinned,
        resolveChatUrl,
      }}
    >
      <Component {...props} />
    </ChatContext.Provider>
  );
};

export default ChatContext;
