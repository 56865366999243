import { Box, Button, Flex, Text, VStack, useToast } from "@chakra-ui/react";
import FAIcon from "@/components/FAIcon";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAPI from "@/libs/hooks/api";
import UserOverridesContext from "@/context/user-overrides";
import PlaylistContext from "@/context/playlist";
import AuthContext from "@/context/auth";
import CreatorAvatar from "../CreatorAvatar";
import ShareModal from "../ShareModal";

const VideoActions = ({
  id,
  active,
  creator,
  description,
  isLiked,
  likedTotal,
  toggleLiked,
  isSaved,
  onCreatorClick,
  onChatClick,
  tags,
  update,
}) => {
  const { t } = useTranslation();
  const api = useAPI();
  const { user } = useContext(AuthContext);
  const { muted, setMuted, immersive, setImmersive } =
    useContext(PlaylistContext);
  const { overrides, overrideCreator } = useContext(UserOverridesContext);
  const navigate = useNavigate();
  const toast = useToast();
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [tagsExpanded, setTagsExpanded] = useState(false);
  const [shareData, setShareData] = useState(null);

  const followed = useMemo(() => {
    if (!creator?.id) return false;
    const localRecord = overrides.creators[creator.id]?.isFollowed;
    return localRecord ?? creator?.isFollowed;
  }, [creator, overrides.creators]);

  const jumpToExplore = useCallback(
    (tag) => () => navigate("/explore", { state: { tag } }),
    [navigate],
  );

  const toggleSaved = useCallback(
    async (e) => {
      if (e) e.stopPropagation();
      const action = isSaved ? api.unsaveVideo : api.saveVideo;
      await action(id);
      update({ isSaved: !isSaved });
    },
    [api, id, isSaved, update],
  );

  const toggleFollowed = useCallback(
    async (e) => {
      if (e) e.stopPropagation();
      const creatorId = creator?.id;
      if (!creatorId) return;

      const action = followed ? api.unfollowCreator : api.followCreator;
      await action(creatorId);

      overrideCreator(creatorId)({ isFollowed: !followed });
    },
    [creator, followed, api, overrideCreator],
  );

  const toggleMuted = useCallback(
    async (e) => {
      if (e) e.stopPropagation();
      setMuted((muted) => !muted);
    },
    [setMuted],
  );

  const toggleImmersive = useCallback(
    async (e) => {
      if (e) e.stopPropagation();
      setImmersive((immersive) => !immersive);
    },
    [setImmersive],
  );

  const shareVideo = useCallback(async () => {
    const canShare = typeof navigator?.share === "function";
    const shareData = {
      title: `${creator.displayName}的影片`,
      text: description,
      url: `https://${window.location.host}/videos/${id}`,
    };
    if (canShare) {
      try {
        await navigator.share(shareData);
        toast({ title: "已分享!", status: "info" });
      } catch (e) {
        console.error(e);
      }
    } else {
      setShareData(shareData);
    }
  }, [creator, description, toast, id]);

  return (
    <Flex
      align="flex-end"
      opacity={active ? 1 : 0}
      marginRight={{ base: undefined, md: "-70px" }}
      gap={{ base: 2, md: 9 }}
    >
      <ShareModal data={shareData} onClose={() => setShareData(null)} />
      {immersive ? (
        <Flex
          align="center"
          width="100%"
          justify="space-between"
          textShadow="0.1rem 0.1rem 0.5rem #3D3D3D"
        >
          <Flex
            align="center"
            role="button"
            gap={4}
            onClick={() => onCreatorClick(creator.id)}
            id="button__video_check-creator"
          >
            <CreatorAvatar id={creator.id} picture={creator.picture} />
            <Text fontSize="md" fontWeight="bold">
              @{creator?.slug}
            </Text>
          </Flex>
          <Box
            role="button"
            onClick={toggleImmersive}
            id="button__video_immersive"
          >
            <FAIcon variant="regular" type="compress" fontSize="2xl" />
          </Box>
        </Flex>
      ) : (
        <>
          <Box flex={1}>
            <Flex mb={3} align="center" justify="space-between" gap={3} pr={4}>
              <Flex
                gap={4}
                align="center"
                role="button"
                onClick={() => onCreatorClick(creator.id)}
                id="button__video_check-creator"
              >
                <CreatorAvatar id={creator?.id} picture={creator?.picture} />
                <VStack align="start" textShadow="0.1rem 0.1rem 0.5rem #3D3D3D">
                  <Text fontSize="small">@{creator?.slug}</Text>
                  <Text fontSize="lg" fontWeight="bold" lineHeight={1}>
                    {creator?.displayName}
                  </Text>
                </VStack>
              </Flex>

              {user && (
                <Button
                  onClick={toggleFollowed}
                  id="button__video_follow"
                  size="sm"
                  variant="themed-outline"
                >
                  <FAIcon type={followed ? "check" : "plus"} />
                  {followed
                    ? t("videos.actions.followed")
                    : t("videos.actions.follow")}
                </Button>
              )}
            </Flex>
            <Text
              role="button"
              onClick={() => setDescriptionExpanded(!descriptionExpanded)}
              noOfLines={descriptionExpanded ? null : 3}
              textShadow="0.1rem 0.1rem 0.25rem #3D3D3D"
            >
              {description}
            </Text>
            <Flex
              gap={2}
              mt={2}
              pr={2}
              onClick={() => setTagsExpanded(!tagsExpanded)}
              whiteSpace="break-spaces"
              flexWrap="wrap"
              maxHeight={tagsExpanded ? null : "3rem"}
              overflow="hidden"
            >
              {tags.map((tag, index) => (
                <Text
                  key={index}
                  fontSize="xs"
                  bg="rgba(0,0,0,.15)"
                  onClick={jumpToExplore(tag)}
                  borderColor="#D7913F"
                  px={2}
                  rounded="xl"
                  borderWidth={1}
                >
                  #{tag}
                </Text>
              ))}
            </Flex>
          </Box>

          <VStack
            gap={4}
            color="white"
            textShadow="0.1rem 0.1rem 0.5rem #3D3D3D"
            transition="opacity .8s"
          >
            <Box
              align="center"
              role="button"
              onClick={shareVideo}
              id="button__video_share"
            >
              <FAIcon variant="regular" type="share" fontSize="2xl" />
            </Box>
            {user && (
              <>
                <Box
                  align="center"
                  role="button"
                  onClick={toggleLiked}
                  id="button__video_like"
                >
                  <FAIcon
                    variant={isLiked ? "solid" : "regular"}
                    type="heart"
                    color={isLiked ? "red.400" : "white"}
                    fontSize="2xl"
                  />
                  <Text fontSize="sm" fontWeight="bold">
                    {likedTotal + ~~isLiked}
                  </Text>
                </Box>
                <Box
                  align="center"
                  role="button"
                  onClick={toggleSaved}
                  id="button__video_save-video"
                >
                  <FAIcon
                    variant={isSaved ? "solid" : "regular"}
                    type="bookmark"
                    color={isSaved ? "blue.300" : "white"}
                    fontSize="2xl"
                  />
                </Box>

                <Box
                  align="center"
                  role="button"
                  onClick={() => onChatClick(creator.id)}
                  id="button__video_go-to-chat"
                >
                  <FAIcon variant="regular" type="comments" fontSize="2xl" />
                </Box>
              </>
            )}
            <Box
              align="center"
              role="button"
              onClick={toggleMuted}
              id="button__video_volume"
            >
              <FAIcon
                variant="regular"
                type={muted ? "volume-slash" : "volume"}
                fontSize="2xl"
              />
            </Box>
            <Box
              align="center"
              role="button"
              onClick={toggleImmersive}
              id="button__video_immersive"
            >
              <FAIcon variant="regular" type="expand" fontSize="2xl" />
            </Box>
          </VStack>
        </>
      )}
    </Flex>
  );
};

export default VideoActions;
