import { Center, CircularProgress } from "@chakra-ui/react";
import { useEffect, useRef } from "react";

const LoadMore = ({
  loader = () => null,
  loading = false,
  render,
  done,
  observerProps,
  ...props
}) => {
  const ref = useRef(null);

  useEffect(() => {
    let element = ref.current;
    if (done) return;
    const observer = new IntersectionObserver(
      (entries) => {
        const intersecting = !!entries.find((entry) => entry.isIntersecting);
        if (intersecting && !loading) {
          loader();
        }
      },
      {
        root: null,
        threshold: 0.5,
        rootMargin: "0px",
        ...observerProps,
      },
    );
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [done, loader, loading, observerProps]);

  return (
    <Center ref={ref} {...props}>
      {!done &&
        (typeof render === "function" ? (
          render()
        ) : (
          <CircularProgress isIndeterminate color="secondary.500" />
        ))}
    </Center>
  );
};

export default LoadMore;
