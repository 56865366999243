import {
  Box,
  Circle,
  Flex,
  Image,
  List,
  ListItem,
  Square,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import brandLogo from "@/assets/images/brand.png";
import { useTranslation } from "react-i18next";
import { useContext, useMemo } from "react";
import ChatContext from "@/context/chat";
import usePolyfills from "@/libs/hooks/polyfills";
import AuthContext from "@/context/auth";
import fbLogo from "@/assets/images/social-apps/facebook.png";
import xLogo from "@/assets/images/social-apps/twitter.png";
import igLogo from "@/assets/images/social-apps/instagram.png";
import tgLogo from "@/assets/images/social-apps/telegram.png";
import tiktokLogo from "@/assets/images/social-apps/tiktok.png";
import FAIcon from "./FAIcon";

const NavigationLink = ({
  id,
  url,
  icon,
  startsWith,
  iconType = "regular",
  badge,
  children,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const matchedPath = url === pathname || pathname.startsWith(startsWith);

  return (
    <ListItem
      role="button"
      id={id}
      position="relative"
      onClick={() => navigate(url)}
      color={matchedPath ? "secondary.100" : "white"}
      display="flex"
      alignItems="center"
    >
      <Square size={8} pr={2}>
        <FAIcon className={`fa-${iconType} fa-${icon}`} />
      </Square>

      {children}
      {badge && (
        <Circle
          position="absolute"
          bg="#EE3D52"
          size={5}
          left="10px"
          top={-1}
          fontSize="xs"
        >
          {badge}
        </Circle>
      )}
    </ListItem>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();
  const { chats } = useContext(ChatContext);
  const { user } = useContext(AuthContext);
  const { getMaxHeightRepresentation } = usePolyfills();
  const maxH = getMaxHeightRepresentation();

  const totalUnread = Object.values(chats).reduce(
    (acc, cur) => acc + (cur?.unreadTotal || 0),
    0,
  );
  const unreadMessages = useMemo(() => {
    if (totalUnread === 0) return null;
    return totalUnread > 99 ? "99+" : totalUnread;
  }, [totalUnread]);
  return (
    <Box
      id="sidebar"
      p={10}
      width={320}
      maxW="100vw"
      maxH={maxH}
      position="sticky"
      top={0}
      color="white"
      display={{ base: "none", md: "flex" }}
      flexDir="column"
      borderRightWidth="2px"
      borderColor="gray.500"
    >
      <Box my={6} width={200} mx="auto">
        <Image src={brandLogo} />
      </Box>
      <List fontSize="xl" p={4} spacing={3}>
        <NavigationLink
          id="link__sidebar_index"
          url="/"
          startsWith="/video"
          icon="film"
        >
          {t("page.title.playlist")}
        </NavigationLink>
        {user ? (
          <>
            <NavigationLink
              id="link__sidebar_streams"
              url="/streams"
              icon="video"
            >
              {t("page.title.streams")}
            </NavigationLink>
            <NavigationLink
              id="link__sidebar_explore"
              url="/explore"
              icon="compass"
            >
              {t("page.title.explore")}
            </NavigationLink>
            <NavigationLink
              id="link__sidebar_chats"
              url="/chats"
              icon="comments"
              badge={unreadMessages}
            >
              {t("page.title.chats")}
            </NavigationLink>
            <NavigationLink
              id="link__sidebar_notifications"
              url="/notifications"
              icon="bell"
            >
              {t("page.title.notifications")}
            </NavigationLink>
            <NavigationLink
              id="link__sidebar_profile"
              url="/profile"
              icon="user"
            >
              {t("page.title.profile")}
            </NavigationLink>
          </>
        ) : (
          <NavigationLink
            id="link__sidebar_login"
            url="/auth/register"
            icon="user-gear"
          >
            {t("page.title.login_or_register")}
          </NavigationLink>
        )}
      </List>
      <VStack flex={1} justify="flex-end">
        <Box>
          <Flex justify="center" gap={4} mb={3}>
            <a
              href="https://x.com/NightCo_io"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src={xLogo} width="32px" />
            </a>
            <a
              href="https://www.facebook.com/people/NightCo/61558503355020/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src={fbLogo} width="32px" />
            </a>
            <a
              href="https://www.instagram.com/nightco.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src={igLogo} width="32px" />
            </a>
            <a
              href="https://t.me/+E1Kwu_HT8dA0MjM1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src={tgLogo} width="32px" />
            </a>
            <a
              href="https://www.tiktok.com/@nightco.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src={tiktokLogo} width="32px" />
            </a>
          </Flex>
          <Text color="white">Copyrights © 2024 NightCo </Text>
        </Box>
      </VStack>
    </Box>
  );
};

export default Sidebar;
