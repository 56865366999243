import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  IconButton,
  Square,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";
import getBlobDuration from "get-blob-duration";

import FAIcon from "../FAIcon";
import { useTranslation } from "react-i18next";
import canonicalPlaybackTime from "@/libs/canonical-playback-time";

const RECORDING_AREA_SIZE = 260;

const ProgressBar = ({ children, ...props }) => (
  <CircularProgress
    position="absolute"
    size={128}
    thickness={8}
    color="#46CB53"
    trackColor="#484848"
    {...props}
  >
    <CircularProgressLabel>{children}</CircularProgressLabel>
  </CircularProgress>
);

const AudioStatus = ({ isRecording, audioUrl, reviewing, duration }) => {
  const [reviewProgress, setReviewProgress] = useState(0);

  const onTimeUpdate = useCallback((e) => {
    setReviewProgress(e.target.currentTime);
  }, []);
  useEffect(() => {
    if (!reviewing) {
      setReviewProgress(0);
    }
  }, [reviewing]);

  if (isRecording) return <FAIcon type="square" color="white" fontSize="2xl" />;
  switch (true) {
    case !audioUrl:
      return (
        <ProgressBar value={0}>
          <FAIcon type="circle" color="red" fontSize="2xl" />
        </ProgressBar>
      );
    case reviewing:
      return (
        <ProgressBar value={(100 * reviewProgress) / duration}>
          <FAIcon type="square" color="#46CB53" fontSize="2xl" />
          <audio
            autoPlay
            src={audioUrl}
            type="audio/ogg"
            onTimeUpdate={onTimeUpdate}
          />
        </ProgressBar>
      );
    default:
      return (
        <ProgressBar value={0}>
          <FAIcon type="play" color="#46CB53" fontSize="2xl" />
        </ProgressBar>
      );
  }
};

const AudioRecoder = ({ active, onSend, ...props }) => {
  const { t } = useTranslation();
  const [audioBlob, setAudioBlob] = useState(null);
  const [reviewing, setReviewing] = useState(false);
  const [duration, setDuration] = useState(0);

  const {
    startRecording,
    stopRecording,
    recordingBlob,
    isRecording,
    recordingTime,
  } = useAudioRecorder();

  const doneRecording = !isRecording && audioBlob;
  const displayTime = doneRecording ? parseInt(duration) : recordingTime;

  const audioUrl = useMemo(
    () => (audioBlob ? URL.createObjectURL(audioBlob) : null),
    [audioBlob],
  );

  const discardBlob = useCallback(() => {
    setAudioBlob(null);
    setDuration(0);
  }, []);

  const sendAudio = useCallback(() => {
    if (!audioBlob) return;
    onSend(audioBlob);
  }, [audioBlob, onSend]);

  const audioControl = useCallback(() => {
    if (isRecording) {
      stopRecording();
    } else if (!audioBlob) {
      startRecording();
      setReviewing(false);
    } else {
      setReviewing((reviewing) => !reviewing);
    }
  }, [audioBlob, isRecording, startRecording, stopRecording]);

  // recorder blob buffer
  useEffect(() => {
    if (recordingBlob) {
      setAudioBlob(recordingBlob);
      getBlobDuration(recordingBlob).then(setDuration);
    }
  }, [recordingBlob]);

  // active status listener
  useEffect(() => {
    if (!active) {
      stopRecording();
      discardBlob();
    }
  }, [active, discardBlob, stopRecording]);

  return (
    <Box height={`${RECORDING_AREA_SIZE}px`} {...props}>
      <Box height={12}>
        {isRecording || doneRecording ? (
          <Text align="center" color="white" fontSize="2xl">
            {canonicalPlaybackTime(displayTime)}
          </Text>
        ) : (
          <Text align="center" color="#484848">
            {t("chats.recorder.recorder_hint")}
          </Text>
        )}
      </Box>
      <Flex justify="center" align="center" gap={10}>
        <IconButton
          id="button__recorder_discard"
          isRound={true}
          variant="solid"
          onClick={discardBlob}
          isDisabled={isRecording || !audioBlob}
          bg="#D9D9D9"
          fontSize={18}
          size="lg"
          color="white"
          icon={<FAIcon type="trash-can" />}
        />
        <Square
          role="button"
          id="button__recorder_record"
          size={120}
          rounded="full"
          color="white"
          position="relative"
          bg={isRecording ? "#46CB53" : "inherit"}
          onClick={audioControl}
        >
          <AudioStatus
            isRecording={isRecording}
            audioUrl={audioUrl}
            reviewing={reviewing}
            duration={duration}
          />
        </Square>
        <IconButton
          id="button__recorder_send"
          isRound={true}
          variant="solid"
          onClick={sendAudio}
          isDisabled={isRecording || !audioBlob}
          bg="#46CB53"
          fontSize={18}
          size="lg"
          color="white"
          icon={<FAIcon type="send" />}
        />
      </Flex>
    </Box>
  );
};

export default AudioRecoder;
