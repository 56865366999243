import { Circle, GridItem, SimpleGrid, Text } from "@chakra-ui/react";
import Diamond from "@/assets/images/diamond.svg?react";
import Scroll from "@/assets/images/scroll.svg?react";
import Star from "@/assets/images/star-alternate.svg?react";
import Discount from "@/assets/images/discount.svg?react";
import Gift from "@/assets/images/gift.svg?react";
import Trophy from "@/assets/images/trophy.svg?react";

const Premiums = ({ ...props }) => (
  <SimpleGrid
    columns={3}
    spacing={1}
    mx="auto"
    fontSize={{ base: "10px", md: "md" }}
    {...props}
  >
    <GridItem p={2} display="flex" flexDir="column" alignItems="center">
      <Circle bg="#413729" size={{ base: 16, md: 20 }} mb={1}>
        <Diamond />
      </Circle>
      <Text color="#AE8241">每日加贈 500 鑽石</Text>
      <Text color="#E5D6C6">免費領</Text>
    </GridItem>
    <GridItem p={2} display="flex" flexDir="column" alignItems="center">
      <Circle bg="#413729" size={{ base: 16, md: 20 }} mb={1}>
        <Discount />
      </Circle>
      <Text color="#AE8241">儲值鑽石專屬折扣</Text>
      <Text color="#E5D6C6">79折起</Text>
    </GridItem>
    <GridItem p={2} display="flex" flexDir="column" alignItems="center">
      <Circle bg="#413729" size={{ base: 16, md: 20 }} mb={1}>
        <Gift />
      </Circle>
      <Text color="#AE8241">活動福利再升級</Text>
      <Text color="#E5D6C6">專屬權益</Text>
    </GridItem>
    <GridItem p={2} display="flex" flexDir="column" alignItems="center">
      <Circle bg="#413729" size={{ base: 16, md: 20 }} mb={1}>
        <Scroll />
      </Circle>
      <Text color="#AE8241">官方實體活動</Text>
      <Text color="#E5D6C6">優先報名</Text>
    </GridItem>
    <GridItem p={2} display="flex" flexDir="column" alignItems="center">
      <Circle bg="#413729" size={{ base: 16, md: 20 }} mb={1}>
        <Trophy />
      </Circle>
      <Text color="#AE8241">新功能內部測試</Text>
      <Text color="#E5D6C6">搶先體驗</Text>
    </GridItem>
    <GridItem p={2} display="flex" flexDir="column" alignItems="center">
      <Circle bg="#413729" size={{ base: 16, md: 20 }} mb={1}>
        <Star />
      </Circle>
      <Text color="#AE8241">成為最矚目的人</Text>
      <Text color="#E5D6C6">專屬VIP圖框</Text>
    </GridItem>
  </SimpleGrid>
);

export default Premiums;
