import ChatContext from "@/context/chat";
import useAPI from "@/libs/hooks/api";
import {
  Box,
  Flex,
  Text,
  VStack,
  Button,
  Square,
  Image,
} from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import profilePlaceholder from "@/assets/images/profile-placeholder.png";
import Skeletons from "@/components/Skeletons";
import CreatorAvatar from "@/components/CreatorAvatar";

const FollowedCreators = () => {
  const { t } = useTranslation();
  const api = useAPI();
  const [creators, setCreators] = useState(null);
  const { resolveChatUrl } = useContext(ChatContext);
  const navigate = useNavigate();

  const to = useCallback((url) => () => navigate(url), [navigate]);

  useEffect(() => {
    api.getFollowedCreators().then(({ data }) =>
      setCreators(
        data.map(({ creator, followedAt }) => ({
          followedAt,
          ...creator,
        })),
      ),
    );
  }, [api]);

  return (
    <Box p={6} color="white">
      <Text fontSize="xl" pb={3}>
        {t("profile.tabs.title.follows")}
      </Text>
      <VStack align="stretch" gap={3}>
        {creators == null &&
          Array.from({ length: 8 }).map((_, index) => (
            <Skeletons.Creator key={index} />
          ))}
        {creators?.length === 0 && (
          <VStack align="center" pt={10}>
            <Square size={36}>
              <Image src={profilePlaceholder} />
            </Square>
            <Text mt={3}>{t("profile.placeholder")}</Text>
          </VStack>
        )}
        {creators?.map(({ id, picture, slug, displayName }) => (
          <Flex key={id} align="center">
            <Flex
              role="button"
              onClick={() => navigate(`/c/@${slug}`)}
              gap={3}
              flex={1}
              align="center"
            >
              <CreatorAvatar id={id} picture={picture} />
              <Box>
                <Text fontSize="lg">@{slug}</Text>
                <Text mt={1}>{displayName}</Text>
              </Box>
            </Flex>

            <Button
              variant="themed-outline"
              bg="#333333"
              px={6}
              height={8}
              onClick={to(resolveChatUrl(id))}
            >
              {t("profile.follower_actions.chat")}
            </Button>
          </Flex>
        ))}
      </VStack>
    </Box>
  );
};

export default FollowedCreators;
