import { Flex, Text, Box } from "@chakra-ui/react";
import { useCallback } from "react";
import { Trans } from "react-i18next";

const SubscribePlan = ({
  plan,
  cost,
  discount = 0,
  rate,
  duration,
  recommended,
  onSelect,
}) => {
  const fixedNumber = useCallback(
    (n) => (rate !== 1 ? (n * rate).toFixed(2) : n),
    [rate],
  );

  return (
    <Flex
      role="button"
      onClick={onSelect}
      borderColor="#8E7046"
      borderWidth={2}
      borderRadius={12}
      justify="space-between"
      align="center"
      position="relative"
      px={4}
      py={6}
    >
      <Flex align="flex-end" gap={1}>
        <Text fontWeight="bold">
          <Trans i18nKey={`common.vip_plans.${plan.replace("-", "_")}`} />
        </Text>
        <Text fontSize="xs">/{duration}個月</Text>
      </Flex>

      <Box textAlign="right" position="relative">
        <Text pt={discount ? 3 : 0}>
          $ {fixedNumber(cost - discount)} {!discount && "/月"}
        </Text>
        {!!discount && (
          <Text
            position="absolute"
            right={0}
            bottom={-4}
            whiteSpace="nowrap"
            fontSize="sm"
            color="#8E8E93"
          >
            $ {fixedNumber((cost - discount) / duration)}/月
          </Text>
        )}
      </Box>
      {!!discount && (
        <Flex
          position="absolute"
          right={-4}
          top={-2}
          px={3}
          rounded="xl"
          align="center"
        >
          {recommended && (
            <Box pl={3} pr={7} mr={-6} rounded="xl" bg="#C93434" pb={1}>
              最推薦
            </Box>
          )}

          <Box
            px={3}
            pb={1}
            rounded="xl"
            bg="linear-gradient(180deg, #F7C74E 0%, #F28C32 100%)"
            color="black"
          >
            {recommended ? "SALE! 激省" : "立即省下"}{" "}
            {parseInt((discount / cost) * 100)}%
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default SubscribePlan;
