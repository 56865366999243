import {
  Avatar,
  Box,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Square,
  useDisclosure,
} from "@chakra-ui/react";
import getPublicUrl from "@/libs/get-public-url";
import FAIcon from "./FAIcon";
import VIPFrame from "@/assets/images/vip-frame.svg?react";

const AvatarWithModal = ({ source, vip = false }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box position="relative">
        <Avatar
          src={getPublicUrl(source)}
          role="button"
          size="xl"
          onClick={source ? onOpen : null}
        />
        {vip && (
          <Box position="absolute" top={0} pointerEvents="none" width="100%">
            <VIPFrame />
          </Box>
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalBody>
          <ModalContent bg="transparent">
            <Square size={{ base: 48, md: 72 }} m="auto" position="relative">
              <Image src={getPublicUrl(source)} />
              <FAIcon
                type="circle-xmark"
                role="button"
                onClick={onClose}
                position="absolute"
                right="-10px"
                top="-10px"
                color="secondary.100"
                opacity={0.5}
                fontSize={20}
              />
            </Square>
          </ModalContent>
        </ModalBody>
      </Modal>
    </>
  );
};
export default AvatarWithModal;
