import {
  Box,
  Collapse,
  Flex,
  Image,
  Input,
  Textarea,
  useBoolean,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ResizeTextarea from "react-textarea-autosize";
import FAIcon from "../FAIcon";
import AudioRecoder from "./AudioRecorder";
import trimMessage from "@/libs/trim-message";
import Gifts from "./Gifts";
import { flushSync } from "react-dom";
import { Trans } from "react-i18next";
import Currency from "@/components/Currency";

const MAX_WORD_COUNT = 600;
const HEIGHT = 80;
const ATTACHMENT_TYPES = {
  VIDEO: "video",
  IMAGE: "image",
};

const InputArea = ({ balance, charge, sendMessage, sendAudio, sendGift }) => {
  const [value, setValue] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [typing, setTyping] = useBoolean(false);
  const [giftSelecting, setGiftSelecting] = useBoolean(false);
  const [attachmentType, setAttachmentType] = useState(ATTACHMENT_TYPES.IMAGE);
  const textRef = useRef(null);
  const recorderRef = useRef(null);
  const giftsRef = useRef(null);
  const fileRef = useRef(null);
  const videoRef = useRef(null);

  const attachmentUrl = useMemo(() => {
    if (!attachment) return null;
    if (window.webkitURL) {
      return window.webkitURL.createObjectURL(attachment);
    } else if (window.URL && window.URL.createObjectURL) {
      return window.URL.createObjectURL(attachment);
    } else {
      return null;
    }
  }, [attachment]);

  const insufficientBalance = balance < charge;

  const imageAttached = attachment && attachmentType === ATTACHMENT_TYPES.IMAGE;
  const videoAttached = attachment && attachmentType === ATTACHMENT_TYPES.VIDEO;

  const {
    isOpen: recorderOpen,
    onClose: closeRecorderUI,
    onToggle: toggleRecorderUI,
  } = useDisclosure();
  const {
    isOpen: giftsOpen,
    onClose: closeGiftsUI,
    onToggle: toggleGiftsUI,
  } = useDisclosure();

  useOutsideClick({
    ref: textRef,
    handler: () => setTyping.off(),
  });

  useOutsideClick({
    ref: recorderRef,
    handler: () => closeRecorderUI(),
  });

  useOutsideClick({
    ref: giftsRef,
    // if enabled, the order confirm modal will act funny, so this should be disabled after it's done
    enabled: !giftSelecting,
    handler: () => closeGiftsUI(),
  });

  const resetFile = useCallback(() => {
    setAttachment(null);
    fileRef.current.value = null;
  }, []);

  const attachFile = useCallback(
    (type) => () => {
      resetFile();
      setAttachmentType(type);
      // force flush so that the ref click trick could work
      flushSync();
      fileRef.current.click();
    },
    [resetFile],
  );

  const onInput = useCallback((e) => {
    const v = e.target.value;
    if (v.length > MAX_WORD_COUNT) return;
    setValue(v);
  }, []);

  const onAudioSend = useCallback(
    async (blob) => {
      if (insufficientBalance) return;
      sendAudio(blob);
      closeRecorderUI();
    },
    [closeRecorderUI, insufficientBalance, sendAudio],
  );

  const onGiftSend = useCallback(
    (gift) => {
      sendGift(gift);
      closeGiftsUI();
    },
    [closeGiftsUI, sendGift],
  );

  const onTextSend = useCallback(async () => {
    const text = trimMessage(value);
    if (!(text || attachment)) return;
    if (insufficientBalance) return;
    sendMessage({
      text: text || undefined,
      type: attachment ? attachmentType : "text",
      attachment,
    });
    setValue("");
    resetFile();
  }, [
    attachment,
    attachmentType,
    insufficientBalance,
    resetFile,
    sendMessage,
    value,
  ]);

  // load source of video
  useEffect(() => {
    if (videoRef.current) videoRef.current.load();
  }, [videoAttached]);

  return (
    <Box
      position="absolute"
      bottom={0}
      left={0}
      width="100%"
      transition=".2s all"
      // set zIndex = 3 so that the audio player in messsage won't break the layout
      zIndex={3}
      ref={textRef}
    >
      {attachmentUrl && (
        <Box width="100%" height={16} bg="#352815" p={3}>
          <Box height="100%" width="fit-content" position="relative">
            {attachmentType === ATTACHMENT_TYPES.IMAGE ? (
              <Image src={attachmentUrl} height="100%" />
            ) : (
              <video
                src={attachmentUrl}
                ref={videoRef}
                style={{ height: "100%" }}
              />
            )}
            <FAIcon
              id="button__chat_cancel-upload"
              type="circle-xmark"
              role="button"
              onClick={resetFile}
              position="absolute"
              right="-6px"
              top="-5px"
              color="secondary.100"
              opacity={0.5}
              fontSize={12}
            />
          </Box>
        </Box>
      )}

      <Flex
        bg="linear-gradient(to right, #D7913F, #A86A31)"
        py={4}
        px={4}
        gap={3}
        align="center"
        color="white"
        fontSize="xl"
      >
        <Input
          type="file"
          accept={
            attachmentType === ATTACHMENT_TYPES.IMAGE
              ? "image/*"
              : "video/mp4,video/x-m4v,video/*"
          }
          ref={fileRef}
          hidden
          onChange={(e) => setAttachment(e.target.files[0])}
        />

        {typing ? (
          <Box
            role="button"
            id="button__chat_collapse-options"
            onClick={setTyping.off}
          >
            <FAIcon type="chevron-right" />
          </Box>
        ) : (
          <>
            <Box
              role="button"
              id="button__chat_attach-video"
              onClick={attachFile(ATTACHMENT_TYPES.VIDEO)}
              color={videoAttached ? "secondary.100" : "white"}
            >
              <FAIcon type="film" />
            </Box>
            <Box
              role="button"
              id="button__chat_attach-image"
              onClick={attachFile(ATTACHMENT_TYPES.IMAGE)}
              color={imageAttached ? "secondary.100" : "white"}
            >
              <FAIcon type="image" />
            </Box>
          </>
        )}

        <Box position="relative" flex={1}>
          <Textarea
            id="textarea__chat_input"
            as={ResizeTextarea}
            value={value}
            onChange={onInput}
            onFocus={setTyping.on}
            bg="white"
            borderRadius={30}
            rows={1}
            pt={value ? 4 : null}
            maxRows={6}
            resize="none"
            color="black"
            sx={{
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          />
          <Flex
            position="absolute"
            pointerEvents="none"
            align="center"
            fontSize={value ? ".75rem" : "sm"}
            top={value ? 1 : 0}
            left={4}
            py={value ? 2 : 0}
            height={value ? 15 : "100%"}
            opacity={value ? 1 : 0.6}
            bg={value ? "rgba(255,255,255,.85)" : "transparent"}
            gap="2px"
            color={insufficientBalance ? "red" : "#9857BD"}
            zIndex={1}
            transition="all .2s"
          >
            <Trans
              i18nKey="chats.send_message_hint"
              defaults="Send with <highlight>{{charge}}<highlight> {{currency}} <symbol></symbol>"
              values={{ charge }}
              components={{
                highlight: (
                  <Box
                    fontWeight="bold"
                    color={insufficientBalance ? "inherit" : "secondary.500"}
                  />
                ),
                symbol: <Currency size={4} />,
              }}
            />
          </Flex>
          {typing && (
            <Flex
              position="absolute"
              pointerEvents="none"
              align="center"
              fontSize={value ? ".75rem" : "sm"}
              top={value ? 1 : 0}
              p={value ? 2 : 0}
              height={value ? 15 : "100%"}
              bg={value ? "rgba(255,255,255,.85)" : "transparent"}
              opacity={value ? 1 : 0.6}
              right={4}
              gap="2px"
              zIndex={1}
              color={value.length >= MAX_WORD_COUNT ? "red.400" : "gray.800"}
              transition="all .2s"
            >
              {value.length}/{MAX_WORD_COUNT} 字
            </Flex>
          )}
        </Box>

        {(typing || value || attachmentUrl) && (
          <Box
            role="button"
            id="button__chat_send"
            onClick={onTextSend}
            opacity={insufficientBalance ? 0.6 : 1}
            color={
              insufficientBalance ? "rgba(255,255,255,.6)" : "secondary.100"
            }
          >
            <FAIcon type="send" />
          </Box>
        )}

        {!typing && (
          <>
            <Box
              role="button"
              id="button__chat_toggle-recorder"
              onClick={() => {
                toggleRecorderUI();
                closeGiftsUI();
              }}
              color={recorderOpen ? "secondary.100" : "white"}
            >
              <FAIcon type="microphone" />
            </Box>

            <Box
              role="button"
              id="button__chat_toggle-gifts"
              onClick={() => {
                toggleGiftsUI();
                closeRecorderUI();
              }}
              color={giftsOpen ? "secondary.100" : "white"}
            >
              <FAIcon type="gift" />
            </Box>
          </>
        )}
      </Flex>
      <Collapse in={recorderOpen} animateOpacity={false} ref={recorderRef}>
        <AudioRecoder
          active={recorderOpen}
          onSend={onAudioSend}
          bg="#1D1D1D"
          p={10}
        />
      </Collapse>
      <Collapse in={giftsOpen} animateOpacity={false} ref={giftsRef}>
        <Gifts
          onSend={onGiftSend}
          onSelect={setGiftSelecting.on}
          onCancel={setGiftSelecting.off}
          bg="#1D1D1D"
        />
      </Collapse>
    </Box>
  );
};
InputArea.HEIGHT = HEIGHT;

export default InputArea;
