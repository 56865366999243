import useDeviceInfo from "@/libs/hooks/device-info";
import MobileNavigation from "./MobileNavigation";

import {
  Drawer,
  DrawerCloseButton,
  Text,
  Box,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
} from "@chakra-ui/react";

const ConfigurationMenu = ({
  isOpen,
  onClose,
  title,
  children,
  respectNavigation = true,
  ...rest
}) => {
  const { isMobile } = useDeviceInfo();

  return (
    <Drawer
      isOpen={isOpen}
      placement={isMobile ? "bottom" : "right"}
      onClose={onClose}
      {...rest}
    >
      <DrawerOverlay bg="transparent" />
      <DrawerContent
        pb={respectNavigation ? `${MobileNavigation.HEIGHT}px` : 0}
        minHeight="40vh"
        bg="#1D1D1D"
        color="white"
        borderTopRadius={8}
      >
        <DrawerHeader
          borderBottomWidth={2}
          borderColor="#484848"
          align="center"
          position="relative"
        >
          <Box
            height={1}
            width={10}
            bg="white"
            position="absolute"
            top={0}
            left={0}
            right={0}
            borderRadius={3}
            mx="auto"
          />
          <Text align="center">{title}</Text>
          <DrawerCloseButton onClick={onClose} mt={2} />
        </DrawerHeader>
        <DrawerBody p={4}>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ConfigurationMenu;
