import dayjs from "dayjs";
import i18next from "i18next";

const canonicalDate = (raw) => {
  const now = dayjs();
  const time = dayjs(raw);
  switch (true) {
    case time.isSame(now, "day"):
      return time.format("HH:mm");
    case now.diff(time, "day") <= 2:
      return i18next.t("common.date.yesterday");
    case now.diff(time, "day") <= 7:
      return i18next.t(`common.date.${time.format("dddd").toLowerCase()}`);
    default:
      return time.format("MM/DD");
  }
};

export default canonicalDate;
