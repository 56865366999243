import ChatContext from "@/context/chat";
import { Avatar, Flex, Text, useToast } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import FAIcon from "./FAIcon";
import { useLocation } from "react-router-dom";

const IncomingMessage = () => {
  const { incoming, setIncoming } = useContext(ChatContext);
  const toast = useToast();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!incoming?.length) return;
    // if already in chat, block the toast
    const blocked = pathname.startsWith("/chat/");

    const clone = incoming.slice();
    const { message, image } = clone.shift();
    if (!blocked)
      toast({
        render: ({ onClose }) => (
          <Flex
            px={3}
            py={2}
            gap={2}
            bg="#e3e3e3"
            color="#212121"
            align="center"
            rounded="lg"
          >
            <Avatar src={image} size="sm" />
            <Text flex={1} fontWeight="semibold">
              {message}
            </Text>
            <FAIcon
              role="button"
              type="circle-x"
              color="gray.500"
              onClick={onClose}
            />
          </Flex>
        ),
        duration: 4000,
      });
    setIncoming(clone);
  }, [incoming, pathname, setIncoming, toast]);
  return null;
};

export default IncomingMessage;
