import AuthContext from "@/context/auth";
import PersistentStorage from "@/libs/persistent-storage";
import {
  Box,
  Divider,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import UserTour from "./UserTour";

const NOTIFICATION_LAST_REQUESTED_KEY = "NOTIFICATION_PERMISSION_REQUESTED";
// retry permission every 24 hours
const RETRY_PERIOD = 24 * 60 * 60 * 1000;

const RequestNotificationPermission = () => {
  const { requestToken, user } = useContext(AuthContext);

  const [open, setOpen] = useState(true);
  const [permission, setPermission] = useState(null);

  const isOpen = useMemo(() => {
    const lastRequestedAt = user
      ? +PersistentStorage.getItem(NOTIFICATION_LAST_REQUESTED_KEY)
      : Infinity;
    const retry =
      permission === "default" && Date.now() - lastRequestedAt >= RETRY_PERIOD;
    // @todo: occasionally ask user permission even if user denied

    const shouldOpen =
      (!lastRequestedAt && user && permission === "default") || retry;
    return open && shouldOpen;
  }, [open, permission, user]);

  const onCancel = useCallback(() => {
    PersistentStorage.setItem(NOTIFICATION_LAST_REQUESTED_KEY, Date.now());
    setOpen(false);
  }, []);

  const onApprove = useCallback(async () => {
    await Notification.requestPermission();
    PersistentStorage.setItem(NOTIFICATION_LAST_REQUESTED_KEY, Date.now());
    setOpen(false);
    await requestToken();
  }, [requestToken]);

  // listen to state change
  useEffect(() => {
    navigator?.permissions?.query({ name: "notifications" }).then((status) => {
      const newPermission =
        status.state === "prompt" ? "default" : status.state;
      setPermission(newPermission);
      status.onchange = () => setPermission(Notification.permission);
    });
  }, []);
  return (
    <Modal isOpen={isOpen} onClose={onCancel} isCentered>
      <ModalOverlay zIndex={UserTour.Z_INDEX + 100} />
      <ModalContent
        bg="transparent"
        boxShadow="none"
        containerProps={{ zIndex: UserTour.Z_INDEX + 100 }}
      >
        <ModalBody>
          <Box
            bg="#151515"
            mx={8}
            maxW={400}
            color="white"
            p={6}
            rounded={16}
            borderWidth="1px"
            borderColor="secondary.100"
          >
            <Text align="center" fontSize="xl" mb={3}>
              開啟通知
            </Text>
            <Divider borderColor="#484848" my={2} />
            <Box align="center" fontSize="sm" my={4}>
              <Text mb={2}>開啟通知接受最新的消息！</Text>
              <Text>即刻與需要愛的人進行互動～</Text>
            </Box>
            <Flex gap={6} mt={6}>
              <Button
                onClick={onCancel}
                flex={1}
                colorScheme="whiteAlpha"
                borderRadius={20}
              >
                之後再說
              </Button>
              <Button onClick={onApprove} variant="themed" flex={1}>
                設定
              </Button>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RequestNotificationPermission;
