import {
  Box,
  Flex,
  Text,
  Button,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import AvatarWithModal from "@/components/AvatarWithModal";
import FAIcon from "@/components/FAIcon";
import Header from "@/components/Header";
import ChatContext from "@/context/chat";
import UserOverridesContext from "@/context/user-overrides";
import useAPI from "@/libs/hooks/api";
import toDisplayNumber from "@/libs/to-display-number";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import usePolyfills from "@/libs/hooks/polyfills";
import LoadMore from "./LoadMore";
import { FETCHING_STATUS } from "@/constants";
import AuthContext from "@/context/auth";
import PlaylistContext from "@/context/playlist";
import VideoPreview from "./VideoPreview";
import Playlist from "./Playlist";
import { flushSync } from "react-dom";
import ShareModal from "./ShareModal";
import MemberOnlyWall from "./MemberOnlyWall";

const ActionButton = ({ children, isDisabled, ...props }) => (
  <Button
    variant="themed-outline"
    isDisabled={isDisabled}
    flex={1}
    poistion="relative"
    {...props}
  >
    {children}
    {isDisabled && (
      <Flex
        position="absolute"
        left={0}
        top={0}
        width="100%"
        height="100%"
        bg="rgba(0,0,0,0.5)"
        borderRadius={8}
        align="center"
        justify="center"
      >
        <FAIcon type="lock" variant="regular" fontSize="xl" />
      </Flex>
    )}
  </Button>
);

const CreatorProfile = ({ handle, onBack, onVideoClicked }) => {
  const { t } = useTranslation();
  const { getMaxHeightRepresentation } = usePolyfills();
  const maxHeight = getMaxHeightRepresentation();
  const api = useAPI();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const [shareData, setShareData] = useState(null);
  const { resolveChatUrl } = useContext(ChatContext);
  const { user } = useContext(AuthContext);
  const { overrides, overrideCreator } = useContext(UserOverridesContext);
  const { videos, setVideos, jumpTo, resetVideos } =
    useContext(PlaylistContext);
  const toast = useToast();
  const [videoFetchingStatus, setVideoFetchingStatus] = useState(
    FETCHING_STATUS.IDLE,
  );
  const [allVideosLoaded, setAllVideosLoaded] = useState(false);
  const [creator, setCreator] = useState(null);

  const [expandIntroduction, setExpandIntroduction] = useState(false);
  const followed = useMemo(() => {
    if (!creator) return false;
    const override = overrides.creators[creator.id];
    return override?.isFollowed ?? creator.isFollowed;
  }, [creator, overrides.creators]);

  const openChat = useCallback(
    () => navigate(resolveChatUrl(creator.id)),
    [navigate, resolveChatUrl, creator],
  );

  const onCreatorClick = useCallback(
    () => setSearchParams({}, { replace: true }),
    [setSearchParams],
  );

  const onChatClick = useCallback(() => {
    setSearchParams({}, { replace: true });
    navigate(resolveChatUrl(creator.id));
  }, [creator, navigate, resolveChatUrl, setSearchParams]);

  const clicksVideo = useCallback(
    (index) => () => {
      jumpTo(index);
      if (onVideoClicked) onVideoClicked();
    },
    [jumpTo, onVideoClicked],
  );

  const toggleFollowed = useCallback(
    async (e) => {
      if (e) e.stopPropagation();
      const action = followed ? api.unfollowCreator : api.followCreator;
      await action(creator.id);
      overrideCreator(creator.id)({ isFollowed: !followed });
    },
    [followed, api, creator, overrideCreator],
  );

  const shareCreator = useCallback(async () => {
    const canShare = typeof navigator?.share === "function";
    const { displayName, introduce, slug } = creator;
    const data = {
      title: `來 NightCo 與${displayName}相遇 ♥️`,
      text: introduce,
      url: `https://${window.location.host}/c/@${slug}`,
    };
    if (canShare) {
      try {
        await navigator.share(data);
        toast({ title: "已分享!", status: "info" });
      } catch (e) {
        const isAborted = e instanceof DOMException && e.name === "AbortError";
        if (!isAborted) console.error(e);
      }
    } else {
      setShareData(data);
    }
  }, [creator, toast]);

  const loadMoreVideos = useCallback(async () => {
    const id = creator?.id;
    if (!id) return;
    setVideoFetchingStatus(FETCHING_STATUS.FETCHING);
    const lastId = videos?.[videos?.length - 1]?.id;
    const { data, paginator } = await api.getCreatorVideos(id, lastId);
    setVideoFetchingStatus(FETCHING_STATUS.FINISHED);

    if (!data.length) {
      setAllVideosLoaded(true);
      return;
    }
    setVideos((state) => (state ? state.concat(data) : data));
    if (!paginator?.next) setAllVideosLoaded(true);
  }, [api, creator, setVideos, videos]);

  // fetch creator & video info
  useEffect(() => {
    async function fetchData() {
      try {
        const handleIsSlug = handle.startsWith("@");
        const creatorInfo = await api.getCreator(
          handleIsSlug ? handle.slice(1) : handle,
        );
        if (!creatorInfo.id) throw new Error("CREATOR_NOT_EXISTS");
        if (!handleIsSlug)
          navigate(`/c/@${creatorInfo.slug}`, { replace: true, shallow: true });
        flushSync(() => setCreator(creatorInfo));
        resetVideos(null);
        loadMoreVideos();
      } catch (e) {
        if (e.message === "CREATOR_NOT_EXISTS") {
          toast({
            title: t("creator.error.not_exists"),
            duration: 2000,
            status: "error",
          });
          return navigate("/");
        }
        console.error(e);
      }
    }
    if (!creator?.id) fetchData();
  }, [api, handle, navigate, toast, t, loadMoreVideos, creator, resetVideos]);

  const {
    slug,
    displayName,
    picture,
    videosTotal,
    followersTotal,
    likersTotal,
    introduce,
  } = creator || {};

  return (
    <Box bg="primary.100" color="white" minH={maxHeight} position="relative">
      <Header gap={3} quickDeposit>
        <Box role="button" onClick={onBack}>
          <FAIcon type="chevron-left" fontSize="2xl" />
        </Box>
        <Box>
          <Text>{displayName}</Text>
          <Text fontSize="xs">{slug && `@${slug}`}</Text>
        </Box>
      </Header>
      <Flex py={5} pl={8} pr={12} align="center" textAlign="center" gap={8}>
        <AvatarWithModal source={picture} />
        <Flex justify="space-between" flex={1}>
          <Box>
            <Text fontSize="2xl">{toDisplayNumber(videosTotal)}</Text>
            <Text>{t("creator.total_videos")}</Text>
          </Box>
          <Box>
            <Text fontSize="2xl">{toDisplayNumber(followersTotal)}</Text>
            <Text>{t("creator.total_followers")}</Text>
          </Box>
          <Box>
            <Text fontSize="2xl">{toDisplayNumber(likersTotal)}</Text>
            <Text>{t("creator.total_likes")}</Text>
          </Box>
        </Flex>
      </Flex>
      <Box px={6} py={3}>
        <Text
          noOfLines={expandIntroduction ? undefined : 2}
          whiteSpace="pre-wrap"
        >
          {introduce}
        </Text>
        <Text
          onClick={() => setExpandIntroduction(!expandIntroduction)}
          mt={2}
          fontWeight="bold"
        >
          {expandIntroduction ? "收起" : "更多"}
        </Text>
      </Box>

      <Flex color="white" gap={3} px={4}>
        <ActionButton
          isLoading={!creator}
          isDisabled={!user}
          onClick={toggleFollowed}
        >
          {followed ? (
            <>
              <FAIcon type="check" pr={2} />
              {t("creator.actions.followed")}
            </>
          ) : (
            t("creator.actions.follow")
          )}
        </ActionButton>

        <ActionButton
          isLoading={!creator}
          isDisabled={!user}
          onClick={openChat}
        >
          {t("creator.actions.chat")}
        </ActionButton>
        <ActionButton isLoading={!creator} onClick={shareCreator}>
          {t("creator.actions.share")}
        </ActionButton>
      </Flex>

      {videos && (
        <SimpleGrid columns={3} spacing={2} p={6}>
          {videos.map((video, index) => (
            <VideoPreview
              key={`${video.id}-${index}`}
              {...video}
              showPrice={true}
              timestamp={video.updatedAt}
              onClick={clicksVideo(index)}
            />
          ))}
        </SimpleGrid>
      )}

      <ShareModal data={shareData} onClose={() => setShareData(null)} />

      <Playlist
        modal
        onCreatorClick={onCreatorClick}
        onChatClick={onChatClick}
        loader={loadMoreVideos}
        done={allVideosLoaded}
      />

      <LoadMore
        loader={loadMoreVideos}
        loading={videoFetchingStatus === FETCHING_STATUS.FETCHING}
        mt={6}
        done={!creator || allVideosLoaded}
      />
      <MemberOnlyWall
        creator={creator}
        loaded={videoFetchingStatus === FETCHING_STATUS.FINISHED}
      />
    </Box>
  );
};

export default CreatorProfile;
