import { createContext, useState, useCallback } from "react";

const UserOverridesContext = createContext({
  overrides: { creators: {} },
});

const override = (id, overrides) => (data) => {
  return {
    ...data,
    [id]: {
      ...(data[id] || {}),
      ...overrides,
    },
  };
};

// this context is for recording user local actions,
// such as subscribing creators, favorite videos and things,
// so that the UI displays are consistent between pages
// @todo: refactor this to creators context
export const withUserOverridesContext = (Component) => (props) => {
  const [creators, setCreators] = useState({});

  const overrideCreator = useCallback(
    (id) => (overrides) => setCreators(override(id, overrides)),
    [],
  );

  return (
    <UserOverridesContext.Provider
      value={{
        overrides: {
          creators,
        },
        overrideCreator,
      }}
    >
      <Component {...props} />
    </UserOverridesContext.Provider>
  );
};

export default UserOverridesContext;
