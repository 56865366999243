import { Box, Circle, Flex } from "@chakra-ui/react";
import Currency from "./Currency";
import { Link } from "react-router-dom";
import FAIcon from "./FAIcon";

const HEIGHT = 80;

const Header = ({ quickDeposit = false, children, ...props }) => (
  <Flex
    height={`${HEIGHT}px`}
    position="sticky"
    align="center"
    top={0}
    left={0}
    p={4}
    width="100%"
    bg="#141414"
    color="white"
    zIndex={1000}
    {...props}
  >
    {children}
    {quickDeposit && (
      <Box
        as={Link}
        to="/payment/deposit"
        position="absolute"
        right={5}
        size={12}
      >
        <Currency size={8} />
        <Circle
          position="absolute"
          top={-0.5}
          size={4}
          right={-1.5}
          bg="white"
          boxShadow="3px 3px 0px rgba(0,0,0,0.3)"
        >
          <FAIcon type="plus" color="secondary.500" fontSize={14} />
        </Circle>
      </Box>
    )}
  </Flex>
);
Header.HEIGHT = HEIGHT;

export default Header;
