import { Box, Text, AspectRatio } from "@chakra-ui/react";
import vipMember from "@/assets/images/vip-member.png";

const VIPCard = ({ plan }) => (
  <AspectRatio
    ratio={2 / 1}
    backgroundImage={vipMember}
    backgroundSize="contain"
    rounded="xl"
  >
    <Box>
      <Box
        position="absolute"
        left={{ base: 6, md: 8 }}
        top={{ base: 6, md: 8 }}
        color="white"
      >
        <Text fontSize={{ base: "md", md: "xl" }} fontWeight="bold">
          VIP 會員
        </Text>
        <Text fontSize={{ base: "sm", md: "lg" }}>
          Be the king of the night
        </Text>
      </Box>
      <Text
        position="absolute"
        left={{ base: 6, md: 8 }}
        bottom={{ base: 6, md: 8 }}
        color="#EAC897"
        fontSize={{ base: "sm", md: "lg" }}
      >
        {plan}
      </Text>
    </Box>
  </AspectRatio>
);

export default VIPCard;
