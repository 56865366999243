import {
  Badge,
  Box,
  Circle,
  Flex,
  Square,
  Text,
  VStack,
} from "@chakra-ui/react";
import Currency from "../Currency";
import EarlyTicket from "@/assets/images/tickets/early.svg?react";
import VIPTicket from "@/assets/images/tickets/vip.svg?react";
import RegularTicket from "@/assets/images/tickets/regular.svg?react";
import { useTranslation } from "react-i18next";

const IconMap = {
  early_bird: EarlyTicket,
  vip: VIPTicket,
  normal: RegularTicket,
};

const enNameMap = {
  normal: "REGULAR TICKET",
  early_bird: "EARLY BIRD",
  vip: "MEMBER EXCLUSIVE",
};

const discountLabels = {
  early_bird: "搶先購買 89 折",
  vip: "限定 85 折優惠",
  normal: "",
};

const Ticket = ({ type, price, selected, onClick }) => {
  const { t } = useTranslation();
  const themeColor = selected ? "#FFC14C" : "#EAE9E9";
  const textColor1 = selected ? "black" : "#9F9F9F";
  const textColor2 = selected ? "white" : "#9F9F9F";

  const Icon = IconMap[type];
  const englishName = enNameMap[type];
  const discountLabel = discountLabels[type];

  return (
    <Box
      role="button"
      boxShadow={`0 0 0 .5em ${themeColor}`}
      bg="white"
      rounded="md"
      onClick={onClick}
    >
      <Flex align="center" gap={2} px={4} height="6em">
        <Square size={12} pr={1}>
          <Icon fill={themeColor} />
        </Square>
        <Box py={4} flex={3}>
          <Text
            fontSize="md"
            fontWeight="bold"
            color={textColor1}
            whiteSpace="nowrap"
          >
            {t(`streams.tickets.${type}`)}
          </Text>
          <Text
            textTransform="uppercase"
            fontSize="10px"
            color={textColor1}
            whiteSpace="nowrap"
          >
            {englishName}
          </Text>
          {discountLabel && (
            <Badge bg={themeColor} color={textColor2} mt={1} py={1} px={2}>
              {discountLabel}
            </Badge>
          )}
        </Box>
        <VStack alignSelf="stretch" position="relative" gap={1}>
          <Circle
            bg={themeColor}
            size={4}
            position="relative"
            top={-2}
            left={0}
          />
          <Box flex={1} borderRight="1px dashed #CCCCCC" ml="2px" />
          <Circle
            bg={themeColor}
            size={4}
            position="relative"
            bottom={-2}
            left={0}
          />
        </VStack>

        <Flex
          align="center"
          justify="flex-end"
          py={4}
          px={2}
          flex={1}
          minWidth={20}
        >
          <Currency
            size={4}
            mr={1}
            filter={selected ? "none" : "grayscale(1)"}
          />
          <Text
            fontFamily="Outfit"
            fontWeight="bold"
            fontSize="sm"
            color={textColor1}
          >
            {price}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Ticket;
