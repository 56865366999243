import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useToast,
} from "@chakra-ui/react";
import brand from "@/assets/images/brand.png";
import FAIcon from "@/components/FAIcon";
import { useCallback, useContext, useState } from "react";
import useAPI from "@/libs/hooks/api";
import AuthContext from "@/context/auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PostRegisterPage = () => {
  const api = useAPI();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setUser } = useContext(AuthContext);
  const toast = useToast();
  const [displayName, setDisplayName] = useState("");
  const [updating, setUpdating] = useState(false);
  const invalidDisplayName = displayName.length > 10;

  const onConfirm = useCallback(async () => {
    setUpdating(true);
    const response = await api.updateProfile({ displayName });
    setUser((user) => ({ ...user, displayName: response.displayName }));
    setUpdating(false);
    toast({
      title: t("profile.personal_info.update_display_name_success"),
      status: "success",
      duration: 2000,
      onCloseComplete: () => navigate("/"),
    });
  }, [api, displayName, navigate, setUser, t, toast]);

  return (
    <Box>
      <Image src={brand} width={180} mx="auto" />
      <Box color="white" align="center">
        <Text mt={6}>歡迎來到 NightCo</Text>
        <Text>請設定要顯示在 NightCo 的名稱</Text>
      </Box>

      <FormControl my={4}>
        <FormLabel color="white">顯示名稱</FormLabel>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <FAIcon type="user-circle" color="black" />
          </InputLeftElement>
          <Input
            placeholder="要如何稱呼您？"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            isInvalid={invalidDisplayName}
            id="input__auth_display-name"
            bg="white"
          />
        </InputGroup>
        <Text color="#979797" fontSize="sm" mt={1}>
          * 長度約 2-10 個字，中英不限
        </Text>
      </FormControl>
      <Button
        variant="themed"
        isDisabled={invalidDisplayName}
        width="100%"
        isLoading={updating}
        onClick={onConfirm}
      >
        確認
      </Button>
    </Box>
  );
};

export default PostRegisterPage;
