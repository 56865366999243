// context to check if creator is online
import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import AuthContext from "./auth";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useFirebase } from "@/libs/hooks/firebase";

const OnlineStatusContext = createContext({ videos: [] });

export const withOnlineStatusContext = (Component) => (props) => {
  const { firestore } = useFirebase();
  const { user } = useContext(AuthContext);
  const [listening, setListening] = useState([]);
  const [onlineStatus, setOnlineStatus] = useState({});

  const updateListenList = useCallback((newElements) => {
    if (!Array.isArray(newElements)) return;
    setListening((state) => {
      const clone = state.slice();
      newElements.forEach(
        (element) => !clone.includes(element) && clone.push(element),
      );
      return clone.length === state.length ? state : clone;
    });
  }, []);

  // listen creator live state
  useEffect(() => {
    if (!user?.uid) return;
    if (!listening.length) return;
    const unsubscribes = [];
    // firebase can only batch query ids in size of 30
    const BATCH = 30;
    for (let i = 0; i * BATCH < listening.length; i++) {
      const list = listening.slice(i * BATCH, (i + 1) * BATCH);
      const q = query(
        collection(firestore, "creator-online-status"),
        where("__name__", "in", list),
      );

      unsubscribes[i] = onSnapshot(q, async (querySnapshot) => {
        const newStatus = Object.fromEntries(
          querySnapshot.docs.map((doc) => [doc.id, doc.data().lastActiveAt]),
        );
        setOnlineStatus(newStatus);
      });
    }

    return () => unsubscribes.forEach((run) => run());
  }, [firestore, listening, user]);

  return (
    <OnlineStatusContext.Provider
      value={{
        onlineStatus,
        setOnlineStatus,
        listening,
        updateListenList,
      }}
    >
      <Component {...props} />
    </OnlineStatusContext.Provider>
  );
};

export default OnlineStatusContext;
