import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const themed = defineStyle({
  background: "secondary.500",
  color: "white",
  borderRadius: 20,
  _disabled: {
    bg: "secondary.100",
  },
  _hover: {
    _disabled: {
      bg: "secondary.500",
    },
  },
  _active: {
    bg: "secondary.100",
  },
  _loading: {
    bg: "secondary.100",
  },
});

const themedGradient = defineStyle({
  bgGradient: "linear(to-r, #D09031 10%, #FFBC39 44%, #E08D19 99%)",
  color: "white",
  borderRadius: 20,
  py: 5,
  _disabled: {
    bg: "#7D7D7D",
  },
  _hover: {
    bgGradient: "linear(to-r, #FA9A14 10%, #FEC942 44%, #E59D32 99%)",
    _disabled: {
      bg: "#7D7D7D",
    },
  },
  _active: {
    bgGradient: "linear(to-r, #D09031 10%, #FFBC39 44%, #E59D32 99%)",
  },
  _loading: {
    bgGradient: "linear(to-r, #D09031 10%, #FFBC39 44%, #E08D19 99%)",
    opacity: 0.4,
  },
});

const themedOutline = defineStyle({
  borderWidth: 2,
  borderColor: "secondary.500",
  background: "rgba(246, 160, 30, 0.5)",
  borderRadius: 9,
  px: 5,
  color: "white",
  _disabled: {
    bg: "secondary.100",
  },
  _hover: {
    _disabled: {
      bg: "rgba(246, 160, 30, 0.5)",
    },
  },
  _active: {
    bg: "secondary.100",
  },
  _loading: {
    bg: "secondary.100",
  },
});

const themedCancel = defineStyle({
  borderWidth: 2,
  borderColor: "#7D7D7D",
  background: "#7D7D7D",
  borderRadius: 20,
  px: 5,
  color: "white",
  _disabled: {
    bg: "#7D7D7D",
  },
  _hover: {
    _disabled: {
      bg: "rgba(125, 125, 125, 0.8)",
    },
  },
  _active: {
    bg: "#7D7D7D",
  },
  _loading: {
    bg: "#7D7D7D",
  },
});

const buttonTheme = defineStyleConfig({
  variants: {
    themed,
    "themed-gradient": themedGradient,
    "themed-outline": themedOutline,
    "themed-cancel": themedCancel,
  },
});

export default buttonTheme;
