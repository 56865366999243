import { Avatar, Box, Fade, Flex, Image, Text, VStack } from "@chakra-ui/react";
import SuperChat from "./SuperChat";
import { useEffect, useMemo, useState } from "react";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useFirebase } from "@/libs/hooks/firebase";
import dayjs from "dayjs";
import getPublicUrl from "@/libs/get-public-url";
import { useTranslation } from "react-i18next";

function calculateDuration(type, value) {
  if (type === "gift") {
    return 5000;
  } else if (type === "comment") {
    switch (true) {
      case value >= 16800:
        return 10 * 60 * 1000;
      case value >= 11800:
        return 5 * 60 * 1000;
      case value >= 5100:
        return 2 * 60 * 1000;
      case value >= 1300:
        return 30 * 1000;

      default:
        return 0;
    }
  }
  return 0;
}

const PinnedList = ({ id }) => {
  const { t } = useTranslation();
  const { firestore } = useFirebase();
  const [items, setItems] = useState([]);
  const [time, setTime] = useState(Date.now());

  // find the index of the item that should be pinned
  const cropped = useMemo(() => {
    let remainPosition = 4;
    let index = -1;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (time <= item.disappearedAt) {
        remainPosition -= item.type === "gift" ? 2 : 1;
        index = i;
        if (remainPosition <= 0) {
          break;
        }
      }
    }
    return items.slice(0, index + 1);
  }, [items, time]);

  useEffect(() => {
    if (!id) return;
    setInterval(() => setTime(Date.now()), 1000);
  }, [firestore, id]);

  useEffect(() => {
    if (!id) return;
    const tenMinsAgo = dayjs().subtract(10, "minutes").unix() * 1000;
    const q = query(
      collection(firestore, "stream-events"),
      where("streamId", "==", id),
      where("type", "in", ["comment", "gift"]),
      // only get items created in the last 10 minutes
      where("createdAt", ">=", tenMinsAgo),
      orderBy("createdAt", "desc"),
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const events = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const candidates = events
        .filter(({ type, data }) => type === "gift" || data.value >= 1300)
        .map((event) => ({
          ...event,
          disappearedAt:
            event.createdAt + calculateDuration(event.type, event.data.value),
        }));

      setItems(candidates);
    });
    return () => unsubscribe();
  }, [firestore, id]);

  return (
    <VStack align="start" gap={2} flex={1} maxW={300} minH={32} overflow="auto">
      {cropped.map(({ id, type, data, user, disappearedAt }) => (
        <Fade
          key={id}
          in={time <= disappearedAt}
          offsetX={20}
          offsetY={20}
          unmountOnExit
        >
          <Flex gap={3} align="center" color="white" fontSize="sm">
            {type === "comment" && (
              <SuperChat amount={data.value} vip={data.vip}>
                <Text noOfLines={1} wordBreak="break-all">
                  {user?.displayName}：{data.text}
                </Text>
              </SuperChat>
            )}
            {type === "gift" && (
              <Flex
                align="center"
                bg="linear-gradient(90deg, #CC51D7 10%, rgba(192, 94, 200, 0.00) 90%)"
                rounded="full"
                p={1.5}
                gap={2}
              >
                <Avatar
                  src={getPublicUrl(user.picture)}
                  alt={user.displayNameName}
                />
                <Box>
                  <Text fontSize="lg">{user.displayName}</Text>
                  <Text fontSize="xs">
                    送出
                    {t(`gifts.${data.giftId}`)}
                  </Text>
                </Box>
                <Image
                  width={12}
                  src={getPublicUrl(`/assets/gifts/${data.giftId}.png`)}
                />
              </Flex>
            )}
          </Flex>
        </Fade>
      ))}
    </VStack>
  );
};

export default PinnedList;
