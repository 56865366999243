import {
  Box,
  Flex,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
} from "@chakra-ui/react";

const Creator = () => (
  <Flex gap={3} align="center">
    <SkeletonCircle size={14} />
    <Box flex={1}>
      <SkeletonText noOfLines={2} spacing={3} skeletonHeight={3} />
    </Box>
    <Skeleton height={7} width={20} borderRadius={6} />
  </Flex>
);

export default Creator;
