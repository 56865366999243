import OnlineStatusContext from "@/context/online-status";
import { useContext, useEffect, useState } from "react";

// default check every 30 seconds
const useCreatorOnlineStatus = (id, checkInterval = 30) => {
  const { onlineStatus } = useContext(OnlineStatusContext);
  const [online, setOnline] = useState(false);

  useEffect(() => {
    if (!id) return;
    const check = () => {
      const lastActiveAt = onlineStatus[id];
      // if not active for more than 15mins, set offline
      const offline =
        !lastActiveAt || Date.now() - lastActiveAt >= 15 * 60 * 1000;
      setOnline(!offline);
    };
    check();
    const interval = setInterval(check, checkInterval * 1000);
    return () => clearInterval(interval);
  }, [checkInterval, id, onlineStatus]);

  return online;
};

export default useCreatorOnlineStatus;
