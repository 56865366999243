import withPullToRefresh from "@/libs/hoc/pull-to-refresh";
import CreatorProfile from "@/components/CreatorProfile";
import { ScrollRestoration, useParams } from "react-router-dom";
import useNavigateBack from "@/libs/hooks/navigate-back";

const CreatorPage = () => {
  const { handle } = useParams();
  const navigateBack = useNavigateBack();

  return (
    <>
      <CreatorProfile handle={handle} onBack={navigateBack} />
      <ScrollRestoration />
    </>
  );
};

export default withPullToRefresh(CreatorPage);
