import Header from "@/components/Header";
import useCrisp from "@/libs/hooks/crisp";
import usePolyfills from "@/libs/hooks/polyfills";
import { Box, Code, Image, Text, VStack, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate, useRouteError } from "react-router-dom";
import notFound from "@/assets/images/fixing.png";
import { useCallback } from "react";

const ErrorPage = () => {
  const { t } = useTranslation();
  const error = useRouteError();
  const crisp = useCrisp({ load: true, show: true, hideOnClose: false });

  const navigate = useNavigate();

  const { getMaxHeightRepresentation } = usePolyfills();
  const maxH = getMaxHeightRepresentation();

  const backToApp = useCallback(() => {
    navigate("/");
    window.location.reload();
  }, [navigate]);

  const reportError = useCallback(() => {
    const greet = "嗨！我在使用 NightCo 時發生了以下錯誤：\n";
    const message = greet + error.stack.toString();

    crisp.send("text", message);
    crisp.open();
  }, [crisp, error.stack]);

  return (
    <Box bg="primary.100" height={maxH} color="white">
      <Header justify="center">
        <Text fontSize="2xl">NightCo</Text>
      </Header>
      <VStack gap={3} px={20}>
        <Box width={60} mt={20}>
          <Image src={notFound} />
        </Box>
        <Text my={8} width={220}>
          您可按下聯繫客服，一起維護 NightCo
          花園，與眾多夥伴一同成為滅蟲軍隊的成員
        </Text>
        <Code
          maxHeight={{ base: 200, md: 300 }}
          maxW={{ base: 300, md: 600 }}
          overflow="auto"
          p={4}
          mb={3}
          whiteSpace="pre-wrap"
          fontSize="sm"
          bg="#2C2C2C"
          color="white"
          rounded="xl"
        >
          {error.stack}
        </Code>
        <Button onClick={reportError} width={200} borderRadius={20}>
          立即回報客服
        </Button>
        <Button onClick={backToApp} variant="themed" width={200}>
          {t("not_found.back_to_app")}
        </Button>
      </VStack>
    </Box>
  );
};

export default ErrorPage;
