import {
  Box,
  Center,
  Circle,
  Flex,
  Image,
  Square,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import ChatContext from "@/context/chat";
import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import MobileNavigation from "@/components/MobileNavigation";
import Header from "@/components/Header";
import { useTranslation } from "react-i18next";
import usePolyfills from "@/libs/hooks/polyfills";
import FAIcon from "@/components/FAIcon";
import { motion } from "framer-motion";
import chatsPlaceholder from "@/assets/images/chats-placeholder.png";
import canonicalDate from "@/libs/canonical-date";
import CreatorAvatar from "@/components/CreatorAvatar";

const Chat = ({
  id,
  lastMessage,
  lastMessagedAt,
  creator,
  unreadTotal,
  isPinned,
  isMuted,
  onPinToggled,
  onMuteToggled,
}) => {
  const { t } = useTranslation();
  const { onToggle, onOpen, onClose, isOpen, getDisclosureProps } =
    useDisclosure();
  const [touchStartX, setTouchStartX] = useState(0);
  const [hidden, setHidden] = useState(!isOpen);
  const navigate = useNavigate();

  const openChat = useCallback(() => navigate(`/chat/${id}`), [id, navigate]);

  // fallback for desktop devices
  const toggleMoreOptions = useCallback(
    (e) => {
      e.preventDefault();
      onToggle();
    },
    [onToggle],
  );

  // mobile touch wipe event
  const handleTouchStart = useCallback(
    (e) => setTouchStartX(e.targetTouches[0].clientX),
    [],
  );
  const handleTouchMove = useCallback(
    (e) => {
      const touchX = e.targetTouches[0].clientX;
      const SWIPE_THRESHOLD = 60;
      if (touchX - touchStartX > SWIPE_THRESHOLD) {
        onOpen();
      } else if (touchStartX - touchX > SWIPE_THRESHOLD) {
        onClose();
      }
    },
    [onClose, onOpen, touchStartX],
  );

  const handleMuteToggled = useCallback(() => {
    onMuteToggled(id);
    onClose();
  }, [id, onClose, onMuteToggled]);

  const handlePinToggled = useCallback(() => {
    onPinToggled(id);
    onClose();
  }, [id, onClose, onPinToggled]);

  return (
    <Flex
      position="relative"
      minWidth={{ base: "100vw", md: "100%" }}
      overflow="hidden"
      height={28}
      align="stretch"
    >
      <Box
        as={motion.div}
        {...getDisclosureProps()}
        hidden={hidden}
        initial={false}
        flexShrink={0}
        onAnimationStart={() => setHidden(false)}
        onAnimationComplete={() => setHidden(!isOpen)}
        animate={{ width: isOpen ? 180 : 0 }}
        whiteSpace="nowrap"
        overflow="hidden"
        left={0}
        top={0}
        color="white"
        fontSize="2xl"
      >
        <Flex height="100%" align="stretch">
          <VStack
            role="button"
            onClick={handleMuteToggled}
            flex={1}
            justify="center"
            bg={isMuted ? "#905E11" : "secondary.500"}
          >
            <FAIcon type={isMuted ? "bell" : "bell-slash"} fontSize="2xl" />
            <Text fontSize="xs">
              {isMuted ? t("chats.actions.unmute") : t("chats.actions.mute")}
            </Text>
          </VStack>
          <VStack
            role="button"
            onClick={handlePinToggled}
            flex={1}
            justify="center"
            bg={isPinned ? "#905E11" : "secondary.500"}
          >
            <FAIcon type="map-pin" fontSize="2xl" />
            <Text fontSize="xs">
              {isPinned ? t("chats.actions.unpin") : t("chats.actions.pin")}
            </Text>
          </VStack>
        </Flex>
      </Box>
      <Flex
        role="button"
        width="100vw"
        flex={{ md: 1 }}
        align="center"
        onClick={openChat}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onContextMenu={toggleMoreOptions}
        bg={isOpen ? "#3C3420" : "#1C1914"}
        flexShrink={0}
        p={6}
        gap={3}
        color="white"
        borderBottom="2px"
        borderColor="#F6A01E44"
      >
        <Center position="relative" minW="4rem">
          {isPinned && (
            <FAIcon
              type="thumbtack"
              color="secondary.500"
              position="absolute"
              transform="rotate(-30deg)"
              left={-1}
              top={-1}
            />
          )}

          <CreatorAvatar id={creator?.id} picture={creator?.picture} />
        </Center>
        <Box flex={1}>
          <Flex pb={1} gap={2} align="center">
            <Text whiteSpace="nowrap">{creator?.displayName}</Text>
            <Text
              color="#F6A01E"
              fontSize="xs"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              maxW="16ch"
            >
              @{creator?.slug}
            </Text>
            {isMuted && <FAIcon type="bell-slash" color="secondary.500" />}
          </Flex>

          <Text noOfLines={2}>
            {lastMessage.type === "text" && lastMessage.text}
            {lastMessage.type === "video" && t("chats.messages.preview.video")}
            {lastMessage.type === "audio" && t("chats.messages.preview.audio")}
            {lastMessage.type === "gift" && t("chats.messages.preview.gift")}
            {lastMessage.type === "image" && t("chats.messages.preview.image")}
          </Text>
        </Box>
        <Box>
          <Text>{canonicalDate(lastMessagedAt)}</Text>
          {!!unreadTotal && (
            <Circle
              size={7}
              mx="auto"
              mt={3}
              bg="rgba(246, 160, 30, 0.5)"
              color="white"
              fontSize={unreadTotal > 99 ? "xs" : "sm"}
              lineHeight="100%"
              textAlign="center"
            >
              {unreadTotal > 99 ? "99+" : unreadTotal}
            </Circle>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

const ChatsPage = () => {
  const { t } = useTranslation();
  const { chats, pinned, muted, updateMuted, updatePinned } =
    useContext(ChatContext);
  const { getMaxHeightRepresentation } = usePolyfills();
  const maxH = getMaxHeightRepresentation();

  const sortedChats = useMemo(() => {
    const sorter = (a, b) => {
      const aPinIndex = pinned.findIndex((n) => n === a.id);
      const bPinIndex = pinned.findIndex((n) => n === b.id);

      if (aPinIndex !== -1 && bPinIndex !== -1) return bPinIndex - aPinIndex;
      else if (aPinIndex !== -1) return -1;
      else if (bPinIndex !== -1) return 1;
      else return b.lastMessagedAt - a.lastMessagedAt;
    };
    return Object.values(chats).sort(sorter);
  }, [pinned, chats]);

  const toggleChatMuted = useCallback(
    (id) => {
      const alreadyMuted = muted.includes(id);
      updateMuted(id)(!alreadyMuted);
    },
    [muted, updateMuted],
  );

  const toggleChatPinned = useCallback(
    (id) => {
      const alreadyPinned = pinned.includes(id);
      updatePinned(id)(!alreadyPinned);
    },
    [pinned, updatePinned],
  );

  return (
    <Box>
      <Header quickDeposit>
        <Text fontSize="2xl" color="white" m="auto">
          {t("page.title.chats")}
        </Text>
      </Header>
      <VStack
        align="stretch"
        gap={0}
        minH={`calc(${maxH} - ${Header.HEIGHT}px - ${MobileNavigation.HEIGHT}px)`}
        overflow="auto"
      >
        {sortedChats.length ? (
          sortedChats.map((chat) => (
            <Chat
              key={chat.id}
              {...chat}
              isPinned={pinned.includes(chat.id)}
              isMuted={muted.includes(chat.id)}
              onPinToggled={toggleChatPinned}
              onMuteToggled={toggleChatMuted}
            />
          ))
        ) : (
          <VStack align="center" pt={20} color="white">
            <Square size={36}>
              <Image src={chatsPlaceholder} />
            </Square>
            <Text mt={3}>{t("chats.placeholder")}</Text>
          </VStack>
        )}
      </VStack>
    </Box>
  );
};

export default ChatsPage;
