import { useEffect } from "react";

const targetEnv = import.meta.env.VITE_TARGET_ENV;

const withDebug = (Component) => (props) => {
  useEffect(() => {
    let title = null;
    switch (targetEnv) {
      case "local":
        title = "NightCo Local";
        break;
      case "development":
        title = "NightCo Dev";
        break;
      case "staging":
        title = "NightCo Staging";
        break;
      default:
        title = "NightCo";
    }

    document.title = title;
  }, []);
  return <Component {...props} />;
};

export default withDebug;
