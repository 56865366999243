import useDeviceInfo from "@/libs/hooks/device-info";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  Text,
  Divider,
  Center,
  Button,
} from "@chakra-ui/react";

const SystemInfo = ({ isOpen, onClose }) => {
  const { browser } = useDeviceInfo();
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="transparent" boxShadow="none">
        <ModalBody>
          <Box
            bg="#151515"
            mx={8}
            maxW={400}
            color="white"
            p={6}
            rounded={16}
            borderWidth="1px"
            borderColor="secondary.100"
          >
            <Text align="center" fontSize="xl" mb={3}>
              系統資訊
            </Text>
            <Divider borderColor="#484848" my={2} />
            <Box my={4}>
              <Text>NightCo 版本： {import.meta.env.VITE_APP_VERSION}</Text>
              <Text>
                瀏覽器： {browser.getBrowserName()}{" "}
                {browser.getBrowserVersion()}
              </Text>
              <Text>
                作業系統： {browser.getOSName()} {browser.getOSVersion()}
              </Text>
            </Box>
            <Center mt={6}>
              <Button variant="themed" onClick={onClose} width={120}>
                關閉
              </Button>
            </Center>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SystemInfo;
