import useDeviceInfo from "@/libs/hooks/device-info";
import usePWA from "@/libs/hooks/pwa";
import { Button, ScaleFade, Text, VStack, useToast } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import MobileNavigation from "./MobileNavigation";
import AuthContext from "@/context/auth";

const MemberOnlyWall = ({ creator, loaded }) => {
  const { isMobile } = useDeviceInfo();
  const { isPWA, install } = usePWA();
  const { user, authLoaded } = useContext(AuthContext);
  const navigate = useNavigate();
  const toast = useToast();
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const unauthenticated = authLoaded && !user;

  const onCTA = useCallback(async () => {
    if (isPWA || !isMobile) return navigate("/auth/register");
    try {
      await install();
      toast({
        title: "安裝成功！請使用 APP 繼續瀏覽",
        status: "success",
        duration: null,
      });
    } catch (e) {
      return navigate("/install-app");
    }
  }, [install, isMobile, isPWA, navigate, toast]);

  useEffect(() => {
    if (!unauthenticated) return;
    let element = ref.current;
    const observer = new IntersectionObserver(
      (entries) => {
        const intersecting = !!entries.find((entry) => entry.isIntersecting);
        setShow(intersecting);
      },
      {
        root: null,
        threshold: 0.5,
        rootMargin: "40px",
      },
    );
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [unauthenticated]);

  return (
    unauthenticated && (
      <ScaleFade in={loaded && show} offsetY={-20}>
        <VStack
          ref={ref}
          position="absolute"
          bottom={isMobile ? 0 : `-${MobileNavigation.HEIGHT}px`}
          left={0}
          width="100%"
          backdropFilter="blur(16px)"
          bg="rgba(255,255,255,0.1)"
          p={10}
          height={{ base: 320, md: 500 }}
          borderTopLeftRadius={12}
          borderTopRightRadius={12}
        >
          <VStack m="auto" align="stretch" justify="center" maxW={320}>
            <Text align="center" fontSize="xl" fontWeight="bold">
              想探索{creator?.displayName}的美嗎？
            </Text>
            <Button
              variant="outline"
              color="white"
              my={2}
              borderRadius={0}
              borderWidth={2}
              size="sm"
              onClick={onCTA}
            >
              {isPWA || !isMobile ? "立即登入 / 註冊" : "立即下載 APP"}
            </Button>
            <Text align="center" fontWeight="bold">
              將會獲得更多他的樣貌 {">"}w{"<"}
            </Text>
          </VStack>
        </VStack>
      </ScaleFade>
    )
  );
};

export default MemberOnlyWall;
