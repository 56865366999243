import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useNavigateBack = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(() => {
    if (location.key === "default") navigate("/");
    return navigate(-1);
  }, [location.key, navigate]);
};
export default useNavigateBack;
