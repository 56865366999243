import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { Mousewheel, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import PlaylistContext from "@/context/playlist";
import { FETCHING_STATUS } from "@/constants/";
import useDeviceInfo from "@/libs/hooks/device-info";
import MobileNavigation from "@/components/MobileNavigation";
import VideoPost from "@/components/VideoPost";
import usePolyfills from "@/libs/hooks/polyfills";
import GestureHint from "./GestureHint";
import useVisible from "@/libs/hooks/visible";

const PREFETCH_DISTANCE = 2;

const MODAL_KEY = "videos_modal";

const ModalContainer = ({ children, onClose }) => {
  const { getMaxHeightRepresentation } = usePolyfills();
  const fullscreen = getMaxHeightRepresentation();
  const [searchParams] = useSearchParams();
  const isOpen = !!searchParams.get(MODAL_KEY);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay bg="rgba(0,0,0,0.6)" />
      <ModalBody>
        <ModalContent height={fullscreen}>{children}</ModalContent>
      </ModalBody>
    </Modal>
  );
};

const Playlist = ({
  modal = false,
  onChange,
  onCreatorClick,
  onChatClick,
  loader = () => {},
  done = false,
}) => {
  const { getMaxHeightRepresentation } = usePolyfills();
  const fullscreen = getMaxHeightRepresentation();

  const {
    index,
    jumpTo,
    creator,
    chat,
    immersive,
    videos,
    resetVideos,
    status,
  } = useContext(PlaylistContext);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [_, setSearchParams] = useSearchParams();
  const visible = useVisible();
  const { height } = useDeviceInfo();
  const [swiper, setSwiper] = useState(null);
  const [active, setActive] = useState(0);

  const Container = useMemo(() => (modal ? ModalContainer : Box), [modal]);

  const urlParamsAction = useCallback(
    (action, key, value) => {
      setSearchParams(
        (params) => {
          if (action === "set") params.set(key, value);
          else if (action === "delete") params.delete(key);
          return params;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  const onVideoChange = useCallback(
    (id) => {
      if (onChange) return onChange(id);
      if (modal) urlParamsAction("set", MODAL_KEY, id);
    },
    [modal, onChange, urlParamsAction],
  );

  const onSlideChange = useCallback(
    ({ activeIndex }) => {
      setActive(activeIndex);
      const activeId = videos[activeIndex]?.id;
      if (activeId) onVideoChange(activeId);
      if (activeIndex >= videos.length - PREFETCH_DISTANCE && !done) loader();
    },
    [loader, onVideoChange, videos, done],
  );

  const onExit = useCallback(() => {
    if (modal) urlParamsAction("delete", MODAL_KEY);
  }, [urlParamsAction, modal]);

  // initial load
  // if videos provided, skip the loading part and just use exist video lists
  useEffect(() => {
    if (swiper && status === FETCHING_STATUS.IDLE && !videos.length) {
      loader().then((firstVideoId) => {
        if (!firstVideoId) return;
        swiper.slideTo(0);
        onVideoChange(firstVideoId);
      });
    }
  }, [loader, navigate, onVideoChange, status, swiper, videos]);

  // if index changed, jump to index & reset
  useEffect(() => {
    async function update() {
      if (index != null) {
        // if is modal, open the modal and wait for 50ms to make sure swiper loaded
        if (modal) {
          urlParamsAction("set", MODAL_KEY, videos[index]?.id);
          await new Promise((resolve) => setTimeout(resolve, 100));
        }
        // if wiper initalized, swiped to the selected index
        if (swiper) {
          swiper.slideTo(index);
          jumpTo(null);
          // some weird scenario when index = 0, the slide change event not being called,
          // so we have to manually set it
          if (index === 0) setActive(0);
        }
      }
    }
    update();
  }, [index, jumpTo, modal, swiper, urlParamsAction, videos]);

  // flush videos when unmount
  useEffect(() => resetVideos(), [resetVideos]);

  return (
    <Container onClose={onExit}>
      <Box id="container__videos">
        <GestureHint />
        <Box
          height={{
            base: immersive
              ? fullscreen
              : `calc(${fullscreen} - ${MobileNavigation.HEIGHT}px)`,
            md: fullscreen,
          }}
          overflow="hidden"
          bg="primary.100"
        >
          <Swiper
            direction="vertical"
            height={height - (immersive ? 0 : MobileNavigation.HEIGHT)}
            modules={[Mousewheel, Virtual]}
            onSwiper={setSwiper}
            onSlideChange={onSlideChange}
            slidesPerView={1}
            touchReleaseOnEdges={true}
            threshold={0}
            resistanceRatio={0.5}
            mousewheel={{
              forceToAxis: true,
              sensitivity: 0,
              thresholdDelta: 30,
              thresholdTime: 70,
            }}
            virtual={{
              addSlidesBefore: 2,
              addSlidesAfter: 2,
            }}
          >
            {videos.map((post, index) => (
              <SwiperSlide key={`${post.id}-${index}`} virtualIndex={index}>
                <VideoPost
                  {...post}
                  active={active === index}
                  onCreatorClick={onCreatorClick}
                  onChatClick={onChatClick}
                  pageFocused={visible && !chat && !creator}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Container>
  );
};

Playlist.MODAL_KEY = MODAL_KEY;

export default Playlist;
