import FAIcon from "@/components/FAIcon";
import { Box, Flex } from "@chakra-ui/react";
import { useWavesurfer } from "@wavesurfer/react";
import { useCallback, useRef } from "react";

const AudioPlayer = ({ src }) => {
  const audioRef = useRef(null);
  const { wavesurfer, isPlaying } = useWavesurfer({
    container: audioRef,
    url: src,
    waveColor: "black",
    height: 20,
  });

  const onPlayPause = useCallback(() => {
    wavesurfer && wavesurfer.playPause();
  }, [wavesurfer]);

  return (
    <Flex gap={2} align="center">
      <FAIcon
        role="button"
        type={isPlaying ? "pause" : "play"}
        onClick={onPlayPause}
      />
      <Box minW={120} height={6}>
        <div ref={audioRef} />
      </Box>
    </Flex>
  );
};

export default AudioPlayer;
