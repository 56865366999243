import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  UnorderedList,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ref, uploadBytes } from "firebase/storage";
import Header from "@/components/Header";
import ImageUploader from "@/components/ImageUploader";
import { useFirebase } from "@/libs/hooks/firebase";
import { useCallback, useContext, useState } from "react";
import useAPI from "@/libs/hooks/api";
import FAIcon from "@/components/FAIcon";
import getPublicUrl from "@/libs/get-public-url";
import AuthContext from "@/context/auth";
import useNavigateBack from "@/libs/hooks/navigate-back";

const PersonalInfoPage = () => {
  const { t } = useTranslation();
  const { storage } = useFirebase();
  const api = useAPI();
  const { user, setUser } = useContext(AuthContext);
  const navigateBack = useNavigateBack();

  const {
    isOpen: uploaderModalOpened,
    onOpen: openUploaderModal,
    onClose: closeUploaderModal,
  } = useDisclosure();

  const toast = useToast();
  const [avatar, setAvatar] = useState(user?.picture);
  const [uploading, setUploading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [displayName, setDisplayName] = useState(user?.displayName || "");

  const updateDisplayName = useCallback(async () => {
    setUpdating(true);
    const response = await api.updateProfile({ displayName });
    setUser((user) => ({ ...user, displayName: response.displayName }));
    setUpdating(false);
    toast({
      title: t("profile.personal_info.update_display_name_success"),
      status: "success",
      duration: 2000,
    });
  }, [api, displayName, setUser, t, toast]);
  const invalidDisplayName = displayName.length > 10;

  const handleFileUpload = useCallback(
    async (blob) => {
      const fileName = `${user.uid}-${Date.now()}`;
      const path = `uploads/users/${user.uid}/${fileName}.png`;
      const userPictureRef = ref(storage, path);
      setUploading(true);
      await uploadBytes(userPictureRef, blob);
      const { picture } = await api.uploadPicture(path);
      setAvatar(picture);
      setUser((user) => ({ ...user, picture }));
      setUploading(false);
      toast({
        title: t("profile.personal_info.upload_photo_success"),
        status: "success",
        duration: 2000,
      });
    },
    [api, setUser, storage, t, toast, user.uid],
  );

  return (
    <Box>
      <Header>
        <FAIcon
          type="chevron-left"
          role="button"
          fontSize="2xl"
          onClick={navigateBack}
        />
        <Text fontSize="2xl" m="auto" pr={4}>
          {t("page.title.personal_info")}
        </Text>
      </Header>
      <Box>
        <VStack>
          <Box
            role="button"
            position="relative"
            onClick={uploading ? null : openUploaderModal}
          >
            <Avatar src={getPublicUrl(avatar)} size="2xl" />
            <Flex
              position="absolute"
              left={0}
              top={0}
              bg="rgba(0,0,0,.3)"
              width="100%"
              height="100%"
              color="white"
            >
              {uploading ? (
                <CircularProgress
                  color="secondary.500"
                  isIndeterminate
                  m="auto"
                  size="1.5rem"
                />
              ) : (
                <FAIcon type="pen" m="auto" />
              )}
            </Flex>
          </Box>
          <FormControl my={3} width={300}>
            <FormLabel color="white">
              {t("profile.form.display_name")}
            </FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <FAIcon type="user-circle" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder={t("profile.form.display_name_placeholder")}
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                bg="white"
              />
            </InputGroup>
            {invalidDisplayName && (
              <Text color="red.500" mt={2} fontWeight="bold">
                {t("profile.form.display_name_warning")}
              </Text>
            )}
          </FormControl>
          <Flex gap={3} minW={300}>
            <Button variant="themed-cancel" flex={1} onClick={navigateBack}>
              {t("common.actions.cancel")}
            </Button>
            <Button
              variant="themed"
              flex={1}
              onClick={updateDisplayName}
              isLoading={updating}
              isDisabled={!displayName || invalidDisplayName}
            >
              {t("common.actions.confirm")}
            </Button>
          </Flex>
        </VStack>
        <Modal
          isOpen={uploaderModalOpened}
          onClose={closeUploaderModal}
          isCentered
        >
          <ModalOverlay bg="rgba(0,0,0,.72)" />
          <ModalBody>
            <ModalContent bg="transparent" color="white">
              <VStack align="center">
                <Text align="center" fontWeight="bold" fontSize="2xl" mb={2}>
                  {t("profile.actions.update_avatar")}
                </Text>
                <UnorderedList fontSize="xs" my={6}>
                  <ListItem>可選擇想要使用的圖片</ListItem>
                  <ListItem>將虛線寬拉至想要的大小之後，即會進行裁切</ListItem>
                  <ListItem>點擊更換頭像後，即會對您的頭貼進行更新</ListItem>
                </UnorderedList>
              </VStack>

              <ImageUploader
                onConfirm={handleFileUpload}
                onCancel={closeUploaderModal}
              />
            </ModalContent>
          </ModalBody>
        </Modal>
      </Box>
    </Box>
  );
};

export default PersonalInfoPage;
