const FETCHING_STATUS = {
  IDLE: 0,
  FETCHING: 1,
  FINISHED: 2,
  ERROR: 3,
};

const PAYMENT_TYPES = {
  CARD: "card",
};

const IS_LOCAL = import.meta.env.VITE_APP_ENV === "local";

const DEFAULT_USER_NAME = "未命名使用者";

const VIP_PLANS = [
  {
    plan: "half-year",
    cost: 5400,
    discount: 1800,
    duration: 6,
    recommended: true,
  },
  {
    plan: "season",
    cost: 2700,
    discount: 330,
    duration: 3,
  },
  { plan: "month", cost: 900, duration: 1 },
];

export {
  FETCHING_STATUS,
  PAYMENT_TYPES,
  IS_LOCAL,
  DEFAULT_USER_NAME,
  VIP_PLANS,
};
