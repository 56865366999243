import { useLayoutEffect, useMemo, useState } from "react";
import Bowser from "bowser";

const MEDIUM_DEVICE_WIDTH = 964;
const browser = Bowser.getParser(window.navigator.userAgent);

const useDeviceInfo = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [isMobile, setIsMobile] = useState(width < MEDIUM_DEVICE_WIDTH);

  const isSafari =
    browser.satisfies({
      macos: {
        safari: ">=0",
      },

      mobile: {
        safari: ">=0",
      },
    }) || false;

  const isIPhone =
    browser.getPlatform().type === "mobile" && browser.getOSName() === "iOS";

  useLayoutEffect(() => {
    function update() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      setIsMobile(window.innerWidth < MEDIUM_DEVICE_WIDTH);
    }
    window.addEventListener("resize", update);

    return () => window.removeEventListener("resize", update);
  }, []);

  return useMemo(
    () => ({ width, height, isMobile, isSafari, isIPhone, browser }),
    [width, height, isMobile, isSafari, isIPhone],
  );
};

export default useDeviceInfo;
