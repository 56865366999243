import useDeviceInfo from "@/libs/hooks/device-info";
import { memo, useCallback, useEffect, useState } from "react";
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import PersistentStorage from "@/libs/persistent-storage";

const USER_TOUR_Z_INDEX = 1710;

const emptyTour = { mobile: [], desktop: [] };

const Tooltip = ({
  continuous,
  index,
  step,
  size,
  backProps,
  closeProps,
  primaryProps,
  skipProps,
  tooltipProps,
  isLastStep,
}) => (
  <Box width={300} bg="white" borderRadius={20} p={6} {...tooltipProps}>
    {step.title && (
      <Text fontWeight="bold" fontSize="lg">
        {step.title}
      </Text>
    )}
    <Box mb={5} fontSize="sm">
      {step.content}
    </Box>
    {step.hideFooter || (
      <Flex align="center">
        <Button
          variant="ghost"
          size="sm"
          px={0}
          justifySelf="flex-start"
          color="#7D7D7D"
          {...skipProps}
        >
          <Trans i18nKey="tours.common.skip" />
        </Button>
        <Flex justify="flex-end" flex={1} gap={2}>
          {index > 0 && !step.hideBackButton && (
            <Button variant="themed-cancel" size="sm" px={2} {...backProps}>
              <Trans i18nKey="tours.common.back" />
            </Button>
          )}
          {continuous && !isLastStep && step.showProgress && (
            <Button size="sm" variant="themed" {...primaryProps}>
              <Trans
                i18nKey="tours.common.next"
                values={{ size, index: index + 1 }}
              />
            </Button>
          )}
          {isLastStep && (
            <Button size="sm" variant="themed" {...closeProps}>
              <Trans
                i18nKey="tours.common.close"
                values={{ size, index: index + 1 }}
              />
            </Button>
          )}
        </Flex>
      </Flex>
    )}
  </Box>
);

const UserTour = memo(({ config, start, onComplete }) => {
  const { isMobile } = useDeviceInfo();
  const tour = config ? config.tour : emptyTour;
  const steps = isMobile ? tour.mobile : tour.desktop;
  const [paused, setPaused] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [blocker, setBlocker] = useState(null);

  const callback = useCallback(
    (data) => {
      const { action, index, status, type, step } = data;
      if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        setPaused(true);
        if (start) {
          PersistentStorage.setItem(config.key, "visited");
          onComplete();
        }
      } else if (type === EVENTS.STEP_AFTER) {
        const nextIndex = index + (action === ACTIONS.PREV ? -1 : 1);
        setStepIndex(nextIndex);
      } else if (action === ACTIONS.UPDATE) {
        if (step.data?.waitFor) {
          setBlocker(step.data.waitFor);
        }
      }
    },
    [config, onComplete, start],
  );

  useEffect(() => {
    if (blocker) {
      const interval = setInterval(() => {
        const appearAsExpected =
          blocker.to === "APPEAR" &&
          document.querySelector(blocker.target) != null;
        const disappearAsExpected =
          blocker.to === "DISAPPEAR" &&
          document.querySelector(blocker.target) == null;
        if (appearAsExpected || disappearAsExpected) {
          setBlocker(null);
          setStepIndex((index) => index + 1);
          clearInterval();
        }
      }, 200);
      return () => clearInterval(interval);
    }
  }, [blocker]);

  return (
    <ReactJoyride
      steps={steps}
      stepIndex={stepIndex}
      run={start && !paused}
      continuous
      showProgress
      showSkipButton
      disableOverlayClose
      disableCloseOnEsc
      tooltipComponent={Tooltip}
      styles={{
        options: {
          zIndex: USER_TOUR_Z_INDEX,
        },
      }}
      callback={callback}
    />
  );
});
UserTour.Z_INDEX = USER_TOUR_Z_INDEX;

export default UserTour;
