import { createContext, useState } from "react";

const StreamContext = createContext({ streams: [] });

export const withStreamContext = (Component) => (props) => {
  const [action, setAction] = useState(null);
  const [immersive, setImmersive] = useState(false);

  return (
    <StreamContext.Provider
      value={{
        action,
        setAction,
        immersive,
        setImmersive,
      }}
    >
      <Component {...props} />
    </StreamContext.Provider>
  );
};

export default StreamContext;
