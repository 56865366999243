import { Flex, Circle, Text, Box } from "@chakra-ui/react";
import Currency from "@/components/Currency";
import Star from "@/assets/images/star.svg?react";

const background = [
  "#2D2D2D80",
  "linear-gradient(90deg, rgba(69, 165, 248, 0.70) 0%, rgba(101, 86, 244, 0.70) 100%)",
  "linear-gradient(90deg, rgba(0, 221, 175, 0.70) 0%, rgba(0, 183, 215, 0.70) 100%)",
  "linear-gradient(90deg, rgba(242, 202, 41, 0.70) 0%, rgba(245, 155, 19, 0.70) 100%)",
  "linear-gradient(90deg, rgba(255, 147, 15, 0.70) 0%, rgba(247, 100, 41, 0.70) 100%)",
  "linear-gradient(90deg, rgba(255, 91, 91, 0.70) 0%, rgba(252, 31, 90, 0.70) 100%)",
];

const calculateLevel = (amount) => {
  if (amount < 250) return 0;
  else if (amount < 1300) return 1;
  else if (amount < 5100) return 2;
  else if (amount < 11800) return 3;
  else if (amount < 16800) return 4;
  else if (amount >= 16800) return 5;
  return 0;
};

const SuperChat = ({ vip, amount, children }) => {
  const level = calculateLevel(amount);
  const bg = vip
    ? "linear-gradient(90deg, #C48B2E 0%, #9C6C2A 100%)"
    : background[level];
  return (
    <Flex
      align="center"
      gap={1}
      bg={bg}
      borderWidth={vip ? 2 : 0}
      borderColor={vip ? "#74531B" : "none"}
      py={vip ? 0.5 : 1}
      px={vip ? 2 : 3}
      rounded="full"
    >
      {vip && (
        <Circle
          bg="#74531B"
          borderWidth={2}
          borderColor="#FBCC71"
          size={6}
          p={1}
        >
          <Star />
        </Circle>
      )}

      <Box color="white" fontSize="sm" _last={{ mr: 1.5 }}>
        {children}
      </Box>
      {!!amount && (
        <Flex align="center" bg="white" rounded="full" px={1.5} color="black">
          <Currency size={3} mr={0.5} mt={0.5} />
          <Text fontSize="xs">x{amount}</Text>
        </Flex>
      )}
    </Flex>
  );
};

export default SuperChat;
