import { useEffect, useState } from "react";

const useVisible = () => {
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    const listener = () => setVisible(document.visibilityState === "visible");
    document.addEventListener("visibilitychange", listener);
    return () => document.removeEventListener("visibilitychange", listener);
  }, []);
  return visible;
};
export default useVisible;
