import {
  Box,
  Divider,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
  VStack,
  Image,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef } from "react";
import telegramLogo from "@/assets/images/social-apps/telegram.png";
import facebookLogo from "@/assets/images/social-apps/facebook.png";

const ShareModal = ({ data, onClose }) => {
  const toast = useToast();
  const lineRef = useRef(null);

  const share = useCallback(
    (provider) => async () => {
      const { url, title } = data || {};
      switch (provider) {
        case "telegram":
          return window.open(
            `https://t.me/share/url?url=${url}&text=${title}`,
            "_blank",
          );
        case "fb":
          return window.FB.ui({
            method: "share",
            href: url,
          });
        default:
      }
    },
    [data],
  );

  const onCopy = useCallback(async () => {
    if (!data) return;
    await navigator.clipboard.writeText(`${data.title} \n${data.url}`);
    toast({ title: "已複製連結到剪貼簿!", status: "info" });
    onClose();
  }, [data, onClose, toast]);

  useEffect(() => {
    setTimeout(() => {
      window.LineIt.loadButton();
    }, 0);
  }, [data]);

  return (
    <Modal isOpen={!!data} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalBody>
        <ModalContent bg="transparent">
          <Box
            bg="#151515"
            color="white"
            rounded={12}
            borderWidth="1px"
            borderColor="secondary.100"
          >
            <Text color="white" align="center" py={2} fontSize="lg">
              分享
            </Text>
            <ModalCloseButton />
            <Divider borderColor="#484848" my={2} />

            <Box py={6} px={12}>
              <Flex mb={6} gap={3} justify="center">
                <VStack>
                  <Box
                    ref={lineRef}
                    className="line-it-button"
                    data-lang="zh_Hant"
                    data-type="share-c"
                    data-env="REAL"
                    data-url={data?.url}
                    data-color="default"
                    data-size="large"
                    data-ver="3"
                  />
                  <Text align="center" fontSize="xs">
                    Line
                  </Text>
                </VStack>
                <VStack>
                  <Image
                    src={telegramLogo}
                    role="button"
                    width={45}
                    onClick={share("telegram")}
                  />
                  <Text align="center" fontSize="xs">
                    Telegram
                  </Text>
                </VStack>
                <VStack>
                  <Image
                    src={facebookLogo}
                    role="button"
                    width={45}
                    onClick={share("fb")}
                  />
                  <Text align="center" fontSize="xs">
                    Facebook
                  </Text>
                </VStack>
              </Flex>
              <Flex gap={3} align="center">
                <Input
                  readOnly
                  value={data?.url}
                  bg="white"
                  color="#A0AEC0"
                  size="sm"
                />
                <Button variant="themed" size="sm" px={6} onClick={onCopy}>
                  複製
                </Button>
              </Flex>
            </Box>
          </Box>
        </ModalContent>
      </ModalBody>
    </Modal>
  );
};

export default ShareModal;
