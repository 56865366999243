import {
  Box,
  Collapse,
  Flex,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { useOutletContext } from "react-router-dom";

import FAIcon from "@/components/FAIcon";
import SubscribePlan from "@/components/SubscribePlan";
import OrderConfirm from "@/components/OrderConfirm";
import * as Membership from "@/components/Membership";
import { VIP_PLANS } from "@/constants";
import TagManager from "react-gtm-module";

const VIPPage = () => {
  const {
    currency,
    exchangeRates,
    payment,
    setPayment,
    confirm,
    onConfirm,
    onCancel,
  } = useOutletContext();
  const { isOpen, onToggle } = useDisclosure();
  const cost = payment?.cost - (payment?.discount || 0);

  const selectPlan = useCallback(
    (plan) => () => {
      setPayment({ ...plan, type: "subscribe" });
      const amount = plan.cost - (plan.discount || 0);
      TagManager.dataLayer({
        dataLayer: {
          event: `InitiateCheckout_${amount}`,
        },
      });
    },
    [setPayment],
  );

  return (
    <Box>
      {!!payment || (
        <VStack color="white" align="stretch" gap={2}>
          <Box mb={2}>
            <Box
              bg="linear-gradient(270deg, #523B19 0%, #665235 75%)"
              p={6}
              color="white"
              rounded="xl"
            >
              <Text fontSize={{ base: "md", md: "xl" }} fontWeight="bold">
                VIP 會員訂閱
              </Text>
              <Text fontSize={{ base: "xs", md: "lg" }}>
                Be the king of the night
              </Text>
              <Flex
                justify="space-between"
                mt={{ base: 4, md: 8 }}
                align="center"
                fontSize={{ base: "xs", md: "md" }}
              >
                <Text>每日贈高額鑽石 | 會員限定折扣｜限定專屬權益</Text>
                <Box role="button" color="#EAC897" onClick={onToggle}>
                  {isOpen ? "收合" : "展開"}{" "}
                  <FAIcon type={isOpen ? "angle-up" : "angle-down"} />
                </Box>
              </Flex>
            </Box>
            <Collapse in={isOpen}>
              <Membership.Premiums
                mt={-4}
                pt={8}
                pb={3}
                bg="#1B1713"
                borderBottomRadius={12}
              />
            </Collapse>
          </Box>

          {VIP_PLANS.map((plan) => (
            <SubscribePlan
              key={plan.name}
              onSelect={selectPlan(plan)}
              rate={currency === "TWD" ? 1 : exchangeRates[currency]}
              {...plan}
            />
          ))}
        </VStack>
      )}

      <OrderConfirm
        variant="subscribe"
        cost={cost}
        isOpen={confirm}
        onClose={onCancel}
        entry={payment?.name}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </Box>
  );
};

export default VIPPage;
