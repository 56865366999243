import {
  Box,
  Image,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import brand from "@/assets/images/brand.png";
import { useContext, useEffect } from "react";
import AuthContext from "@/context/auth";
import PersistentStorage from "@/libs/persistent-storage";

const R18_WARNING_SHOWN_STORAGE_KEY = "R18_WARNING_SHOWN";

const R18Warning = () => {
  const { authLoaded, user } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const lastShownAt = +(
      PersistentStorage.getItem(R18_WARNING_SHOWN_STORAGE_KEY, {
        auth: false,
      }) || 0
    );
    const minutes = 60 * 1000;
    const now = Date.now();
    if (authLoaded && !user && lastShownAt < now - 15 * minutes) {
      onOpen();
      PersistentStorage.setItem(R18_WARNING_SHOWN_STORAGE_KEY, now, {
        auth: false,
      });
    }
  }, [authLoaded, onOpen, user]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="rgba(0,0,0,.8)" />
      <ModalBody>
        <ModalContent bg="transparent">
          <Box
            bg="#151515"
            mx={6}
            maxW={400}
            color="white"
            py={20}
            px={8}
            rounded={16}
            borderWidth="1px"
            borderColor="secondary.500"
          >
            <Image src={brand} width={180} mx="auto" />

            <Text fontWeight="bold" my={10} fontSize="xl">
              螢幕面前的你，是否已滿 18 歲？
            </Text>

            <Text>
              依據台灣網站內容分級規定處理，
              <Text color="#EE3D52" as="span">
                未滿十八歲者不得瀏覽。
              </Text>
              未成年請勿瀏覽與購買限制級商品（成年依據當地國家法律規定之成年年齡為準）。
              根據「兒童及少年福利與權益保障法」規定，本網站非闔家皆宜，予以標示。
              如尚未年滿 18 歲，請點選離開；若已年滿，不可將內容以任何形式給未滿
              18 歲的瀏覽。
            </Text>

            <VStack align="center" pt={16} gap={4}>
              <Button variant="themed-gradient" width={220} onClick={onClose}>
                滿 18 歲 | 進入 NightCo
              </Button>
              <Button
                variant="themed-cancel"
                width={220}
                onClick={() =>
                  (window.location.href = "https://www.google.com")
                }
              >
                未滿 18 歲 | 離開網站
              </Button>
            </VStack>
          </Box>
        </ModalContent>
      </ModalBody>
    </Modal>
  );
};

export default R18Warning;
