import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  ListItem,
  Select,
  Square,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  VStack,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { PAYMENT_TYPES } from "@/constants/";
import FAIcon from "@/components/FAIcon";
import Header from "@/components/Header";
import Currency from "@/components/Currency";
import { TapPayField, TapPayLabel } from "@/components/TapPay";
import AuthContext from "@/context/auth";
import useAPI from "@/libs/hooks/api";

import MasterCard from "@/assets/images/payment_methods/MasterCard.png";
import VISA from "@/assets/images/payment_methods/VISA.png";
import JCB from "@/assets/images/payment_methods/JCB.png";
import creditCardPlaceholder from "@/assets/images/credit-card-placeholder.png";
import useNavigateBack from "@/libs/hooks/navigate-back";
import { Outlet } from "react-router-dom";

const CARD_SCHEMES = {
  MasterCard,
  VISA,
  JCB,
};

const TPDirect = window.TPDirect || null;
const TP_ID = import.meta.env.VITE_TAPPAY_ID || 0;
const TP_KEY = import.meta.env.VITE_TAPPAY_KEY || "";
const TP_ENV = import.meta.env.VITE_TAPPAY_ENV || "sandbox";

const CustomTab = (props) => (
  <Tab
    color="white"
    _selected={{ color: "white", borderColor: "secondary.100" }}
    {...props}
  />
);

const PaymentLayout = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const api = useAPI();
  const toast = useToast();
  const navigateBack = useNavigateBack();
  const [confirm, setConfirm] = useBoolean(false);
  const [savedCards, setSavedCards] = useState(null);
  const [validCard, setValidCard] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [cardId, setCardId] = useState(null);
  const [holder, setHolder] = useState("");
  const [cardName, setCardName] = useState("");
  const [currency, setCurrency] = useState("TWD");
  const [paymentType, setPaymentType] = useState(PAYMENT_TYPES.CARD);
  const [exchangeRates, setExchangeRates] = useState({});
  const [payment, setPayment] = useState(null);

  const cardNumberRef = useRef(null);
  const expDateRef = useRef(null);
  const ccvRef = useRef(null);

  const onCancel = useCallback(() => {
    setPayment(null);
    setCardId(null);
    setCardName(null);
    setSaveCard(false);
    setConfirm.off();
  }, [setConfirm]);

  const onConfirm = useCallback(async () => {
    const paymentInfo = { holder };

    if (payment.type === "deposit") paymentInfo.saveCard = saveCard;

    if (!cardId) {
      const result = await new Promise((resolve) =>
        TPDirect.card.getPrime(resolve),
      );
      const { card } = result;
      paymentInfo.prime = card.prime;
      if (paymentInfo.saveCard) paymentInfo.name = cardName;
    } else {
      paymentInfo.cardId = cardId;
    }

    const { cost = 0, discount = 0 } = payment || {};
    const apiCall = () => {
      switch (payment.type) {
        case "subscribe":
          return api.vip({
            plan: payment.plan,
            ...paymentInfo,
          });
        case "deposit":
        default:
          return api.deposit({
            price: cost - discount,
            ...paymentInfo,
          });
      }
    };

    const { paymentUrl } = await apiCall();
    // redirect to target
    if (paymentUrl) window.location.href = paymentUrl;
    else {
      toast({ status: "error", title: t("profile.payment.failed") });
      onCancel();
    }
  }, [api, cardId, cardName, holder, onCancel, payment, saveCard, t, toast]);

  useEffect(() => {
    api.getSavedCards().then(setSavedCards);
    api.getExchangeRate().then(({ data }) => setExchangeRates(data));
  }, [api]);

  useEffect(() => {
    TPDirect.setupSDK(TP_ID, TP_KEY, TP_ENV);
    const fields = {
      number: {
        element: cardNumberRef.current,
        placeholder: "**** **** **** ****",
      },
      expirationDate: {
        element: expDateRef.current,
        placeholder: "MM / YY",
      },
      ccv: {
        element: ccvRef.current,
        placeholder: "CCV",
      },
    };
    TPDirect.card.setup({
      fields: fields,
    });
    TPDirect.card.onUpdate((update) => {
      if (update.canGetPrime) {
        setValidCard(true);
      } else {
        setValidCard(false);
      }
    });
  }, []);
  return (
    <Box overflowX="hidden">
      <Header>
        <FAIcon
          type="chevron-left"
          role="button"
          hidden={!payment}
          fontSize="2xl"
          onClick={onCancel}
        />
        <Text fontSize="2xl" color="white" m="auto">
          {payment
            ? t("profile.payment.select_card")
            : t("profile.payment.title")}
        </Text>
        <FAIcon
          type="close"
          role="button"
          hidden={!!payment}
          fontSize="2xl"
          onClick={navigateBack}
        />
      </Header>
      <Box bg="#151515" px={4}>
        <VStack color="white" gap={4} align="stretch">
          <Flex justify="space-between">
            <Text>
              {t("profile.payment.currencies_owned", {
                currency: t("common.currency"),
              })}
            </Text>
            <Flex align="center" gap={2}>
              <Text color="secondary.100">{user.points?.balance}</Text>
              <Currency size={5} />
            </Flex>
          </Flex>
          <Flex justify="space-between">
            <Text>{t("profile.payment.select_currency")}</Text>
            <Select
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              variant="unstyled"
              color="secondary.100"
              width={20}
            >
              <option key={currency} value="TWD">
                TWD
              </option>
              {Object.keys(exchangeRates)
                .sort()
                .map((currency) => (
                  <option key={currency} value={currency}>
                    {currency}
                  </option>
                ))}
            </Select>
          </Flex>
          <Flex justify="space-between">
            <Text>{t("profile.payment.select_payment_type")}</Text>
            <Select
              value={paymentType}
              onChange={(e) => setPaymentType(e.target.value)}
              variant="unstyled"
              width={20}
              color="secondary.100"
            >
              {Object.values(PAYMENT_TYPES).map((payment) => (
                <option key={payment} value={payment}>
                  {t(`common.payment.${payment}`)}
                </option>
              ))}
            </Select>
          </Flex>
        </VStack>
        <Flex
          align="center"
          justify="center"
          width="fit-content"
          bg="#484848"
          gap={1}
          mx="auto"
          mt={4}
          py={1}
          px={3}
          borderRadius={16}
        >
          <Text wordBreak="keep-all" fontSize="xs" color="white">
            {t("profile.payment.supported_methods")}
          </Text>
          <Image src={VISA} width={6} />
          <Image src={MasterCard} width={6} />
          <Image src={JCB} width={6} />
        </Flex>
        <Box py={6}>
          <Divider size="xl" />
        </Box>
        <Tabs isFitted display={payment ? "block" : "none"}>
          <TabList>
            <CustomTab>{t("profile.payment.add_card")}</CustomTab>
            <CustomTab>{t("profile.payment.select_card")}</CustomTab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <VStack alignItems="stretch" gap={6} color="#D9D9D9" mt={4}>
                <Flex align="center" gap={2}>
                  <TapPayLabel>{t("profile.payment.card_owner")}</TapPayLabel>
                  <Input
                    value={holder}
                    onChange={(e) => setHolder(e.target.value)}
                    bg="white"
                    height={8}
                    px={2}
                    flex={1}
                    fontSize="0.875rem"
                    fontWeight="bold"
                    borderRadius={2}
                    placeholder="ooooo"
                    color="gray.800"
                  />
                </Flex>
                <Flex align="center" gap={2}>
                  <TapPayLabel>{t("profile.payment.card_number")}</TapPayLabel>
                  <TapPayField ref={cardNumberRef} />
                </Flex>
                <Flex gap={2} align="center">
                  <TapPayLabel>{t("profile.payment.card_exp")}</TapPayLabel>
                  <TapPayField ref={expDateRef} />
                  <TapPayLabel>{t("profile.payment.card_ccv")}</TapPayLabel>
                  <TapPayField ref={ccvRef} />
                </Flex>
                <Checkbox
                  value={saveCard}
                  onChange={(e) => setSaveCard(e.target.checked)}
                >
                  {t("profile.payment.save_card")}
                </Checkbox>
                {saveCard && (
                  <FormControl display="flex" alignItems="center">
                    <FormLabel mb={0}>
                      {t("profile.payment.card_name")}
                    </FormLabel>
                    <Input
                      value={cardName}
                      onChange={(e) => setCardName(e.target.value)}
                      color="#1D1D1D"
                      bg="white"
                      size="sm"
                      flex={1}
                    />
                  </FormControl>
                )}
              </VStack>
            </TabPanel>
            <TabPanel>
              {savedCards?.length === 0 && (
                <VStack align="center" pt={5} color="white">
                  <Square size={36}>
                    <Image src={creditCardPlaceholder} />
                  </Square>
                  <Text mt={3}>{t("profile.payment.no_saved_cards")}</Text>
                </VStack>
              )}

              <VStack gap={3} align="stretch">
                {savedCards?.map(({ id, name, scheme, lastFour, binCode }) => (
                  <Box color="white" key={id}>
                    <Text fontSize="sm" pb={2}>
                      {name}
                    </Text>
                    <Flex
                      role="button"
                      borderColor={cardId === id ? "#F9A000" : "#8E7046"}
                      borderWidth={2}
                      borderRadius={12}
                      justify="space-between"
                      align="center"
                      position="relative"
                      px={7}
                      py={5}
                      onClick={() => setCardId(id)}
                    >
                      <Image src={CARD_SCHEMES[scheme]} width={8} />
                      <Text flex={1} align="center">
                        {binCode?.slice(0, 4) || "****"}-****-****-{lastFour}
                      </Text>
                    </Flex>
                  </Box>
                ))}
              </VStack>
            </TabPanel>
            <VStack px={4} gap={4} align="stretch">
              <Box fontSize="xs" color="white">
                <Text>{t("profile.payment.card_disclaimer")}</Text>
                <UnorderedList>
                  <ListItem>{t("profile.payment.card_disclaimer_1")}</ListItem>
                  <ListItem>
                    <Trans
                      i18nKey="profile.payment.card_disclaimer_2"
                      components={{
                        link1: (
                          <Box
                            as="a"
                            color="red.400"
                            href="https://www.lourdes.org.tw/Default.aspx"
                            rel="noopener noreferrer"
                            target="_blank"
                          />
                        ),
                      }}
                    />
                  </ListItem>
                </UnorderedList>
              </Box>

              <Flex gap={3}>
                <Button
                  onClick={onCancel}
                  colorScheme="whiteAlpha"
                  borderRadius={20}
                  flex={1}
                >
                  {t("common.actions.cancel")}
                </Button>

                <Button
                  onClick={setConfirm.on}
                  variant="themed"
                  isDisabled={
                    !cardId &&
                    (!validCard || !holder || (saveCard && !cardName))
                  }
                  flex={1}
                >
                  {t("common.actions.confirm")}
                </Button>
              </Flex>
            </VStack>
          </TabPanels>
        </Tabs>
        <Outlet
          key={`${payment?.cost}-${currency}-${confirm}`}
          context={{
            currency,
            exchangeRates,
            confirm,
            payment,
            setPayment,
            onConfirm,
            onCancel,
          }}
        />
      </Box>
    </Box>
  );
};

export default PaymentLayout;
