import { Crisp } from "crisp-sdk-web";

import { useMemo, useCallback, useEffect } from "react";

Crisp.configure(import.meta.env.VITE_CRISP_ID || "random_string", {
  autoload: false,
  safeMode: true,
});

const defaultOptions = { load: true, show: false, hideOnClose: true };

const useCrisp = (options) => {
  const settings = useMemo(
    () => ({ ...defaultOptions, ...options }),
    [options],
  );
  const open = useCallback(() => {
    Crisp.chat.show();
    Crisp.chat.open();
  }, []);

  const login = useCallback(async (user) => {
    Crisp.setTokenId(user.uid);
    Crisp.user.setEmail(user.email);
    Crisp.user.setNickname(user.displayName);
    Crisp.session.setData({
      user_id: user.uid,
      plan: "free",
    });
  }, []);

  const logout = useCallback(() => {
    Crisp.setTokenId();
    Crisp.session.reset();
  }, []);

  const send = useCallback((...params) => Crisp.message.send(...params), []);

  // load instance
  useEffect(() => {
    const { load, show, hideOnClose } = settings || {};
    if (!load) return;
    Crisp.load();
    if (!show) {
      Crisp.chat.hide();
    } else {
      Crisp.chat.show();
    }
    if (hideOnClose) {
      Crisp.chat.onChatClosed(() => {
        Crisp.chat.hide();
      });
    }

    return () => {
      Crisp.chat.hide();
      if (hideOnClose) {
        Crisp.chat.offChatClosed();
      }
    };
  }, [settings]);

  return useMemo(
    () => ({ login, logout, open, send }),
    [login, logout, open, send],
  );
};

export default useCrisp;
