import firebase from "./firebase";

const getNormalizedKey = (key, options) => {
  const userId = firebase.auth?.currentUser?.uid;
  if (options.auth && !userId) throw new Error("UNAUTHORIZED");
  return options.auth ? `${userId}:${key}` : key;
};

const getItem = (key, options = { auth: true }) => {
  const normalizedKey = getNormalizedKey(key, options);
  return localStorage.getItem(normalizedKey);
};

const setItem = (key, value, options = { auth: true }) => {
  const normalizedKey = getNormalizedKey(key, options);
  localStorage.setItem(normalizedKey, value);
};

const removeItem = (key, options = { auth: true }) => {
  const normalizedKey = getNormalizedKey(key, options);
  return localStorage.removeItem(normalizedKey);
};

const PersistentStorage = { getItem, setItem, removeItem };

export default PersistentStorage;
