import { withAuthContext } from "./auth";
import { withChatContext } from "./chat";
import { withNotificationContext } from "./notification";
import { withOnlineStatusContext } from "./online-status";
import { withPlaylistContext } from "./playlist";
import { withStreamContext } from "./stream";
import { withUserOverridesContext } from "./user-overrides";

const compose = (...funcs) =>
  funcs.reduce(
    (a, b) =>
      (...args) =>
        a(b(...args)),
  );

const withContext = compose(
  withAuthContext,
  withNotificationContext,
  withOnlineStatusContext,
  withChatContext,
  withStreamContext,
  withPlaylistContext,
  withUserOverridesContext,
);

export default withContext;
