import { useCallback, useMemo } from "react";
import useDeviceInfo from "./device-info";

const usePolyfills = () => {
  const { browser } = useDeviceInfo();

  const getMaxHeightRepresentation = useCallback(() => {
    const dvhSupported = browser.satisfies({
      chrome: ">=108",
      safari: ">=16.4",
      opera: ">=94",
    });
    if (dvhSupported) return "100dvh";
    else return "100vh";
  }, [browser]);

  return useMemo(
    () => ({ getMaxHeightRepresentation }),
    [getMaxHeightRepresentation],
  );
};

export default usePolyfills;
