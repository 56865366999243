import { AbsoluteCenter, Box, Skeleton } from "@chakra-ui/react";
import FAIcon from "../FAIcon";

const Video = (props) => (
  <Box position="relative" aspectRatio={9 / 16} {...props}>
    <Skeleton width="100%" height="100%" />
    <AbsoluteCenter>
      <FAIcon
        type="play"
        fontSize="2xl"
        position="relative"
        m="auto"
        color="gray"
      />
    </AbsoluteCenter>
  </Box>
);
export default Video;
