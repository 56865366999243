import {
  Box,
  Button,
  Circle,
  Collapse,
  Flex,
  Image,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Radio,
  Text,
  Textarea,
  useBoolean,
  useDisclosure,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useContext, useRef, useState } from "react";
import ResizeTextarea from "react-textarea-autosize";
import FAIcon from "../FAIcon";
import trimMessage from "@/libs/trim-message";
import Gifts from "@/components/Chat/Gifts";
import Currency from "@/components/Currency";
import StreamContext from "@/context/stream";
import { Link } from "react-router-dom";
import SuperChat from "./SuperChat";
import AuthContext from "@/context/auth";
import ChatIcon from "@/assets/images/chat.svg?react";
import vipHintHeader from "@/assets/images/vip-hint-header.png";

const MAX_WORD_COUNT = 50;

const DonateInput = ({ balance, value, onChange, ...props }) => (
  <Input
    type="number"
    width={32}
    fontSize="sm"
    rounded="full"
    border="none"
    textAlign="center"
    bg="#262626"
    size="sm"
    color={balance < value ? "red.500" : "#6B6B6B"}
    value={value}
    onChange={onChange}
    placeholder="贈送鑽石數"
    {...props}
  />
);

const InputArea = ({ sendMessage, sendGift }) => {
  const { immersive, setAction, action } = useContext(StreamContext);
  const { user } = useContext(AuthContext);
  const vip = !!user?.isVip;
  const balance = user?.points?.balance || 0;
  const [vipOnly, setVipOnly] = useBoolean(false);
  const [value, setValue] = useState("");
  const [commonDonate, setDonate1] = useState(250);
  const [vipDonate, setVipDonate] = useState("");
  const typing = action === "typing";
  const [giftSelecting, setGiftSelecting] = useBoolean(false);
  const textRef = useRef(null);
  const inputRef = useRef(null);
  const giftsRef = useRef(null);
  const donateAmount = vipOnly ? vipDonate : commonDonate;
  const invalidInput = donateAmount > balance || !value;

  const {
    isOpen: giftsOpen,
    onClose: closeGiftsUI,
    onToggle: toggleGiftsUI,
  } = useDisclosure();

  const {
    isOpen: scOpen,
    onClose: closeSCUI,
    onToggle: toggleSCUI,
  } = useDisclosure();
  const {
    isOpen: vipHintOpen,
    onOpen: openVipHint,
    onClose: closeVipHint,
  } = useDisclosure();

  const closeAll = useCallback(() => {
    setAction(null);
    closeGiftsUI();
    closeSCUI();
  }, [setAction, closeGiftsUI, closeSCUI]);

  useOutsideClick({
    ref: textRef,
    handler: closeAll,
  });

  useOutsideClick({
    ref: giftsRef,
    // if enabled, the order confirm modal will act funny, so this should be disabled after it's done
    enabled: !giftSelecting,
    handler: () => closeGiftsUI(),
  });

  const focusInput = useCallback(() => {
    closeSCUI();
    inputRef.current.focus();
  }, [closeSCUI]);

  const onInput = useCallback((e) => {
    const v = e.target.value;
    if (v.length > MAX_WORD_COUNT) return;
    setValue(v);
  }, []);

  const onGiftSend = useCallback(
    (gift) => {
      sendGift(gift);
      closeGiftsUI();
    },
    [closeGiftsUI, sendGift],
  );

  const onTextSend = useCallback(async () => {
    const text = trimMessage(value);
    if (!text) return;
    const payload = {
      text,
      amount: 0,
    };
    if (scOpen) {
      if (vipOnly) payload.vip = true;
      payload.amount = donateAmount || 0;
    }
    if (payload.amount > balance) return;
    sendMessage(payload);
    setValue("");
    closeAll();
  }, [value, scOpen, balance, sendMessage, closeAll, vipOnly, donateAmount]);

  return (
    <Box
      ref={textRef}
      position="absolute"
      bottom={0}
      hidden={immersive}
      left={0}
      width="100%"
      transition=".2s all"
      zIndex={3}
    >
      <Flex
        bg={
          typing ? "linear-gradient(to right, #D7913F, #A86A31)" : "transparent"
        }
        py={4}
        pl={4}
        pr={typing || giftsOpen ? 4 : 12}
        gap={1}
        align="center"
        color="white"
        fontSize="xl"
      >
        <Box position="relative" flex={1}>
          <Textarea
            ref={inputRef}
            bg={typing ? "white" : "translucent"}
            border="none"
            rounded="full"
            placeholder="說點什麼"
            as={ResizeTextarea}
            value={value}
            onChange={onInput}
            onFocus={() => setAction("typing")}
            rows={1}
            pt={value ? 4 : null}
            maxRows={6}
            resize="none"
            color={typing ? "black" : "white"}
            sx={{
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          />
          {typing && (
            <Flex
              position="absolute"
              pointerEvents="none"
              align="center"
              fontSize={value ? ".75rem" : "sm"}
              top={value ? 1 : 0}
              p={value ? 2 : 0}
              height={value ? 15 : "100%"}
              bg={value ? "rgba(255,255,255,.85)" : "transparent"}
              opacity={value ? 1 : 0.6}
              right={4}
              gap="2px"
              zIndex={1}
              color={value.length >= MAX_WORD_COUNT ? "red.400" : "gray.800"}
              transition="all .2s"
            >
              {value.length}/{MAX_WORD_COUNT} 字
            </Flex>
          )}
        </Box>

        {typing ? (
          <Box role="button" onClick={onTextSend}>
            <FAIcon type="send" opacity={invalidInput ? 0.6 : 1} />
          </Box>
        ) : (
          <>
            <Circle
              role="button"
              bg={giftsOpen ? "#2D2D2D77" : "#CC51D7"}
              size={10}
              onClick={() => {
                toggleGiftsUI();
                setAction(action ? null : "gift");
              }}
              transition=".2s all"
            >
              <FAIcon type="gift" variant="regular" />
            </Circle>
            <Link to="/payment/deposit">
              <Circle role="button" bg="#2D2D2D77" size={10}>
                <Currency size={6} />
              </Circle>
            </Link>
          </>
        )}
      </Flex>
      {typing && (
        <Flex
          height={8}
          width="100%"
          bg="#534130"
          align="center"
          px={2}
          gap={4}
        >
          <ChatIcon
            color={scOpen ? "#FFEEC6" : "#F2BE24"}
            onClick={focusInput}
          />
          <Flex
            borderWidth={2}
            align="center"
            borderColor={scOpen ? "#F2BE24" : "#FFEEC6"}
            rounded="full"
            p={0.5}
            gap={0.5}
            onClick={toggleSCUI}
            role="button"
          >
            <Circle
              bg={
                scOpen
                  ? "linear-gradient(90deg, #F2CA29 0%, #F59B13 100%)"
                  : "#FFEEC6"
              }
              size={3}
            />
            {scOpen ? (
              <Text fontSize="xs" color="#F2BE24" lineHeight="1" pr={0.5}>
                超級留言
              </Text>
            ) : (
              <VStack gap="3px" align="flex-start">
                <Box height="2px" bg="#FFEEC6" width={2} rounded="full" />
                <Box
                  height="2px"
                  ml="2px"
                  bg="#FFEEC6"
                  width={2.5}
                  rounded="full"
                />
                <Box height="2px" bg="#FFEEC6" width={2} rounded="full" />
              </VStack>
            )}
          </Flex>
        </Flex>
      )}

      <Collapse in={giftsOpen} animateOpacity={false} ref={giftsRef}>
        <Gifts
          onSend={onGiftSend}
          onSelect={setGiftSelecting.on}
          onCancel={setGiftSelecting.off}
          bg="#1D1D1D"
        />
      </Collapse>
      <Collapse in={scOpen} animateOpacity={false}>
        <Box bg="#2E2D2B">
          <Flex py={3} px={4} align="center" borderBottom="1px solid #403F3F">
            <SuperChat amount={commonDonate}>留言樣式預覽</SuperChat>
            <Box textAlign="right" flex={1} mr={2}>
              <DonateInput
                min={250}
                balance={balance}
                value={commonDonate}
                onChange={(e) => setDonate1(e.target.value)}
                isDisabled={vipOnly}
              />
            </Box>
            <Radio isChecked={!vipOnly} onClick={setVipOnly.off} />
          </Flex>
          <Flex py={3} px={4} align="center" borderBottom="1px solid #403F3F">
            <SuperChat vip={true} amount={vipDonate}>
              會員限定
            </SuperChat>
            <Circle
              role="button"
              bg="#2E2D2B"
              border="1px solid #818181"
              color="white"
              size={5}
              ml={1}
              onClick={openVipHint}
            >
              <FAIcon type="question" fontSize="xs" />
            </Circle>
            <Box textAlign="right" flex={1} mr={2}>
              <DonateInput
                value={vipDonate}
                balance={balance}
                onChange={(e) => setVipDonate(e.target.value)}
                isDisabled={!vip || !vipOnly}
              />
            </Box>
            <Radio isChecked={vipOnly} onClick={setVipOnly.on} />
          </Flex>

          <Box pt={4} pb={10}>
            <Text align="center" fontSize="sm" color="#818181">
              透過超級留言脫穎而出！
            </Text>
            <Text align="center" fontSize="sm" color="#818181">
              試試輸入不同額度 會有不同顏色喔！
            </Text>
            <Text align="center" fontSize="xs" color="#6B6B6B">
              您贈送的鑽石數和訊息都會公開顯示
            </Text>
          </Box>
        </Box>
      </Collapse>
      <Modal isOpen={vipHintOpen} onClose={closeVipHint} isCentered size="xs">
        <ModalOverlay />
        <ModalContent bg="transparent">
          <Image src={vipHintHeader} roundedTop="2xl" />

          <Box
            p={4}
            bg="#151515"
            textAlign="center"
            color="white"
            roundedBottom="2xl"
          >
            <Text fontSize="xl" fontWeight="bold">
              會員限定留言
            </Text>
            <Text fontSize="sm" color="#ACAAAA" mb={4}>
              讓心儀的創作者看見你
            </Text>
            <Text color="#717171" fontSize="sm">
              成為VIP後只要花費1鑽石，
              <br />
              即可使用專屬星星留言外框，
              <br />
              讓Super Chat更加閃耀，
              <br />
              與創作者甜蜜互動！
            </Text>
            <Text color="#717171" fontSize="xs" my={4}>
              * 會員限定留言外框為限定造型，不會因鑽石數設定而變動顏色。
            </Text>
            <Button variant="themed" px={6} onClick={closeVipHint}>
              知道了
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default InputArea;
