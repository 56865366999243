import { extendTheme } from "@chakra-ui/react";
import Button from "./button";

const theme = extendTheme({
  fonts: {
    heading: `'Noto Sans TC', sans-serif`,
    body: `'Noto Sans TC', sans-serif`,
  },
  components: {
    Button,
  },
  colors: {
    translucent: "#2D2D2D80",
    primary: {
      100: "#0F0F0F",
    },
    secondary: {
      50: "#D09031",
      100: "#FFC737",
      500: "#F6A01E",
    },
  },
});

export default theme;
