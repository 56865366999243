import { Text, Flex, Box, AspectRatio } from "@chakra-ui/react";
import normalMember from "@/assets/images/normal-member.png";

const MemberCard = ({ onClick }) => (
  <AspectRatio
    ratio={2 / 1}
    backgroundImage={normalMember}
    backgroundSize="contain"
    rounded="xl"
  >
    <Box>
      <Text
        color="white"
        fontWeight="bold"
        position="absolute"
        left={{ base: 6, md: 8 }}
        top={{ base: 6, md: 8 }}
        fontSize={{ base: "md", md: "xl" }}
      >
        一般會員
      </Text>
      <Text
        background="linear-gradient(270deg, #323032 0%, #474747 100%)"
        position="absolute"
        right={0}
        top={0}
        px={3}
        py={1}
        borderBottomLeftRadius={10}
        borderTopRightRadius={10}
        fontSize={{ base: "xs", md: "md" }}
        color="#8E8E93"
      >
        未開通
      </Text>
      <Flex
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        px={{ base: 6, md: 8 }}
        py={2}
        bg="linear-gradient(270deg, #302F30 0%, #545354 75%)"
        borderBottomLeftRadius={10}
        borderBottomRightRadius={10}
        align="center"
        fontSize={{ base: "sm", md: "lg" }}
      >
        <Text color="white" flex={1}>
          您尚未開通VIP會員
        </Text>
        <Text
          role="button"
          rounded={{ base: "2xl", md: "3xl" }}
          px={{ base: 6, md: 8 }}
          py={1}
          background="linear-gradient(90deg, #D8C09C 0%, #B59059 100%)"
          onClick={onClick}
        >
          立即開通
        </Text>
      </Flex>
    </Box>
  </AspectRatio>
);

export default MemberCard;
