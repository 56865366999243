import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import i18n from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { ChakraProvider } from "@chakra-ui/react";
import TagManager from "react-gtm-module";

import enLocale from "@/resources/locales/en/translation.json";
import zhTWLocale from "@/resources/locales/zh-TW/translation.json";
import theme from "@/theme";
import App from "./App";
import "@nightco/replay/index.css";
import "./index.css";
import aa from "search-insights";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault("Asia/Taipei");

if (import.meta.env.VITE_ALGOLIA_APP_ID) {
  aa("init", {
    appId: import.meta.env.VITE_ALGOLIA_APP_ID,
    apiKey: import.meta.env.VITE_ALGOLIA_KEY,
  });
}

i18n
  // .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "zh-TW",
    saveMissing: true,
    debug: true,
    whitelist: ["zh-TW", "en"],
    resources: {
      en: {
        translation: enLocale,
      },
      "zh-TW": {
        translation: zhTWLocale,
      },
    },
    detection: {
      order: ["querystring", "localStorage"],
      lookupQuerystring: "locale",
      lookupLocalStorage: "locale",
      caches: ["localStorage", "cookie"],
    },
    react: {
      useSuspense: false,
    },
  });

// Init Sentry in production mode
if (import.meta.env.PROD) {
  TagManager.initialize({ gtmId: "GTM-M54TBP5D" });
  (async function setupTools() {
    const Sentry = await import("@sentry/react");
    Sentry.init({
      dsn: "https://75e5253183e1e79a54b11527a9413b02@o4506848685654016.ingest.us.sentry.io/4506848687816705",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // @todo: add other server API
      tracePropagationTargets: ["localhost"],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      environment: import.meta.env.VITE_APP_ENV,
    });
    if (
      ["dev.nightco.io", "staging.nightco.io"].includes(window.location.host)
    ) {
      const { default: VConsole } = await import("vconsole");
      const vConsole = new VConsole({ theme: "dark" });
      vConsole.setSwitchPosition(10000, 2000);
    }
    // show version info
    console.log(
      `%c [Version]: ${import.meta.env.VITE_APP_VERSION}`,
      "color:#2196f3;font-size:28px;font-weight:bold",
    );
  })();
  import("@sentry/react").then((Sentry) => {});
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider
    theme={theme}
    toastOptions={{ defaultOptions: { position: "top" } }}
  >
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </ChakraProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
