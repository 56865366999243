import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import AuthContext from "./auth";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useFirebase } from "@/libs/hooks/firebase";
import useAPI from "@/libs/hooks/api";
import { useBoolean } from "@chakra-ui/react";

const NotificationContext = createContext({ videos: [] });

export const withNotificationContext = (Component) => (props) => {
  const { firestore } = useFirebase();
  const api = useAPI();
  const { user } = useContext(AuthContext);
  const prev = useRef(null);
  const [notice, setNotice] = useBoolean(false);
  const [notifications, setNotifications] = useState([]);

  const fetchNotifications = useCallback(async () => {
    const response = await api.getNotifications();
    setNotice.off();
    setNotifications(response.data);
  }, [api, setNotice]);

  // listen to notification state
  useEffect(() => {
    if (!user?.uid) return;
    const { pushTargets } = user;
    if (!pushTargets?.length) return;
    const unsubscribes = [];
    // firebase can only batch query ids in size of 30
    const BATCH = 30;
    for (let i = 0; i * BATCH < pushTargets.length; i++) {
      const list = pushTargets.slice(i * BATCH, (i + 1) * BATCH);

      const q = query(
        collection(firestore, "notifications"),
        where("target", "in", list),
        orderBy("updatedAt", "desc"),
        limit(3),
      );

      unsubscribes[i] = onSnapshot(q, async (snapshot) => {
        const latest = snapshot.docs[0].id;
        if (!prev.current) prev.current = latest;
        if (latest === prev.current) return;

        setNotice.on();
        prev.current = latest;
      });
    }

    return () => unsubscribes.forEach((run) => run());
  }, [firestore, setNotice, user]);

  return (
    <NotificationContext.Provider
      value={{
        notice,
        notifications,
        fetchNotifications,
      }}
    >
      <Component {...props} />
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
