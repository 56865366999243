import {
  Box,
  Image,
  ScaleFade,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import gestureExample from "@/assets/images/gesture-example.gif";
import mouseScrollExample from "@/assets/images/mouse-scroll-example.gif";
import usePolyfills from "@/libs/hooks/polyfills";
import PersistentStorage from "@/libs/persistent-storage";
import useDeviceInfo from "@/libs/hooks/device-info";
import { useCallback } from "react";

const GESTURE_VIEWED_STORAGE_KEY = "GESTURE_VIEWED";

const GestureHint = () => {
  const { isMobile } = useDeviceInfo();
  const { getMaxHeightRepresentation } = usePolyfills();
  const fullscreen = getMaxHeightRepresentation();
  const gestureViewed = PersistentStorage.getItem(GESTURE_VIEWED_STORAGE_KEY, {
    auth: false,
  });
  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: gestureViewed !== "true",
  });

  const setGestureViewed = useCallback(() => {
    PersistentStorage.setItem(GESTURE_VIEWED_STORAGE_KEY, true, {
      auth: false,
    });
    onClose();
  }, [onClose]);

  return (
    <ScaleFade in={isOpen} initialScale={0.8} unmountOnExit>
      <Box
        position="absolute"
        left={0}
        top={0}
        width="100%"
        height={fullscreen}
        bg="rgba(0,0,0,0.5)"
        zIndex={10}
        onPointerDown={setGestureViewed}
      >
        <VStack height="100%" justify="center">
          <Image
            src={isMobile ? gestureExample : mouseScrollExample}
            width={120}
            mx="auto"
          />
          <Text color="white" align="center">
            {isMobile ? "上滑看更多" : "捲動看更多"}
          </Text>
        </VStack>
      </Box>
    </ScaleFade>
  );
};

export default GestureHint;
