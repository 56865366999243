import { Flex, SlideFade, Text, VStack } from "@chakra-ui/react";
import SuperChat from "./SuperChat";
import Pill from "../Pill";
import { useEffect, useState } from "react";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useFirebase } from "@/libs/hooks/firebase";

const Chat = ({ id }) => {
  const { firestore } = useFirebase();
  const [items, setItems] = useState([]);
  // listen to stream events snapshots
  useEffect(() => {
    if (!id) return;
    const q = query(
      collection(firestore, "stream-events"),
      where("streamId", "==", id),
      where("type", "in", ["comment", "entry"]),
      orderBy("createdAt", "desc"),
      limit(5),
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setItems(data.reverse());
    });
    return () => unsubscribe();
  }, [firestore, id]);
  return (
    <VStack
      align="start"
      gap={2}
      flex={1}
      maxW={300}
      overflow="auto"
      sx={{
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {items.map(({ id, type, data, user }) => (
        <SlideFade key={id} in={true} offsetX={20} offsetY={20}>
          <Flex gap={3} align="center" color="white" fontSize="sm">
            {type === "comment" && (
              <SuperChat amount={data.value} vip={data.vip}>
                <Text noOfLines={1} wordBreak="break-all">
                  {user?.displayName}：{data.text}
                </Text>
              </SuperChat>
            )}

            {type === "entry" && (
              <Pill bg="translucent">{user?.displayName} 進入了聊天室</Pill>
            )}
          </Flex>
        </SlideFade>
      ))}
    </VStack>
  );
};

export default Chat;
