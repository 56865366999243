import Loading from "@/components/Loading";
import AuthContext from "@/context/auth";
import { useContext } from "react";
import { Navigate } from "react-router-dom";

const withAuthGuard = (Component) => (props) => {
  const { user, authLoaded } = useContext(AuthContext);
  // fallback UI when loading auth
  if (!authLoaded) return <Loading />;
  if (!user) return <Navigate to="/auth/login" />;
  return <Component {...props} user={user} />;
};

export default withAuthGuard;
