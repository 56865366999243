import MobileNavigation from "@/components/MobileNavigation";
import Stream from "@/components/Stream";
import StreamContext from "@/context/stream";
import useAPI from "@/libs/hooks/api";
import useDeviceInfo from "@/libs/hooks/device-info";
import usePolyfills from "@/libs/hooks/polyfills";
import { Box, Image, Square, Text, VStack } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { Mousewheel, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import streamPlaceholder from "@/assets/images/stream-placeholder.png";

const StreamsPage = () => {
  const { height } = useDeviceInfo();
  const api = useAPI();
  const { action, immersive } = useContext(StreamContext);
  const [streams, setStreams] = useState(null);
  const { getMaxHeightRepresentation } = usePolyfills();
  const fullscreen = getMaxHeightRepresentation();

  const hideNavigation = action || immersive;

  const onRefresh = useCallback(
    async (id) => {
      const clone = streams.slice();
      const index = clone.findIndex((s) => s.id === id);
      if (index === -1) return;
      const { stream } = await api.getStream(id);
      clone[index] = stream;
      setStreams(clone);
    },
    [api, streams],
  );

  useEffect(() => {
    api.getStreams().then(setStreams);
  }, [api]);

  return (
    <Box
      height={{
        base: hideNavigation
          ? fullscreen
          : `calc(${fullscreen} - ${MobileNavigation.HEIGHT}px)`,
        md: fullscreen,
      }}
      overflow="hidden"
      bg="primary.100"
    >
      {streams?.length ? (
        <Swiper
          direction="vertical"
          height={height - (hideNavigation ? 0 : MobileNavigation.HEIGHT)}
          modules={[Mousewheel, Virtual]}
          slidesPerView={1}
          touchReleaseOnEdges={true}
          threshold={0}
          allowTouchMove={false}
          resistanceRatio={0.5}
          mousewheel={{
            forceToAxis: true,
            sensitivity: 0,
            thresholdDelta: 30,
            thresholdTime: 70,
          }}
          virtual={{
            addSlidesBefore: 2,
            addSlidesAfter: 2,
          }}
        >
          {streams.map((stream, index) => (
            <SwiperSlide key={`${stream.id}-${index}`} virtualIndex={index}>
              <Stream {...stream} onRefresh={onRefresh} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <VStack align="center" justify="center" h="100%">
          <Square size={36}>
            <Image src={streamPlaceholder} />
          </Square>
          <Text mt={3} color="white" align="center">
            場館整理中 <br />
            請等候開播通知
          </Text>
        </VStack>
      )}
    </Box>
  );
};

export default StreamsPage;
