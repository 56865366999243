export default function getPublicUrl(path) {
  if (!path) return null;
  let normalizedPath = path;
  let timestampParams = "";
  if (path.startsWith("/")) normalizedPath = normalizedPath.slice(1);
  // strip timestramp
  if (normalizedPath.includes("?v=")) {
    const splited = normalizedPath.split("?");
    if (splited.length > 1) timestampParams = `&${splited[1]}`;
    normalizedPath = splited[0];
  }
  const endpoint =
    (import.meta.env.VITE_FIREBASE_EMULATOR_ENABLE === "true"
      ? "http://localhost:9199"
      : "https://firebasestorage.googleapis.com") + "/v0";
  return `${endpoint}/b/${import.meta.env.VITE_FIREBASE_STORAGE_BUCKET}/o/${encodeURIComponent(
    normalizedPath,
  )}?alt=media${timestampParams}`;
}
