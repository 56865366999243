import { Button, Flex, Image, Input, Square, VStack } from "@chakra-ui/react";
import { cropToBlob } from "@/libs/crop-to-blob";
import { useCallback, useMemo, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import { useTranslation } from "react-i18next";
import "react-image-crop/dist/ReactCrop.css";

const ImageUploader = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation();
  const [crop, setCrop] = useState();
  const [file, setFile] = useState(null);
  const imageRef = useRef(null);
  const fileRef = useRef(null);

  const source = useMemo(
    () => (file ? URL.createObjectURL(file) : null),
    [file],
  );

  const cancel = useCallback(() => {
    setFile(null);
    setCrop(null);
    onCancel?.();
  }, [onCancel]);

  const uploadFile = useCallback((e) => {
    setFile(e.target.files[0]);
    // set default crop
    setCrop({
      unit: "px",
      x: 0,
      y: 0,
      width: 120,
      height: 120,
    });
  }, []);

  const onConfirmButtonClick = useCallback(async () => {
    const blob = await cropToBlob(imageRef.current, crop);
    onConfirm(blob);
    cancel();
  }, [crop, onConfirm, cancel]);

  return (
    <Flex>
      <VStack mx="auto" p={10}>
        <Input
          type="file"
          ref={fileRef}
          hidden
          accept="image/*"
          onChange={uploadFile}
        />
        {!file && (
          <Square
            size={200}
            border="2px dashed"
            bg="#151515"
            p={10}
            borderRadius={2}
          />
        )}

        <ReactCrop
          crop={crop}
          onChange={setCrop}
          aspect={1}
          minWidth={120}
          minHeight={120}
        >
          <Image
            src={source}
            crossOrigin="anonymous"
            alt=""
            mx="auto"
            ref={imageRef}
          />
        </ReactCrop>
        <VStack gap={4} width={200} align="stretch" mt={4}>
          <Button
            onClick={() => fileRef.current.click()}
            bg="#F6A01E88"
            _active={{
              bg: "#F6A01E88",
            }}
            _focus={{
              bg: "#F6A01E88",
            }}
            color="white"
            borderRadius={20}
          >
            上傳圖片
          </Button>
          <Button
            onClick={onConfirmButtonClick}
            variant="themed"
            hidden={!file}
          >
            更換頭貼
          </Button>
          <Button
            onClick={cancel}
            variant="themed-cancel"
            borderRadius={20}
            hidden={!file}
          >
            {t("common.actions.cancel")}
          </Button>
        </VStack>
      </VStack>
    </Flex>
  );
};

export default ImageUploader;
