import Currency from "@/components/Currency";
import FAIcon from "@/components/FAIcon";
import Header from "@/components/Header";
import OrderConfirm from "@/components/OrderConfirm";
import VideoOverlay from "@/components/VideoPost/VideoOverlay";
import VideoPlayer from "@/components/VideoPost/VideoPlayer";
import getPublicUrl from "@/libs/get-public-url";
import useAPI from "@/libs/hooks/api";
import usePolyfills from "@/libs/hooks/polyfills";
import {
  AbsoluteCenter,
  Box,
  Button,
  Circle,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const SimpleVideoPlayer = ({ id, active, onClose }) => {
  const api = useAPI();
  const [source, setSource] = useState(null);
  const { getMaxHeightRepresentation } = usePolyfills();
  const maxH = getMaxHeightRepresentation();
  const [paused, setPaused] = useState(!active);
  const [controls, setControls] = useState({
    setProperties: () => null,
  });

  const togglePause = useCallback(() => {
    setPaused(!paused);
    controls.setProperties({ isPaused: !paused });
  }, [paused, controls]);

  useEffect(() => {
    if (id) {
      api.getMessagePlayback(id).then(({ playback }) => setSource(playback));
    }
  }, [api, id]);

  return (
    <Box height={maxH} position="relative">
      <VideoPlayer
        src={source}
        active={true}
        paused={paused}
        setControls={setControls}
      />
      <VideoOverlay
        active={active && source}
        paused={paused}
        onClick={togglePause}
      />
      <Header position="absolute" justify="flex-end">
        <FAIcon role="button" type="close" fontSize="2xl" onClick={onClose} />
      </Header>
    </Box>
  );
};

const Media = ({
  id,
  type,
  image,
  thumbnail,
  text,
  buffered,
  isUnlocked,
  isFromUser,
  price,
  onUnlock,
}) => {
  const { t } = useTranslation();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [imageLoaded, setImageLoaded] = useState(false);
  const {
    onOpen: onConfirmOrderOpen,
    onClose: onConfirmOrderClose,
    isOpen: isConfirmOrderOpen,
  } = useDisclosure();

  const unlocked = isUnlocked || isFromUser;

  const imageSource = type === "image" ? image : thumbnail;
  // @todo: imageSource?.blur is for compatibility of legacy schema
  const preview = buffered
    ? imageSource
    : getPublicUrl(imageSource?.blur ?? imageSource ?? "");

  const handleUnlock = useCallback(async () => {
    await onUnlock();
    onConfirmOrderClose();
  }, [onConfirmOrderClose, onUnlock]);

  return (
    <Box role="button" onClick={onOpen} position="relative">
      <Image
        src={preview}
        loading="lazy"
        borderTopLeftRadius={text ? 20 : 0}
        borderTopRightRadius={text ? 20 : 0}
        onLoad={() => setImageLoaded(true)}
      />
      <Box position="absolute" right={3} bottom={2} color="white">
        <FAIcon type={type === "video" ? "film" : "image"} fontSize="xl" />
      </Box>

      {!unlocked && imageLoaded && (
        <AbsoluteCenter>
          <FAIcon variant="regular" type="lock" color="white" fontSize="3xl" />
        </AbsoluteCenter>
      )}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="rgba(0,0,0,.72)" />
        <ModalBody>
          <ModalContent bg="transparent">
            <Box position="relative">
              {(type === "image" || !isUnlocked) && (
                <Box position="relative">
                  <Image src={preview} width="100%" />
                  <Circle
                    position="absolute"
                    right={3}
                    top={3}
                    size={6}
                    bg="#7B7B7B"
                    color="#ECECEC"
                  >
                    <FAIcon role="button" type="close" onClick={onClose} />
                  </Circle>
                </Box>
              )}
              {type === "video" && isUnlocked && (
                <SimpleVideoPlayer id={id} active={isOpen} onClose={onClose} />
              )}

              {unlocked || (
                <Box position="absolute" bottom={3} left={0} width="100%">
                  <Button
                    width={300}
                    mx="auto"
                    variant="themed-gradient"
                    onClick={onConfirmOrderOpen}
                    display="flex"
                    align="center"
                    gap={4}
                    id="button__unlock-message-attachement"
                  >
                    <Currency size={6} />
                    {t("chats.messages.actions.unlock_image_with", {
                      price,
                      currency: t("common.currency"),
                    })}
                    <Currency size={6} />
                  </Button>
                  <OrderConfirm
                    isOpen={isConfirmOrderOpen}
                    onClose={onConfirmOrderClose}
                    onCancel={onConfirmOrderClose}
                    onConfirm={handleUnlock}
                    entry={t("chats.messages.actions.unlock_image")}
                    amount={-price}
                  />
                </Box>
              )}
            </Box>
          </ModalContent>
        </ModalBody>
      </Modal>
    </Box>
  );
};

export default Media;
