import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Square,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import FAIcon from "../FAIcon";
import { useCallback, useContext, useState } from "react";
import AuthContext from "@/context/auth";
import ChatContext from "@/context/chat";
import OrderConfirm from "../OrderConfirm";
import { Trans, useTranslation } from "react-i18next";
import getPublicUrl from "@/libs/get-public-url";
import Currency from "../Currency";
import { useNavigate } from "react-router-dom";

const Gifts = ({
  onSend,
  onSelect = () => {},
  onCancel = () => {},
  ...props
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { gifts } = useContext(ChatContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState(null);

  const selectGift = useCallback(
    (gift) => () => {
      setSelected(gift);
      onSelect(gift);
      onOpen();
    },
    [onOpen, onSelect],
  );

  const cancelSendingGift = useCallback(() => {
    onClose();
    onCancel();
  }, [onCancel, onClose]);

  const sendGift = useCallback(() => {
    if (!selected) return;
    onSend(selected.id);
    cancelSendingGift();
  }, [cancelSendingGift, onSend, selected]);

  return (
    <Box {...props}>
      <Flex
        borderBottomWidth={3}
        color="white"
        borderColor="#484848"
        align="center"
        py={4}
        px={6}
        justify="space-between"
        fontSize="lg"
      >
        <Text fontSize="lg">
          <FAIcon as="i" variant="regular" type="gift" pr={2} />
          {t("chats.gifts.title")}
        </Text>
        <Tag
          bg="#484848"
          color="#F6A01E"
          borderRadius="full"
          size="lg"
          onClick={() => navigate("/payment/deposit")}
        >
          <Flex gap={1}>
            <Text>{user.points?.balance}</Text>
            <Currency size={5} />
          </Flex>
        </Tag>
      </Flex>
      <Box p={4}>
        <Tabs isFitted variant="unstyled">
          <TabList
            display="flex"
            gap={4}
            color="white"
            overflow="auto"
            sx={{
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {Object.keys(gifts).map((key) => (
              <Tab
                key={key}
                whiteSpace="nowrap"
                _selected={{
                  color: "white",
                  borderBottomWidth: 2,
                  borderColor: "secondary.100",
                }}
              >
                {t(`gifts.groups.${key}`)}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {Object.entries(gifts).map(([key, values]) => (
              <TabPanel key={key} px={1}>
                <SimpleGrid columns={4} gap={2} pt={4}>
                  {values.map((gift) => (
                    <Box key={gift.id} role="button" onClick={selectGift(gift)}>
                      <Square size={76} mx="auto">
                        <Image
                          src={getPublicUrl(`/assets/gifts/${gift.id}.png`)}
                          loading="lazy"
                        />
                      </Square>
                      <Box my={2}>
                        <Text color="white" align="center" fontSize="sm">
                          {t(`gifts.${gift.id}`)}
                        </Text>

                        <Text color="#F6A01E" align="center" fontSize="xs">
                          {gift.price}
                        </Text>
                      </Box>
                    </Box>
                  ))}
                </SimpleGrid>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
      <OrderConfirm
        isOpen={isOpen}
        onClose={cancelSendingGift}
        onCancel={cancelSendingGift}
        entry={
          <Trans
            i18nKey="chats.gifts.receipt_title"
            defaults="Send <highlight>@{{name}}</highlight>"
            values={{ name: selected ? t(`gifts.${selected.id}`) : null }}
            components={{ highlight: <Box as="span" color="red" /> }}
          />
        }
        amount={-selected?.price}
        onConfirm={sendGift}
      />
    </Box>
  );
};

export default Gifts;
