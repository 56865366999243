import {
  Box,
  Button,
  Circle,
  Flex,
  SimpleGrid,
  Square,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import toDisplayNumber from "@/libs/to-display-number";
import AuthContext from "@/context/auth";
import Header from "@/components/Header";
import AvatarWithModal from "@/components/AvatarWithModal";
import FAIcon from "@/components/FAIcon";
import ConfigurationMenu from "@/components/ConfigurationMenu";
import useCrisp from "@/libs/hooks/crisp";
import useAPI from "@/libs/hooks/api";
import SystemInfo from "@/components/SystemInfo";

import Star from "@/assets/images/star.svg?react";
import GrayscaleStar from "@/assets/images/star-grayscale.svg?react";

const MENUS = {
  PERSONAL_SETTINGS: "personal_settings",
  WALLET: "wallet",
  MISC_SETTINGS: "misc_settings",
};

const FunctionButton = ({ icon, onClick, children }) => (
  <VStack role="button" onClick={onClick}>
    <Circle size={16} bg="#646464">
      {typeof icon === "string" ? (
        <FAIcon type={icon} fontSize="3xl" />
      ) : (
        <Square size={8}>{icon}</Square>
      )}
    </Circle>
    <Text fontSize="xs" mt={1}>
      {children}
    </Text>
  </VStack>
);

const UserProfileLayout = () => {
  const { t } = useTranslation();
  const api = useAPI();
  const { logout, user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const crisp = useCrisp();

  const vip = !!user?.isVip;

  const { followedCreatorsTotal, unlockedVideosTotal, savedVideosTotal } =
    user || {};

  const {
    isOpen: drawerOpened,
    onOpen: openDrawer,
    onClose: closeDrawer,
  } = useDisclosure();

  const {
    isOpen: systemInfoOpen,
    onOpen: openSystemInfo,
    onClose: closeSystemInfo,
  } = useDisclosure();

  const [currentMenu, setCurrentMenu] = useState(MENUS.PERSONAL_SETTINGS);

  const to = useCallback((url) => () => navigate(url), [navigate]);

  const openChat = useCallback(() => {
    crisp.open();
    closeDrawer();
  }, [closeDrawer, crisp]);

  const openMenu = useCallback(
    (menuType) => () => {
      openDrawer();
      setCurrentMenu(menuType);
    },
    [openDrawer],
  );

  // update profile each time user comes in
  useEffect(() => {
    api
      .getProfile()
      .then((updates) => setUser((state) => ({ ...state, ...updates })));
  }, [api, setUser]);

  return (
    <Box>
      <Header px={6} quickDeposit>
        <Text color="white">{user.displayName}</Text>
      </Header>
      <Flex
        py={5}
        px={8}
        align="center"
        textAlign="center"
        justify="space-between"
        gap={4}
        color="white"
      >
        <AvatarWithModal source={user?.picture} vip={vip} />
        <VStack flex={1} align="stretch">
          <Flex justify="space-between" border={2} mb={1}>
            <Link to="/profile/followed-creators">
              <Text fontSize="2xl">
                {toDisplayNumber(followedCreatorsTotal)}
              </Text>
              <Text>{t("profile.tabs.total_follows")}</Text>
            </Link>
            <Link to="/profile/unlocked-videos">
              <Text fontSize="2xl">{toDisplayNumber(unlockedVideosTotal)}</Text>
              <Text>{t("profile.tabs.total_unlocks")}</Text>
            </Link>
            <Link to="/profile/saved-videos">
              <Text fontSize="2xl">{toDisplayNumber(savedVideosTotal)}</Text>
              <Text>{t("profile.tabs.total_saved")}</Text>
            </Link>
          </Flex>

          <Link to="/profile/membership">
            <Flex
              background={
                vip
                  ? "linear-gradient(270deg, rgba(126, 82, 20, 0.90) 0%, rgba(84, 57, 19, 0.90) 100%)"
                  : "linear-gradient(270deg, rgba(126, 82, 20, 0.90) 0%, rgba(76, 57, 32, 0.90) 25%, rgba(40, 40, 40, 0.90) 55%)"
              }
              rounded="xl"
              p={2}
              align="center"
              gap={2}
              fontSize={{ base: "xs", md: 16 }}
            >
              <Square size={{ base: 4, md: 6 }}>
                <Star />
              </Square>
              <Text color="#E6AD4C" fontWeight="semibold" flex={1} align="left">
                {vip ? "VIP 會員" : "加入會員"}
              </Text>
              <Box color="#FFBC39">
                查看會員權益
                <FAIcon type="angle-right" ml={{ base: 1, md: 2 }} />
              </Box>
            </Flex>
          </Link>
        </VStack>
      </Flex>
      <Flex color="white" gap={3} px={4}>
        <Button
          variant="themed-outline"
          flex={1}
          onClick={openMenu(MENUS.PERSONAL_SETTINGS)}
        >
          {t("profile.menu.personal_settings")}
        </Button>
        <Button
          variant="themed-outline"
          flex={1}
          onClick={openMenu(MENUS.WALLET)}
        >
          {t("profile.menu.wallet")}
        </Button>
        <Button
          variant="themed-outline"
          flex={1}
          onClick={openMenu(MENUS.MISC_SETTINGS)}
        >
          {t("profile.menu.misc_settings")}
        </Button>
      </Flex>

      <Outlet />
      <ConfigurationMenu
        isOpen={drawerOpened}
        onClose={closeDrawer}
        title={t(`profile.menu.${currentMenu}`)}
      >
        <SimpleGrid columns={{ base: 4, md: 3 }} rowGap={4} align="left">
          {currentMenu === MENUS.PERSONAL_SETTINGS && (
            <>
              <FunctionButton
                icon="id-card"
                onClick={to("/profile/personal-info")}
              >
                {t("profile.actions.edit_personal_info")}
              </FunctionButton>
              <FunctionButton
                icon="square-user"
                onClick={to("/profile/manage-account")}
              >
                {t("profile.actions.manage_account")}
              </FunctionButton>
              <FunctionButton
                icon="key"
                onClick={to("/profile/change-password")}
              >
                {t("profile.actions.change_password")}
              </FunctionButton>
            </>
          )}
          {currentMenu === MENUS.WALLET && (
            <>
              <FunctionButton onClick={to("/payment/deposit")} icon="gem">
                {t("common.currency")}
              </FunctionButton>
              <FunctionButton
                onClick={to("/profile/membership")}
                icon={<GrayscaleStar />}
              >
                {t("profile.actions.membership")}
              </FunctionButton>
              <FunctionButton
                onClick={to("/profile/transaction-history")}
                icon="money-check-dollar"
              >
                {t("profile.actions.transaction_history")}
              </FunctionButton>
            </>
          )}
          {currentMenu === MENUS.MISC_SETTINGS && (
            <>
              <FunctionButton icon="headset" onClick={openChat}>
                {t("profile.actions.customer_service")}
              </FunctionButton>
              <FunctionButton icon="info" onClick={openSystemInfo}>
                {t("profile.actions.system_info")}
              </FunctionButton>
              {/* @todo: add this back after implementation done
              <FunctionButton icon="gem">
                {t("profile.actions.apply_creator")}
              </FunctionButton>
              */}
              <FunctionButton onClick={logout} icon="arrow-right-from-bracket">
                {t("auth.actions.logout")}
              </FunctionButton>
            </>
          )}
        </SimpleGrid>
      </ConfigurationMenu>
      <SystemInfo isOpen={systemInfoOpen} onClose={closeSystemInfo} />
    </Box>
  );
};

export default UserProfileLayout;
