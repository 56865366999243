// tranfrom message from firebase database document to local format
export const transformMessage = (doc) => {
  let result = doc;
  if (typeof doc.data == "function") {
    result = {
      id: doc.id,
      ...doc.data(),
    };
  }
  if (result.type === "image") {
    result.image = result.isUnlocked ? result.image?.clear : result.image?.blur;
  }

  if (result.type === "video") {
    result.thumbnail = result.isUnlocked
      ? result.thumbnail?.clear
      : result.thumbnail?.blur;
    result.video = result.isUnlocked ? result.video?.clear : result.video?.blur;
  }

  return result;
};

// tranfrom chat from firebase database document to local format
export const transformChat = (doc) => {
  let result = doc;
  if (typeof doc.data == "function") {
    result = {
      id: doc.id,
      ...doc.data(),
    };
  }

  result = {
    ...result,
    unreadTotal: result.userUnreadTotal,
    lastReadMessageId: result.userLastReadMessageId,
  };

  const lastMessageType = result?.lastMessage?.type;
  if (["image", "videos"].includes(lastMessageType)) {
    result.lastMessage = transformMessage(result.lastMessage);
  }
  return result;
};
