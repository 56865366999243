import { Trans } from "react-i18next";

const key = "VIDEOS_TOUR_VISITED";

const tour = {
  mobile: [
    {
      target: "body",
      placement: "center",
      title: <Trans i18nKey="tours.videos.mobile.greeting.title" />,
      content: <Trans i18nKey="tours.videos.mobile.greeting.content" />,
      spotlightClicks: true,
    },
    {
      target: "#button__video_unlock",
      title: <Trans i18nKey="tours.videos.mobile.unlock_videos.title" />,
      content: <Trans i18nKey="tours.videos.mobile.unlock_videos.content" />,
      hideFooter: true,
      spotlightClicks: true,
      disableBeacon: true,
      disableOverlayClose: true,
      data: {
        waitFor: {
          target: "#button__order_modal_confirm",
          to: "APPEAR",
        },
      },
    },
    {
      target: "#button__order_modal_confirm",
      title: <Trans i18nKey="tours.videos.mobile.confirm_unlock.title" />,
      content: <Trans i18nKey="tours.videos.mobile.confirm_unlock.content" />,
      hideFooter: true,
      spotlightClicks: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      data: {
        waitFor: {
          target: "#button__order_modal_confirm",
          to: "DISAPPEAR",
        },
      },
    },
    {
      target: "#button__video_check-creator",
      title: <Trans i18nKey="tours.videos.mobile.check_creator.title" />,
      content: <Trans i18nKey="tours.videos.mobile.check_creator.content" />,
      hideBackButton: true,
    },
    {
      target: "#button__video_immersive",
      placement: "left",
      title: <Trans i18nKey="tours.videos.mobile.immersive.title" />,
      content: <Trans i18nKey="tours.videos.mobile.immersive.content" />,
    },
    {
      target: "#button__video_share",
      title: <Trans i18nKey="tours.videos.mobile.share.title" />,
      content: <Trans i18nKey="tours.videos.mobile.share.content" />,
      placement: "left",
    },
    {
      target: "#button__video_like",
      title: <Trans i18nKey="tours.videos.mobile.like.title" />,
      content: <Trans i18nKey="tours.videos.mobile.like.content" />,
      placement: "left",
    },
    {
      target: "#button__video_save-video",
      title: <Trans i18nKey="tours.videos.mobile.save.title" />,
      content: <Trans i18nKey="tours.videos.mobile.save.content" />,
      placement: "left",
    },
    {
      target: "#button__video_go-to-chat",
      title: <Trans i18nKey="tours.videos.mobile.chat.title" />,
      content: <Trans i18nKey="tours.videos.mobile.chat.content" />,
      placement: "left",
    },
    {
      target: "#button__video_volume",
      title: <Trans i18nKey="tours.videos.mobile.volume.title" />,
      content: <Trans i18nKey="tours.videos.mobile.volume.content" />,
      placement: "left",
    },
    {
      target: "body",
      title: <Trans i18nKey="tours.videos.mobile.finish.title" />,
      content: <Trans i18nKey="tours.videos.mobile.finish.content" />,
      placement: "center",
    },
  ],
  desktop: [
    {
      target: "body",
      placement: "center",
      title: <Trans i18nKey="tours.videos.desktop.greeting.title" />,
      content: <Trans i18nKey="tours.videos.desktop.greeting.content" />,
      spotlightClicks: true,
    },
    {
      target: "#button__video_unlock",
      title: <Trans i18nKey="tours.videos.desktop.unlock_videos.title" />,
      content: <Trans i18nKey="tours.videos.desktop.unlock_videos.content" />,
      hideFooter: true,
      spotlightClicks: true,
      disableBeacon: true,
      disableOverlayClose: true,
      data: {
        waitFor: {
          target: "#button__order_modal_confirm",
          to: "APPEAR",
        },
      },
    },
    {
      target: "#button__order_modal_confirm",
      title: <Trans i18nKey="tours.videos.desktop.confirm_unlock.title" />,
      content: <Trans i18nKey="tours.videos.desktop.confirm_unlock.content" />,
      hideFooter: true,
      spotlightClicks: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      data: {
        waitFor: {
          target: "#button__order_modal_confirm",
          to: "DISAPPEAR",
        },
      },
    },
    {
      target: "#button__video_check-creator",
      title: <Trans i18nKey="tours.videos.desktop.check_creator.title" />,
      content: <Trans i18nKey="tours.videos.desktop.check_creator.content" />,
      hideBackButton: true,
    },
    {
      target: "#button__video_immersive",
      placement: "left",
      title: <Trans i18nKey="tours.videos.desktop.immersive.title" />,
      content: <Trans i18nKey="tours.videos.desktop.immersive.content" />,
    },
    {
      target: "#button__video_share",
      title: <Trans i18nKey="tours.videos.desktop.share.title" />,
      content: <Trans i18nKey="tours.videos.desktop.share.content" />,
      placement: "left",
    },
    {
      target: "#button__video_like",
      title: <Trans i18nKey="tours.videos.desktop.like.title" />,
      content: <Trans i18nKey="tours.videos.desktop.like.content" />,
      placement: "left",
    },
    {
      target: "#button__video_save-video",
      title: <Trans i18nKey="tours.videos.desktop.save.title" />,
      content: <Trans i18nKey="tours.videos.desktop.save.content" />,
      placement: "left",
    },
    {
      target: "#button__video_go-to-chat",
      title: <Trans i18nKey="tours.videos.desktop.chat.title" />,
      content: <Trans i18nKey="tours.videos.desktop.chat.content" />,
      placement: "left",
    },
    {
      target: "#button__video_volume",
      title: <Trans i18nKey="tours.videos.desktop.volume.title" />,
      content: <Trans i18nKey="tours.videos.desktop.volume.content" />,
      placement: "left",
    },
    {
      target: "body",
      title: <Trans i18nKey="tours.videos.desktop.finish.title" />,
      content: <Trans i18nKey="tours.videos.desktop.finish.content" />,
      placement: "center",
    },
  ],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { key, tour };
