import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import AuthContext from "@/context/auth";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Currency from "./Currency";
import { useNavigate } from "react-router-dom";

const UpdateBalance = ({
  t,
  balance,
  entry,
  amount,
  sufficient,
  remaining,
}) => (
  <>
    <VStack pt={4} pb={8} align="stretch">
      <Flex justify="space-between" align="center">
        <Text flex={1}>{t("common.order.user_currencies")}</Text>
        <Flex gap={2} align="center">
          <Text>{balance}</Text>
          <Currency size={5} />
        </Flex>
      </Flex>
      <Flex justify="space-between" align="center">
        <Text flex={1}>{entry}</Text>
        <Flex gap={2} align="center">
          <Text>{amount > 0 ? `+${amount}` : amount}</Text>
          <Currency size={5} />
        </Flex>
      </Flex>
    </VStack>
    <Divider />
    <Flex justify="space-between" align="center" mt={4} mb={8}>
      <Text flex={1} color={sufficient ? "white" : "red.500"}>
        {sufficient
          ? t("common.order.remaining_currencies")
          : t("common.order.insufficient")}
      </Text>
      <Flex gap={2} align="center">
        <Text>{Math.abs(remaining)}</Text>
        <Currency size={5} />
      </Flex>
    </Flex>
  </>
);

const Subscribe = ({ entry, cost }) => (
  <>
    <Flex pt={4} pb={8} justify="space-between" align="center">
      <Text flex={1}>購買會員方案</Text>
      <Text>{entry}</Text>
    </Flex>
    <Divider />
    <Flex justify="space-between" align="center" mt={4} mb={8}>
      <Text flex={1} coolor="white">
        本次扣款
      </Text>
      <Flex gap={2} align="center">
        {cost} 元
      </Flex>
    </Flex>
  </>
);

const OrderConfirm = ({
  variant = "update_balance",
  isOpen,
  onClose,
  entry,
  cost,
  amount,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const balance = user?.points?.balance || 0;

  const remaining = balance + amount;
  const sufficient = remaining >= 0;

  const onConfirmClick = useCallback(async () => {
    if (loading) return;
    if (sufficient || variant === "subscribe") {
      setLoading(true);
      await onConfirm();
      setLoading(false);
    } else {
      navigate("/payment/deposit");
    }
  }, [loading, navigate, onConfirm, sufficient, variant]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalBody>
        <ModalContent bg="transparent" fontSize="sm">
          <Box
            bg="#151515"
            mx={8}
            maxW={400}
            color="white"
            p={6}
            rounded={16}
            borderWidth="1px"
            borderColor="secondary.100"
          >
            <Text
              align="center"
              fontSize="xl"
              borderBottom="2px"
              borderColor="secondary.100"
              pb={4}
            >
              {t("common.order.title")}
            </Text>
            {variant === "update_balance" && (
              <UpdateBalance
                t={t}
                balance={balance}
                entry={entry}
                amount={amount}
                sufficient={sufficient}
                remaining={remaining}
              />
            )}

            {variant === "subscribe" && <Subscribe entry={entry} cost={cost} />}

            <Flex gap={6} mt={4}>
              <Button
                onClick={onCancel}
                flex={1}
                colorScheme="whiteAlpha"
                borderRadius={20}
                id="button__order_modal_cancel"
              >
                {t("common.actions.cancel")}
              </Button>
              <Button
                onClick={onConfirmClick}
                isLoading={loading}
                isDisabled={loading}
                variant="themed"
                flex={1}
                id="button__order_modal_confirm"
              >
                {sufficient || variant === "subscribe"
                  ? t("common.actions.confirm")
                  : t("common.actions.deposit")}
              </Button>
            </Flex>
          </Box>
        </ModalContent>
      </ModalBody>
    </Modal>
  );
};

export default OrderConfirm;
