import { Box } from "@chakra-ui/react";

const Bubble = ({ children, isFromUser, ...props }) => (
  <Box
    bg={isFromUser ? "#FFC46C" : "white"}
    px={4}
    py={2}
    borderRadius={20}
    whiteSpace="pre-wrap"
    wordBreak="break-word"
    {...props}
  >
    {children}
  </Box>
);

export default Bubble;
