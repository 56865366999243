import { Box, Flex } from "@chakra-ui/react";
import MobileNavigation from "@/components/MobileNavigation";
import Sidebar from "@/components/Sidebar";
import usePolyfills from "@/libs/hooks/polyfills";
import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import PlaylistContext from "@/context/playlist";
import DailyReward from "@/components/DailyReward";
import R18Warning from "@/components/R18Warning";
import Loading from "@/components/Loading";
import AuthContext from "@/context/auth";
import IncomingMessage from "@/components/IncomingMessage";
import { DEFAULT_USER_NAME } from "@/constants";
import RequestNotificationPermission from "@/components/RequestNotificationPermission";

const SemiProtectedLayout = () => {
  const { authLoaded, user } = useContext(AuthContext);
  const { getMaxHeightRepresentation } = usePolyfills();
  const { immersive } = useContext(PlaylistContext);
  const maxH = getMaxHeightRepresentation();

  // fallback UI when loading auth
  if (!authLoaded) return <Loading />;
  if (authLoaded && user && user.displayName === DEFAULT_USER_NAME)
    return <Navigate to="/auth/post-register" />;
  return (
    <>
      <Flex position="relative" bg="primary.100" minH={maxH}>
        <DailyReward />
        <RequestNotificationPermission />
        <IncomingMessage />
        <R18Warning />
        <Sidebar />
        <Box
          width={{ base: "100%", md: 640 }}
          mx="auto"
          px={0}
          pb={immersive ? 0 : `${MobileNavigation.HEIGHT}px`}
        >
          <Outlet />
        </Box>
      </Flex>
      {!immersive && <MobileNavigation />}
    </>
  );
};
export default SemiProtectedLayout;
