import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  Box,
  Text,
  Divider,
  Button,
  VStack,
} from "@chakra-ui/react";
import { memo } from "react";
import { Trans, useTranslation } from "react-i18next";

const ChargeUpdatedNotice = memo(({ updatedCharge, charge, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={updatedCharge != null} onClose={onConfirm} isCentered>
      <ModalOverlay bg="rgba(0,0,0,.8)" />
      <ModalBody>
        <ModalContent bg="transparent">
          <Box
            bg="#151515"
            mx={8}
            maxW={400}
            color="white"
            p={6}
            rounded={16}
            borderWidth="1px"
            borderColor="secondary.100"
          >
            <Text
              align="center"
              fontSize="xl"
              borderColor="secondary.100"
              pb={2}
            >
              {t("chats.updated_charge.title")}
            </Text>
            <Divider borderColor="#484848" my={2} />
            <VStack align="center" gap={3}>
              <Text mb={6}>
                <Trans
                  i18nKey="chats.updated_charge.content"
                  values={{
                    charge,
                    updatedCharge,
                    currency: t("common.currency"),
                  }}
                  components={{ highlight: <Text as="span" color="red.400" /> }}
                />
              </Text>
              <Button
                onClick={onConfirm}
                variant="themed"
                py={2}
                flex={1}
                width={200}
              >
                {t("common.actions.confirm")}
              </Button>
            </VStack>
          </Box>
        </ModalContent>
      </ModalBody>
    </Modal>
  );
});

export default ChargeUpdatedNotice;
