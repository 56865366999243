import { useEffect } from "react";
import PullToRefresh from "pulltorefreshjs";
import useDeviceInfo from "@/libs/hooks/device-info";
import { useLocation } from "react-router-dom";

const allowList = ["/profile", "/profile/followed-creators"];

// A workaround to add pull to refresh to IPhone device in PWA mode,
// as it's blocked by safari
const withPullToRefresh = (Component) => (props) => {
  const { pathname } = useLocation();
  const { isIPhone } = useDeviceInfo();
  const allow = !!allowList.find((path) => pathname === path);
  useEffect(() => {
    const standalone = window.matchMedia("(display-mode: standalone)").matches;
    if (isIPhone && standalone && allow) {
      const ptr = PullToRefresh.init({
        onRefresh() {
          window.location.reload();
        },
      });
      return () => ptr.destroy();
    }
  }, [allow, isIPhone]);

  return <Component {...props} />;
};

export default withPullToRefresh;
