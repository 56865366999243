import { Box, Flex, VStack } from "@chakra-ui/react";
import usePolyfills from "@/libs/hooks/polyfills";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import usePWA from "@/libs/hooks/pwa";
import useDeviceInfo from "@/libs/hooks/device-info";
import { IS_LOCAL } from "@/constants";

const allowList = ["/auth/reset-password", "/auth/post-register"];

export default function AuthLayout() {
  const { getMaxHeightRepresentation } = usePolyfills();
  const maxH = getMaxHeightRepresentation();
  const { pathname } = useLocation();
  const { isPWA } = usePWA();
  const { isMobile } = useDeviceInfo();
  const allowed = !!allowList.find((path) => pathname?.startsWith(path));

  const shouldInstallApp = !isPWA && isMobile && !IS_LOCAL && !allowed;

  if (shouldInstallApp) return <Navigate to="/install-app" />;
  return (
    <Flex minH={maxH} align="center">
      <Box
        left={0}
        top={0}
        height="100%"
        width="100vw"
        position="absolute"
        backgroundImage="https://nightco-asset.b-cdn.net/app/app-cover.png"
        filter="blur(6px)"
        zIndex={-1}
      />
      <Box
        left={0}
        top={0}
        height="100%"
        width="100vw"
        position="absolute"
        backgroundColor="rgba(0, 0, 0, 0.7)"
        zIndex={-1}
      />

      <VStack
        minH={360}
        width={{ base: 350, md: 600 }}
        my={8}
        m="auto"
        bg="#151515"
        borderRadius={10}
        borderWidth={1}
        borderColor="secondary.500"
        shadow={4}
        align="stretch"
        px={10}
        py={20}
        justify="center"
      >
        <Outlet />
      </VStack>
    </Flex>
  );
}
