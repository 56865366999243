import {
  Box,
  Flex,
  Image,
  Skeleton,
  SkeletonCircle,
  Text,
} from "@chakra-ui/react";

import useAPI from "@/libs/hooks/api";
import { useCallback, useContext, useEffect, useState } from "react";
import Skeletons from "@/components/Skeletons";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import getPublicUrl from "@/libs/get-public-url";
import CreatorAvatar from "@/components/CreatorAvatar";
import VideoPreview from "@/components/VideoPreview";
import FAIcon from "@/components/FAIcon";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Playlist from "@/components/Playlist";
import ChatContext from "@/context/chat";
import PlaylistContext from "@/context/playlist";
import LoadMore from "@/components/LoadMore";

const noop = async () => {};

const Loading = () => (
  <Box p={6}>
    <Skeleton aspectRatio={9 / 4} mb={8} />
    <Box mb={4}>
      <Skeleton width={160} height={8} />
      <Flex gap={3} my={6} overflow="auto">
        {Array.from({ length: 8 }).map((_, index) => (
          <SkeletonCircle key={`video-${index}}`} size={16} minW={16} />
        ))}
      </Flex>
    </Box>
    {Array.from({ length: 3 }).map((_, index) => (
      <Box key={`group-${index}`} mb={4}>
        <Skeleton width={160} height={8} />
        <Flex gap={3} my={6} overflow="auto">
          {Array.from({ length: 4 }).map((_, index) => (
            <Skeletons.Video
              key={`video-${index}}`}
              width={{ base: 40, md: 60 }}
              minW={{ base: 40, md: 60 }}
            />
          ))}
        </Flex>
      </Box>
    ))}
  </Box>
);

const Group = ({ type, name, items, index }) => {
  const api = useAPI();
  const navigate = useNavigate();
  const { setVideos, jumpTo } = useContext(PlaylistContext);
  const [data, setData] = useState(items);
  const [done, setDone] = useState(false || type === "creators");
  const [loading, setLoading] = useState(false);

  const loadMoreVideos = useCallback(async () => {
    if (done) return;
    setLoading(true);
    const limit = 5;
    const { result } = await api.getExploreSettingsByChunk(
      index,
      data.length,
      limit,
    );
    if (result.items.length < limit) setDone(true);
    setData(data.concat(result.items));
    setLoading(false);
  }, [api, data, done, index]);

  const onVideoClick = useCallback(
    (index) => () => {
      setVideos(data);
      jumpTo(index);
    },
    [data, jumpTo, setVideos],
  );

  return (
    <Box mt={4} pl={6}>
      <Flex
        justify="space-between"
        fontWeight="bold"
        color="white"
        align="center"
      >
        <Text fontSize="xl">{name}</Text>
        <Box as={Link} to={`/explore/${type}`} fontSize="sm" mr={6}>
          <FAIcon type="angle-right" mr={1} /> 探索更多
        </Box>
      </Flex>

      <Flex
        gap={{ base: 2, md: 3 }}
        my={6}
        pr={6}
        overflow="auto"
        sx={{
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {data.map((item, index) =>
          type === "creators" ? (
            <CreatorAvatar
              role="button"
              key={item.id}
              id={item.id}
              picture={item.picture}
              onClick={() => navigate(`/c/${item.slug}`)}
            />
          ) : (
            <Box
              key={item.id}
              width={{ base: 32, md: 48 }}
              minW={{ base: 32, md: 48 }}
              onClick={onVideoClick(index)}
            >
              <VideoPreview {...item} />
            </Box>
          ),
        )}
        <LoadMore
          loader={loadMoreVideos}
          loading={loading}
          ml={4}
          done={done}
        />
      </Flex>
    </Box>
  );
};

const ExplorePage = () => {
  const api = useAPI();
  const [settings, setSettings] = useState(null);
  const { resolveChatUrl } = useContext(ChatContext);
  // eslint-disable-next-line
  const [_, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const onCreatorClick = useCallback(
    (creator) => {
      setSearchParams({}, { replace: true });
      navigate(`/c/${creator}`);
    },
    [navigate, setSearchParams],
  );

  const onChatClick = useCallback(
    (creator) => {
      setSearchParams({}, { replace: true });
      navigate(resolveChatUrl(creator));
    },
    [navigate, resolveChatUrl, setSearchParams],
  );

  useEffect(() => {
    api.getExploreSettings().then(setSettings);
  }, [api]);

  return (
    <Box>
      {settings == null ? (
        <Loading />
      ) : (
        <Box>
          <Box p={6}>
            <Swiper
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              spaceBetween={30}
              loop
              modules={[Autoplay, Pagination, Navigation]}
            >
              {settings?.banners.map((banner, index) => (
                <SwiperSlide key={`banners-${index}`}>
                  <Box
                    as="a"
                    width="100%"
                    position="relative"
                    href={banner.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Image
                      src={getPublicUrl(
                        typeof banner === "string" ? banner : banner.image,
                      )}
                      width="100%"
                    />
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
          {settings?.groups.map((group, index) => (
            <Group key={group.name} {...group} index={index} />
          ))}
        </Box>
      )}
      <Playlist
        modal
        onCreatorClick={onCreatorClick}
        onChatClick={onChatClick}
        loader={noop}
        done={true}
      />
    </Box>
  );
};

export default ExplorePage;
