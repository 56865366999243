import ChatContext from "@/context/chat";
import { useContext, useEffect, useMemo } from "react";

const withAppBadge = (Component) => () => {
  const { chats } = useContext(ChatContext);
  const totalUnread = useMemo(
    () =>
      Object.values(chats).reduce(
        (acc, cur) => acc + (cur?.unreadTotal || 0),
        0,
      ),
    [chats],
  );
  useEffect(() => {
    if ("setAppBadge" in navigator) {
      navigator.setAppBadge(totalUnread);
    }
  }, [totalUnread]);
  return <Component />;
};

export default withAppBadge;
