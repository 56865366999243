import {
  Box,
  Button,
  Progress,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  useCallback,
  useContext,
  useEffect,
  useState,
  memo,
  useMemo,
} from "react";
import MobileNavigation from "../MobileNavigation";
import useDeviceInfo from "@/libs/hooks/device-info";
import useAPI from "@/libs/hooks/api";
import PlaylistContext from "@/context/playlist";
import { useSwiperSlide } from "swiper/react";
import VideoOverlay from "./VideoOverlay";
import VideoPlayer from "./VideoPlayer";
import VideoActions from "./VideoActions";
import OrderConfirm from "@/components/OrderConfirm";
import { useTranslation } from "react-i18next";
import Currency from "@/components/Currency";
import AuthContext from "@/context/auth";
import { useNavigate } from "react-router-dom";
import usePWA from "@/libs/hooks/pwa";
import canonicalPlaybackTime from "@/libs/canonical-playback-time";

const VideoPost = ({
  id,
  playbacks,
  likedTotal,
  description,
  isLiked,
  isSaved,
  isUnlocked,
  active,
  creator,
  tags,
  price,
  pageFocused,
  onCreatorClick,
  onChatClick,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { width, height, isMobile } = useDeviceInfo();
  const { isPWA, install } = usePWA();
  const api = useAPI();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { muted, updateVideo } = useContext(PlaylistContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const slide = useSwiperSlide();

  const [viewed, setViewed] = useState(false);
  const [paused, setPaused] = useState(!active);
  const [duration, setDuration] = useState(null);
  const [position, setPosition] = useState(null);

  const remaining = duration && position != null ? duration - position : null;

  const shouldLoad = slide.isActive || slide.isNext || slide.isPrev;

  const [controls, setControls] = useState({
    pause: () => null,
    inspect: () => null,
    setPosition: () => null,
    setProperties: () => null,
    setIsMuted: () => null,
  });

  const update = useMemo(() => updateVideo(id), [id, updateVideo]);
  const cta = useMemo(() => {
    if (user)
      return t("videos.actions.unlock_video_with", {
        price,
        currency: t("common.currency"),
      });
    else if (isPWA || !isMobile) return t("common.cta.register_to_unlock");
    return t("common.cta.install_app");
  }, [isMobile, isPWA, price, t, user]);

  const onCTA = useCallback(async () => {
    if (user) return onOpen();
    else if (isPWA || !isMobile) return navigate("/auth/register");
    try {
      await install();
      toast({
        title: "安裝成功！請使用 APP 繼續瀏覽",
        status: "success",
        duration: null,
      });
    } catch (e) {
      return navigate("/install-app");
    }
  }, [user, onOpen, isPWA, isMobile, navigate, install, toast]);

  const unlockVideo = useCallback(async () => {
    await api.unlockVideo(id);
    const data = await api.getVideo(id);
    update(data);
    onClose();
  }, [api, id, update, onClose]);

  const toggleLiked = useCallback(
    async (e) => {
      if (e) e.stopPropagation();
      const action = isLiked ? api.unlikeVideo : api.likeVideo;
      await action(id);
      update({ isLiked: !isLiked });
    },
    [api, id, isLiked, update],
  );

  const setVideoPaused = useCallback(
    (status, restart = false) => {
      setPaused(status);
      const positionStatus = restart ? { position: 0 } : {};
      controls.setProperties({ isPaused: status, ...positionStatus });
    },
    [controls],
  );

  const handleStateChange = useCallback(
    (changes) => {
      if (!active) return;
      const data = controls.inspect();
      if (data.duration && !duration) setDuration(data.duration);
      if (changes.position) setPosition(changes.position);
      const { playState } = changes;
      // if user watched more than 5 seconds, marked as viewed
      if (changes.position >= 5.0 && !viewed) {
        setViewed(true);
        api.viewVideo(id);
      }

      // if video finished, auto replay after 1 second
      if (playState === "inactive") {
        setTimeout(() => {
          setVideoPaused(false, true);
          setViewed(false);
        }, 1000);
      }
    },
    [active, api, controls, duration, id, setVideoPaused, viewed],
  );

  // check page visibility & order factors and determine video plays status
  useEffect(() => {
    if (!pageFocused) {
      setVideoPaused(true);
    } else if (shouldLoad && active) {
      setVideoPaused(false);
    } else {
      setVideoPaused(true, true);
    }
  }, [shouldLoad, active, setVideoPaused, pageFocused]);

  useEffect(() => {
    if (!active) return;
  }, [active, pageFocused, setVideoPaused]);

  // when global muted context changes, set controls
  useEffect(() => {
    controls.setProperties({ isMuted: muted });
  }, [controls, muted]);

  return (
    <Box
      position="relative"
      height="100%"
      maxW={{ base: "none", md: ((height * 9) / 16) * 0.85 }}
      mx="auto"
      py={{ base: 0, md: 5 }}
    >
      {/* responsive video container */}
      <Box
        position="relative"
        height="100%"
        aspectRatio={{
          base: width / (height - MobileNavigation.HEIGHT),
          md: 9 / 16,
        }}
        width="100%"
      >
        {/* video begin */}
        <Box
          width="100%"
          height="100%"
          overflow="hidden"
          position="relative"
          borderRadius={{ md: ".5rem" }}
        >
          <Box position="absolute" height="100%" width="200%" left="-50%">
            <VideoPlayer
              active={shouldLoad}
              playbacks={playbacks}
              muted={muted}
              paused={paused}
              handleStateChange={handleStateChange}
              setControls={setControls}
            />
          </Box>
        </Box>
        {/* video end */}

        <VideoOverlay
          active={active}
          paused={paused}
          onClick={() => setVideoPaused(!paused)}
          onDoubleClick={() => (user ? toggleLiked() : null)}
        />

        {/* remaining time countdown */}
        {remaining != null && (
          <Box
            position="absolute"
            top={2}
            right={2}
            color="white"
            bg="rgba(0,0,0,.56)"
            rounded="xl"
            px={2}
          >
            {canonicalPlaybackTime(remaining)}
          </Box>
        )}

        {/* video controls begin */}
        <Box
          position="absolute"
          bottom={0}
          opacity={active ? 1 : 0}
          color="white"
          transition="opacity .8s"
          width="100%"
        >
          <Box p={5}>
            <VideoActions
              id={id}
              tags={tags}
              description={description}
              creator={creator}
              active={active}
              isSaved={isSaved}
              isLiked={isLiked}
              likedTotal={likedTotal}
              onCreatorClick={onCreatorClick}
              onChatClick={onChatClick}
              toggleLiked={toggleLiked}
              update={update}
            />
            {isUnlocked || (
              <Button
                mt={4}
                width="full"
                variant="themed-gradient"
                onClick={onCTA}
                display="flex"
                align="center"
                gap={4}
                id="button__video_unlock"
              >
                <Currency size={6} />
                {cta}
                <Currency size={6} />
              </Button>
            )}
          </Box>

          <Progress
            value={position}
            max={duration}
            colorScheme="gray"
            size="xs"
            bg="gray.700"
          />
        </Box>

        {/* video controls end */}
        <OrderConfirm
          isOpen={isOpen}
          onClose={onClose}
          onCancel={onClose}
          onConfirm={unlockVideo}
          entry={t("videos.actions.unlock_video")}
          amount={-price}
        />
      </Box>
    </Box>
  );
};
export default memo(VideoPost);
