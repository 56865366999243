import OnlineStatusContext from "@/context/online-status";
import getPublicUrl from "@/libs/get-public-url";
import useCreatorOnlineStatus from "@/libs/hooks/creator-online-status";
import { Avatar, AvatarBadge } from "@chakra-ui/react";
import { useContext, useEffect } from "react";

const CreatorAvatar = ({ id, picture, ...rest }) => {
  const online = useCreatorOnlineStatus(id);
  const { listening, updateListenList } = useContext(OnlineStatusContext);

  useEffect(() => {
    if (id && !listening.includes(id)) {
      updateListenList([id]);
    }
  }, [id, listening, updateListenList]);

  return (
    <Avatar role="button" src={getPublicUrl(picture)} size="lg" {...rest}>
      {online && (
        <AvatarBadge
          position="absolute"
          boxSize=".6em"
          borderWidth=".1em"
          borderColor="primary.100"
          bg="green.500"
          bottom=".3em"
          right=".1em"
        />
      )}
    </Avatar>
  );
};

export default CreatorAvatar;
