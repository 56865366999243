import { Box, Text } from "@chakra-ui/react";
import { forwardRef } from "react";

export const TapPayLabel = ({ children }) => (
  <Text fontSize="sm" wordBreak="keep-all">
    {children}
    <Text as="span" color="red">
      *
    </Text>
  </Text>
);

export const TapPayField = forwardRef(({ ...props }, ref) => (
  <Box
    ref={ref}
    bg="white"
    height={8}
    borderRadius={3}
    px={2}
    flex={1}
    {...props}
  />
));
