import {
  VStack,
  Text,
  Box,
  Avatar,
  Flex,
  Divider,
  AbsoluteCenter,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import FAIcon from "@/components/FAIcon";
import Header from "@/components/Header";
import useNavigateBack from "@/libs/hooks/navigate-back";
import getPublicUrl from "@/libs/get-public-url";
import { useCallback, useContext } from "react";
import AuthContext from "@/context/auth";

import VIPFrame from "@/assets/images/vip-frame.svg?react";
import { useNavigate } from "react-router-dom";
import * as Membership from "@/components/Membership";

const Plan = ({ name, price, discounted, monthly, recommended = false }) => (
  <VStack
    bg="#302A22"
    textAlign="center"
    flex={1}
    py={6}
    px={{ base: 2, md: 6 }}
    rounded="xl"
    borderWidth={2}
    borderColor={recommended ? "#F6A01E" : "transparent"}
    position="relative"
  >
    {recommended && (
      <Box position="absolute" top={-3} bg="#F6A01E" px={3} rounded="xl">
        最推薦
      </Box>
    )}

    <Text color="#E5D6C6" fontSize={{ base: "xs", md: "md" }}>
      {name}
    </Text>
    <Box my={1} flex={1}>
      <Text
        color={recommended ? "#FFBC39" : "white"}
        align="center"
        fontWeight="black"
        mb={0}
      >
        <Text as="span" fontSize={{ base: "sm", md: "xl" }}>
          $
        </Text>
        <Text as="span" fontSize={{ base: "xl", md: "4xl" }}>
          {discounted || price}
        </Text>
      </Text>
      {discounted && (
        <Text
          textDecor="line-through"
          color="rgba(185, 150, 99, 0.50)"
          fontSize={{ base: "xs", md: "md" }}
        >
          ${price}
        </Text>
      )}
    </Box>

    <Text color="#B99663" fontSize={{ base: "sm", md: "xl" }}>
      ${monthly}/月
    </Text>
  </VStack>
);

const MembershipPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const { user } = useContext(AuthContext);
  const vip = user?.isVip;
  const plan = user?.vip?.plan?.id;
  const { followedCreatorsTotal, unlockedVideosTotal, savedVideosTotal } =
    user || {};

  const gotoPayment = useCallback(() => navigate("/payment/vip"), [navigate]);

  return (
    <Box bg="#1B1B1B" minHeight="100%">
      <Header>
        <Text fontSize="2xl" m="auto" pl={4}>
          {t("page.title.membership")}
        </Text>
        <FAIcon
          type="close"
          role="button"
          fontSize="2xl"
          onClick={navigateBack}
        />
      </Header>
      <VStack flex={1} gap={3} mx="auto" align="stretch" py={5} px={8}>
        <Flex color="white" gap={3} align="center" mb={3}>
          <Box position="relative">
            <Avatar
              size={{ base: "lg", md: "xl" }}
              src={getPublicUrl(user?.picture)}
            />
            {vip && (
              <Box
                position="absolute"
                top={0}
                pointerEvents="none"
                width="100%"
              >
                <VIPFrame />
              </Box>
            )}
          </Box>
          <Box>
            <Text fontWeight={500} fontSize={{ base: "md", md: "xl" }}>
              {user.displayName}
            </Text>
            <Text fontSize={{ base: "xs", md: "md" }}>
              {followedCreatorsTotal}
              {t("profile.tabs.total_follows")} | {unlockedVideosTotal}
              {t("profile.tabs.total_unlocks")} | {savedVideosTotal}
              {t("profile.tabs.total_saved")}
            </Text>
          </Box>
        </Flex>
        {vip ? (
          <Membership.VIPCard
            plan={t(`common.vip_plans.${plan.replace("-", "_")}`)}
          />
        ) : (
          <Membership.MemberCard onClick={gotoPayment} />
        )}

        {vip ? (
          <Box fontSize="xl" color="white" my={4}>
            VIP 會員權益
          </Box>
        ) : (
          <Box position="relative" my={6}>
            <Divider borderColor="#CAB18C" />
            <AbsoluteCenter bg="#1B1B1B" color="#CAB18C" px={4}>
              加入即享6大權益
            </AbsoluteCenter>
          </Box>
        )}

        <Membership.Premiums />

        {!vip && (
          <>
            <Flex align="stretch" gap={{ base: 3, md: 4 }} mt={4}>
              <Plan
                name={t("common.vip_plans.season")}
                price={2700}
                discounted={2370}
                monthly={790}
              />
              <Plan
                name={t("common.vip_plans.half_year")}
                price={5400}
                discounted={3600}
                monthly={600}
                recommended
              />
              <Plan
                name={t("common.vip_plans.month")}
                price={900}
                monthly={900}
              />
            </Flex>
            <Button variant="themed-gradient" mt={3} onClick={gotoPayment}>
              立即加入
            </Button>
          </>
        )}
      </VStack>
    </Box>
  );
};
export default MembershipPage;
